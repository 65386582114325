import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import React from "react"

export interface IPagination {
  total: number
  perPage: number
  currentPage: number
  updatePerPage: (perPage: number) => void
  onPageChange: (page: number) => void
}

export interface IPaginationApi {
  start: number
  length: number
  total: number
}

const Pagination = ({
  total,
  perPage,
  currentPage,
  updatePerPage,
  onPageChange,
}: IPagination) => {
  const handlePageChange = (page: number) => {
    if (
      page > 0 &&
      page <= Math.ceil(total / perPage) &&
      page !== currentPage
    ) {
      onPageChange(page)
    }
  }

  const handlePerPageChange = (perPage: number) => {
    if (perPage > 0) {
      onPageChange(1)
      updatePerPage(perPage)
    }
  }

  const renderPageNumbers = () => {
    const pageNumbers = []
    const totalPage = Math.ceil(total / perPage)
    for (let i = 1; i <= totalPage; i++) {
      if (i <= 2 || i >= totalPage - 1 || i === currentPage) {
        pageNumbers.push(i)
      } else if (
        (i < currentPage && i >= currentPage - 2) ||
        (i > currentPage && i <= currentPage + 2)
      ) {
        pageNumbers.push(i)
      } else if (
        (i < currentPage && i === currentPage - 3) ||
        (i > currentPage && i === currentPage + 3)
      ) {
        pageNumbers.push("...")
      }
    }

    return pageNumbers.map((number) => {
      if (number === "...") {
        return (
          <button className="px-2 py-1 rounded-md text-sm font-medium">
            {number}
          </button>
        )
      }

      return (
        <button
          key={number}
          onClick={() =>
            number !== currentPage &&
            number !== "..." &&
            handlePageChange(Number(number))
          }
          className={`${
            number === currentPage
              ? "bg-primary500 text-white"
              : "bg-white text-neutral-400 "
          } px-2 py-1 rounded-md text-sm font-medium`}
        >
          {number}
        </button>
      )
    })
  }

  return (
    <div className="flex items-center py-3 mt-3 w-full justify-between text-sm ">
      <div className="flex items-center justify-start text-[#243757]">
        <span>Show</span>
        <span className="mx-3">
          <input
            type="number"
            value={perPage}
            onChange={(e) => handlePerPageChange(Number(e.target.value))}
            className="appearance-none px-2 py-1 border border-neutral-300 rounded w-10 text-center outline-none focus:ring-1 focus:ring-primary500"
          />
        </span>
        <span>Entires</span>
      </div>
      <div className="flex items-center justify-end space-x-3">
        <button>
          <ChevronLeftIcon className="w-5 h-5" />
        </button>
        <div className="flex items-center space-x-2 text-sm">
          {renderPageNumbers()}
        </div>
        <button>
          <ChevronRightIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  )
}

export default Pagination

interface IUsePagination {
  defaultPerPage?: number
  defaultCurrentPage?: number
  refetch?: (page: number, perPage: number) => void
}

export const usePagination = ({
  defaultPerPage = 10,
  defaultCurrentPage = 1,
  refetch,
}: IUsePagination) => {
  const [currentPage, setCurrentPage] = React.useState(defaultCurrentPage)
  const [perPage, setPerPage] = React.useState(defaultPerPage)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    refetch && refetch(currentPage, perPage)
  }

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage)
    refetch && refetch(currentPage, perPage)
  }

  return {
    currentPage,
    perPage,
    handlePageChange,
    handlePerPageChange,
  }
}
