import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import passwordHidden from "../../../../../assets/illustrations/password_hidden.svg"
import { Alert } from "antd"
import { Button } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const ConfirmPin = ({
  pin,
  setConfirmPin,
}: {
  pin: string
  setConfirmPin: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [otp, setOtp] = useState<string[]>(["", "", "", ""])
  const [updatedPin, setUpdatedPin] = useState("")
  const otpInputs = useRef<(HTMLInputElement | null)[]>([])
  const [isPinComplete, setIsPinComplete] = useState(false)
  useEffect(() => {
    setIsPinComplete(otp.every((digit) => digit !== ""))
  }, [otp])
  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return // Only allow numeric input

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    setUpdatedPin(newOtp?.join(""))

    // Move focus to the next input field if available
    if (
      value !== "" &&
      index < otp.length - 1 &&
      otpInputs.current[index + 1]
    ) {
      otpInputs.current[index + 1]?.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData("text")
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault()
      return
    }

    const newOtp = pastedData.split("").slice(0, otp.length)
    setOtp(newOtp)
    setUpdatedPin(newOtp?.join(""))
  }
  const handleSetPin = () => {
    if (pin === updatedPin) {
      mixPanelEvent(`empl-wallet-pin-creation-init`)

      submit({
        pin: updatedPin,
      })
    } else {
      toast.error("Pin does not match")
    }
  }
  interface IBody {
    alternateEmail?: string
    pin: string
  }
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: IBody) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/set-pin`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      console.log(data)
      if (data.success) {
        data.message && toast.success(data.message)
        mixPanelEvent(`empl-wallet-pin-creation-success`)
        navigate(
          user?.userStatusId === "2" || user?.userStatusId === "3"
            ? "/employee/susp-exit/wallet/overview"
            : "/employee/dashboard/wallet/overview"
        )

        // setCurrentPage(3)
      } else {
        console.log(data)
        data.message && toast.error(data.message)
      }
    },
    onError: (data: any) => {
      console.log(data)
      data.message && toast.error(data.message)
    },
  })
  return (
    <div className="flex justify-center w-full items-center">
      <div className="flex flex-col justify-between w-full items-center bg-white py-[67px] px-4 max-w-[991px]">
        <h6 className="text-2xl text-padeBlack font-circular mb-[10px] font-bold">
          Confirm PIN{" "}
        </h6>
        <p className="text-sm mb-[22px]">
          Set a 4 digit PIN to authorise transactions
        </p>
        <div className="xl:w-[575px] mb-10">
          <Alert
            message={
              "Please note that this PIN will be used to authorise other transactions on PaidHR"
            }
            type="info"
            showIcon
            className="  font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
          />
        </div>
        <img
          src={passwordHidden}
          alt="password"
          className="w-[59px] h-[59px]"
        />
        <p className="text-sm  text-bluesubtext mt-7 mb-5">
          Please enter your PIN
        </p>

        <div className="flex gap-[22px]">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="password"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onPaste={handlePaste}
              ref={(ref) => {
                otpInputs.current[index] = ref
              }}
              className="border w-[60px] h-[60px] rounded-5px text-center text-lg font-bold active:border-primary500"
              style={{
                fontFamily: "PasswordFont", // Replace with your custom font name
              }}
            />
          ))}
        </div>
        <div className="mt-10 w-[318px] flex gap-4 items-center">
          <Button
            color="secondary"
            className="w-full"
            onClick={() => {
              setConfirmPin(false)
              setOtp(["", "", "", ""])
            }}
          >
            Previous
          </Button>
          <Button
            color="primary"
            className="w-full"
            onClick={handleSetPin}
            disabled={!isPinComplete}
            submitting={isLoading}
          >
            Set Pin
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPin
