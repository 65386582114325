import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import MakePayment from "assets/svg/make_payment.svg"
import MoveFunds from "assets/svg/move_funds.svg"
import sendCB from "assets/svg/sendCB.svg"

import { useNavigate } from "react-router-dom"
import SendMoneyCard from "pages/dashboard/wallets/crossBorderPayments/component/SendMoneyCard"
import { Button } from "components"
import { useAuthStore } from "stores"

interface Props {
  onClose: () => void
  open: boolean
  currentLevel: any
  currencyCode: string
}
const MoveFundsLocal = ({
  onClose,
  open,
  currentLevel,
  currencyCode,
}: Props) => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const disabled = currentLevel !== null && currentLevel?.level === 1

  return (
    <CustomModal
      title="Send Money"
      subtitle=""
      onCancel={onClose}
      open={open}
      width={654}
    >
      <div className=" flex flex-col gap-6 pb-[50px]">
        <SendMoneyCard
          icon={sendCB}
          title="To PaidHR Wallet"
          subtitle="Transfer funds instantly to fellow PaidHR users for secure, fast transactions."
          onClick={() => {
            navigate(
              user?.userStatusId === "2" || user?.userStatusId === "3"
                ? "/employee/susp-exit/wallet/send-money-wallet"
                : "/employee/dashboard/wallet/send-money-wallet",
              {
                state: { currencyCode },
              }
            )
          }}
        />
        <SendMoneyCard
          icon={sendCB}
          title="To Bank"
          subtitle="Transfer money directly to any local bank account outside the PaidHR network."
          disabled={disabled ? true : false}
          onClick={() => {
            if (!disabled) {
              navigate(
                user?.userStatusId === "2" || user?.userStatusId === "3"
                  ? "/employee/susp-exit/wallet/send-money-bank"
                  : "/employee/dashboard/wallet/send-money-bank"
              )
            } else {
              navigate("/employee/dashboard/wallet/kyc")
            }
          }}
          tooltitle={
            disabled
              ? "Please upgrade your account by verifying your BVN to get your account number for bank transactions outside PaidHR, and enjoying more benefits "
              : ""
          }
          showButton={
            disabled ? (
              <Button
                color="primary"
                className="z-50"
                onClick={() => navigate("/employee/dashboard/wallet/kyc")}
              >
                Upgrade
              </Button>
            ) : (
              ""
            )
          }
        />
      </div>
    </CustomModal>
  )
}

export default MoveFundsLocal
