import { logos } from "assets"
import { Button, DashboardContainer } from "components"
import { useLocation } from "react-router-dom"
import { useGet } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import PensionReportToPrint from "../pension/PensionReportToPrint"
import React, { useRef } from "react"
import ReactToPrint from "react-to-print"
import NSITFReportToPrint from "./NSITFReportToPrint"
import { CSVLink } from "react-csv"

const NSITFReport = () => {
  const typeId = "9"
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const id = new URLSearchParams(myParam).get("id")
  const componentRef: any = useRef()
  const { isLoading: isPayslipLoading, data } = useGet<any>({
    url: `admin/reports/view/schedule?id=${x}&payment_schedule_item_type_id=${typeId}&x=false`,
  })
  const headers = [
    { label: "Name", key: "full_name" },
    { label: "Amount", key: "amount" },
  ]

  const csvProps = {
    filename: `${data?.report_download_title}.csv`,
    headers: headers,
    data: data?.nsitfSchedules || [],
  }
  return (
    <DashboardContainer>
      <>
        {isPayslipLoading ? (
          <div className="h-[200px] items-center flex justify-center">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div>
            <div className="flex justify-end items-center mb-1 gap-4">
              {" "}
              {componentRef && (
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      title="Download"
                    >
                      Download
                    </Button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={`${data?.report_download_title}`}
                />
              )}
              <CSVLink {...csvProps}>
                <Button
                  color="primary"
                  title="Download"
                >
                  Download CSV
                </Button>
              </CSVLink>
            </div>
            <NSITFReportToPrint
              ref={componentRef}
              data={data}
            />
          </div>
        )}
      </>
    </DashboardContainer>
  )
}
export default NSITFReport
