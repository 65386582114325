import { Form, FormItemProps, Input, Select } from "antd"

const { Option } = Select

export const CustomInput = ({
  label,
  name,
  required = false,
  maxLength = undefined,
  onChange,
  placeholder,
  disabled = false,
  onBlur,
  min = undefined,
  max = undefined,
  type = "text",
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      // tooltip="This is a required field"
      {...restProps}
    >
      <Input
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur ? onBlur : () => {}}
        maxLength={maxLength}
        min={min}
        max={max}
        className="md:text-sm text-base font-avenir"
      />
    </Form.Item>
  )
}

export const CustomSelect = ({
  label,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options,
  onChange,
  onSelectClick,
  isLoading = false,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Select
        loading={isLoading}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        onClick={onSelectClick}
        filterOption={(input, option) => {
          if (option) {
            const value = (option.value as string) || ""
            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
      >
        {options?.map((option, index) => (
          <Option
            key={index}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

type InputItemProps = FormItemProps & {
  maxLength?: number | undefined
  max?: number | undefined
  min?: number | undefined
  label: string
  required?: boolean
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  onBlur?: any
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
}

type SelectItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  showSearch?: boolean
  onChange?: (e: any) => void
  onSelectClick?: VoidFunction
  placeholder: string
  options: { label: string; value: string }[] | undefined
  isLoading?: boolean
}
