import { Space, Spin } from "antd"
import type { CollapseProps } from "antd"
import React, { useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { CaretRightOutlined } from "@ant-design/icons"
import type { CSSProperties } from "react"
import { Collapse, theme } from "antd"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
const { Panel } = Collapse

export type Items = {
  children?: React.ReactNode
  header?: string | React.ReactNode
  key: string
}
type dropDownProps = {
  children?: React.ReactNode
  rootClassName?: string
  items: Items[]
  accordion?: boolean
  bordered?: boolean
  icon?: React.ReactNode | string
  position?: "left" | "right" | "start" | "end"
  onChange?: any
}

const DropdownCard: React.FC<dropDownProps> = ({
  rootClassName = "",
  items,
  accordion = true,
  bordered = false,
  position = "right",
  onChange,
}) => {
  return (
    <>
      <Collapse
        accordion={accordion}
        bordered={bordered}
        rootClassName={`${rootClassName} font-avenir`}
        expandIconPosition={position}
        onChange={onChange}
        expandIcon={({ isActive }) =>
          isActive ? (
            <div className="border  border-neutral50 rounded-full h-7 w-7 flex justify-center items-center text-center ">
              <ChevronDownIcon className=" text-primary500  w-4 h-4" />
            </div>
          ) : (
            <div className="border  border-neutral50 rounded-full h-7 w-7 flex justify-center items-center text-center ">
              <ChevronRightIcon className=" text-primary500  w-4 h-4" />
            </div>
          )
        }
      >
        {items?.length &&
          items?.map((item) => (
            <Panel
              key={item.key}
              header={item.header}
              className={`border-2 border-cardgrey rounded-5px mb-4 bg-white p-0 font-avenir`}
            >
              {item.children}
            </Panel>
          ))}
      </Collapse>
    </>
  )
}

export default DropdownCard
