import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "../utils/constants"
import { request } from "utils/request"
import { useAuthModalStore } from "./auth-modal"

export interface IUser {
  companyIdentifier?: string
  userRoles?: string[]
  isPrimaryAdmin?: string
  isSecondaryAdmin?: string
  currentCompanyId?: string
  isAdmin?: string
  isAtsEnabled?: string
  isEwaEnabled?: string
  isConsultant?: string
  isActive?: string
  isWalletSetup?: string
  userAvatarFilename?: string
  userName?: string
  userPhone?: string
  userId?: string
  userFirstName?: string
  userLastName?: string
  userFullName?: string
  lastLogin?: string
  isUserOrganisation?: string
  userOrganisationId?: string
  userOrganisation?: string
  userOrganisationEmail?: string
  userOrganisationTelephone?: string
  userOrganisationMobilePhone?: string
  nextInvoiceStartDate?: string
  nextInvoiceStartDate_Short?: string
  nextInvoiceEndDate?: string
  nextInvoiceEndDate_Short?: string
  lastPaymentDate?: string
  lastPaymentDate_Short?: string
  lastPaymentAmount?: string
  lastPaymentInvoiceNumber?: string
  hasPendingInvoice?: string
  hasDueInvoice?: string
  activeEmployees?: string
  organisationChildren?: string
  parentOrganisationId?: string
  organisationPlan?: string
  organisationPlanAmount?: string
  organisationPlanConfigPayrollBilling?: string
  organisationPlanConfigPayrollAmount?: string
  organisationPlanConfigBaseAmount?: string
  nextInvoiceDaysRemaining?: string
  startDate?: string
  startDate_Short?: string
  subscriptionId?: string
  billingMode?: string
  billingFrequency?: string
  newBillingMode?: string
  newBillingFrequency?: string
  newOrganisationPlan?: string
  secretKey?: string
  threshold?: string
  lastInvoiceId?: string

  countryCode?: string
  country?: string
  currencyCode?: string
  currencySymbol?: string
  qualifiedCurrency?: string
  billingCurrencyCode?: string
  billingCurrencySymbol?: string
  isBillingNotificationEnabled?: string
  organisationPlanBaseAmount?: string
  databaseConfigKey?: string
  ewaId?: string
  buffer?: string
  paymentProviderStatus?: "UP" | "DOWN"
  paystackPublicKey?: string
  fileUploadLimit?: string
  adminsCanSwitchOrganisations?: string
  controlOrganisationId?: string
  unreadNotificationCount?: string
  unreadNotifications?: null
  userKey?: string
  personKey?: string
  organisationAvatarFilename?: string
  // memberOrganisations?: MemberOrganisation[]
  isAddressSetup?: string
  isPaygradeSetup?: string
  isEmployeeSetup?: string
  isDocumentSigned?: string
  isCompliant?: string
  setupPercentage?: string
  setupStatus?: string
  setupStep?: string
  userStatusId?: string
  isHrisEnabled?: string
  isPerformanceEnabled?: string
  isPayrollEnabled?: string
  splitPaymentEnabled?: string | null
  userPermissions?: IUserPermissions[]
  members?: Members[]
  walletId?: string | null
  organisationWalletId?: string | null
  userWalletId?: string | null
  supportEmail?: string
}

interface Members {
  id: string
  key: string
  name: string
  parent_id: string
}

export interface IUserPermissions {
  id: number
  name: string
  alias: string
  segment: string
}

export interface MemberOrganisation {
  organisationId?: number
  organisationTypeId?: null
  organisationStatusId?: null
  parentOrganisationId?: null
  planId?: null
  hostDatabaseId?: number
  organisationCode?: null
  fullName?: string
  legalName?: null
  email?: null
  telephone?: null
  mobilePhone?: null
  addressLine1?: null
  addressLine2?: null
  addressCity?: null
  addressStateId?: null
  zipCode?: null
  fullAddress?: null
  representativeUserId?: null
  description?: null
  uniqueId?: null
  createdDate?: null
  recordGuid?: string
  isDeleted?: null
  isActive?: null
  children?: null
  billingModeId?: null
  billingFrequencyId?: null
  billingDiscount?: null
  newPlanId?: null
  newBillingModeId?: null
  newBillingFrequencyId?: null
  isBillingVatApplied?: null
  isBillingWhtApplied?: null
  secretKey?: null
  hasPendingInvoice?: null
  hasDueInvoice?: null
  activeEmployees?: null
  threshold?: null
  trialDays?: null
  isManaged?: null
  lastInvoiceId?: null
  trialEndDate?: null
  nextInvoiceStartDate?: null
  nextInvoiceEndDate?: null
  nextInvoiceDaysRemaining?: null
  trialDaysRemaining?: null
  lastPaymentNotificationRequestId?: null
  countryId?: null
  isBillingNotificationEnabled?: null
  isInitialized?: null
  hasPayoutSubaccount?: null
  earnipayId?: null
}

interface AuthState {
  isAuthenticated: boolean
  authToken?: string
  user: IUser
  splashScreen: boolean
}

export interface ILoginResponse {
  access_token: any
  status: any
  user: any
  message: any
  isOauth?: boolean
}

interface AuthMethods {
  login: (
    email?: string,
    password?: string,
    token?: string,
    auth_code?: string,
    provider?: string
  ) => Promise<ILoginResponse>
  loginSwitch: (
    userId: string
  ) => Promise<{ access_token: any; status: any; message: any }>
  verifyTwoFACode: (
    access_token: string,
    generated: boolean,
    code: string
  ) => Promise<ILoginResponse>
  switchApp: (
    token?: string,
    userId?: string
  ) => Promise<{ access_token: any; status: any; user: any }>
  signOut: (keepRedirect?: boolean) => void
  getUser: () => Promise<{ user: IUser }>
  checkUserSession: () => Promise<{ user: IUser }>
  changeOrganization: (
    access_token: any,
    newUser: Partial<IUser>
  ) => Promise<{ user: Partial<IUser> }>
  clearAuthDataStorage: () => void
}

export const useAuthStore = create<AuthState & AuthMethods>()(
  devtools(
    persist(
      (set, get) => ({
        isAuthenticated: false,
        authToken: localStorage.getItem("pade_auth_token") || undefined,
        splashScreen: false,
        user: {},

        clearAuthDataStorage: () => {
          localStorage.removeItem("pade_auth_token")
          localStorage.removeItem("refresh_token")
          localStorage.removeItem("bgr")
          localStorage.removeItem("xfr")
          localStorage.removeItem("cgm")
          localStorage.removeItem("skv")

          set({
            isAuthenticated: false,
            authToken: undefined,
            user: undefined,
          })
        },

        /**
         * @param token - access token
         * @param userId - user id
         * @returns {Promise<{access_token: any; status: any; user: any}>}
         * @description - switch app function lets you switch from v2 to v3 and vice versa
         */
        switchApp: async (token, userId) => {
          get().clearAuthDataStorage()

          localStorage.setItem("pade_auth_token", token ? token : "")
          localStorage.setItem("bgr", userId ? userId : "")
          set({
            authToken: token,
            isAuthenticated: true,
          })

          const { user } = await get().getUser()
          // check if token is valid or not
          if (user?.isAdmin === undefined) {
            get().clearAuthDataStorage()
            return {
              access_token: token,
              status: "error",
              user: {},
            }
          } else {
            localStorage.setItem("xfr", user?.userOrganisationId || "")
            localStorage.setItem("cgm", user?.currentCompanyId || "")
            localStorage.setItem("skv", user?.userId || "")
            return {
              access_token: token,
              status: "Success",
              user: user,
            }
          }
        },

        /**
         * @param token - access token
         * @param email - email
         * @param password - password
         * @returns {Promise<{access_token: any; status: any; user: any}>}
         * @description - login switch function lets you switch from employee to admin profile and vice versa
         */
        loginSwitch: async (userId) => {
          get().clearAuthDataStorage()

          const response = await request.post({
            url: "/identity/authenticate?version_number=2",
            body: { user_id: userId },
          })

          if (response.status === "Success") {
            const token = response.access_token
            return {
              access_token: response.access_token,
              status: "Success",
              message: response.message,
            }
          } else {
            return {
              access_token: response.access_token,
              status: "error",
              message: response.message,
            }
          }
        },

        login: async (email, password, token, auth_code, provider) => {
          get().clearAuthDataStorage()

          const response = await request.post({
            url: "/identity/authenticate",
            body: { username: email, password, auth_code, provider },
          })
          const refresh_token = response.refresh_token

          if (response.status === "Success") {
            const token = response.access_token
            const refresh_token = response.refresh_token
            localStorage.setItem("pade_auth_token", token)
            localStorage.setItem("refresh_token", refresh_token)
            localStorage.setItem("bgr", response.userid)

            set({
              authToken: token,
              isAuthenticated: true,
              splashScreen: true,
            })

            const { user } = await get().getUser()

            localStorage.setItem("xfr", user?.userOrganisationId || "")
            localStorage.setItem("cgm", user?.currentCompanyId || "")
            localStorage.setItem("skv", user?.userId || "")

            set({
              splashScreen: false,
            })

            return {
              access_token: response.access_token,
              refresh_token,
              status: response.status,
              user: user,
              message: response.message,
            }
          } else if (response.status === "TwoFactorRequired") {
            const token = response.access_token

            return {
              access_token: response.access_token,
              refresh_token,
              status: response.status,
              user: response?.username,
              message: response.message,
            }
          } else if (token !== undefined) {
            localStorage.setItem("pade_auth_token", token)
            localStorage.setItem("refresh_token", refresh_token)
            set({
              authToken: token,
              isAuthenticated: true,
              splashScreen: true,
            })
            const { user } = await get().getUser()
            set({
              splashScreen: false,
            })
            // check if token is valid or not
            if (user?.isAdmin === undefined) {
              get().clearAuthDataStorage()
              return {
                access_token: token,
                refresh_token,
                status: "error",
                user: {},
                message: response.message,
              }
            } else {
              localStorage.setItem("xfr", user?.userOrganisationId || "")
              localStorage.setItem("cgm", user?.currentCompanyId || "")
              localStorage.setItem("skv", user?.userId || "")
              return {
                access_token: token,
                refresh_token,
                status: "Success",
                user: user,
                message: response.message,
              }
            }
          } else {
            return {
              access_token: response.access_token,
              refresh_token,
              status: response.status,
              user: {},
              message: response.message,
            }
          }
        },
        verifyTwoFACode: async (token, generated, code) => {
          get().clearAuthDataStorage()

          const response = await request.post({
            url: `/account/2fa/verifycode`,
            body: {
              generated,
              code,
            },
            headers: {
              access_token: `${token}`,
            },
          })
          const refresh_token = response.refresh_token

          if (response.status === "Success") {
            const token = response.access_token
            const refresh_token = response.refresh_token
            localStorage.setItem("pade_auth_token", token)
            localStorage.setItem("refresh_token", refresh_token)
            localStorage.setItem("bgr", response.userid)

            set({
              authToken: token,
              isAuthenticated: true,
            })

            const { user } = await get().getUser()
            localStorage.setItem("xfr", user?.userOrganisationId || "")
            localStorage.setItem("cgm", user?.currentCompanyId || "")
            localStorage.setItem("skv", user?.userId || "")

            return {
              access_token: response.access_token,
              refresh_token,
              status: response.status,
              user: user,
              message: response.message,
            }
          } else if (response.status === "Failed") {
            return {
              access_token: "",
              refresh_token: "",
              status: response.status,
              user: {},
              message: response.message,
            }
          } else if (token !== undefined) {
            localStorage.setItem("pade_auth_token", token)
            localStorage.setItem("refresh_token", refresh_token)
            set({
              authToken: token,
              isAuthenticated: true,
            })
            const { user } = await get().getUser()
            // check if token is valid or not
            if (user?.isAdmin === undefined) {
              get().clearAuthDataStorage()
              return {
                access_token: token,
                refresh_token,
                status: "error",
                user: {},
                message: response.message,
              }
            } else {
              localStorage.setItem("xfr", user?.userOrganisationId || "")
              localStorage.setItem("cgm", user?.currentCompanyId || "")
              localStorage.setItem("skv", user?.userId || "")
              return {
                access_token: token,
                refresh_token,
                status: "Success",
                user: user,
                message: response.message,
              }
            }
          } else {
            return {
              access_token: "",
              refresh_token: "",
              status: response.status,
              user: {},
              message: response.message,
            }
          }
        },

        /**
         * @param access_token - access token
         * @param newUser - new user object
         * @returns {Promise<{user: Partial<IUser>}>}
         * @description - change organization function lets you switch between companies
         */

        changeOrganization: async (access_token, newUser) => {
          const token = access_token
          localStorage.setItem("pade_auth_token", token)
          localStorage.setItem("xfr", newUser?.userOrganisationId || "")
          localStorage.setItem("cgm", newUser?.currentCompanyId || "")
          localStorage.setItem("skv", newUser?.userId || "")

          set({
            authToken: token,
            isAuthenticated: true,
            user: newUser,
          })

          return {
            access_token: access_token,
            user: newUser,
          }
        },

        getUser: async () => {
          const resp = await request.get({
            url: "/account/getusersession",
          })

          set({
            user: resp,
          })

          return {
            user: resp,
          }
        },
        checkUserSession: async () => {
          const response = await request.get({
            url: "/account/checksession",
          })

          if (response.status === "success") {
            const token = response.access_token
            const refresh_token = response.refresh_token
            localStorage.setItem("pade_auth_token", token)
            localStorage.setItem("refresh_token", refresh_token)
            set({
              authToken: token,
              isAuthenticated: true,
            })
          }

          return {
            user: response,
          }
        },
        signOut: (keepRedirect = false) => {
          get().clearAuthDataStorage()

          if (keepRedirect) {
            useAuthModalStore.getState().open()
            // const presentLocation = window.location.href
            // window.location.href = `/auth/login?redirect=${encodeURIComponent(
            //   presentLocation
            // )}`
          } else {
            window.location.href = `/auth/login`
          }
        },
      }),
      {
        name: `${APP_ID}.auth`,
      }
    )
  )
)
