import { XMarkIcon } from "@heroicons/react/24/outline"
import { Button, Input, Label, ModalWrapper, TextareaInput } from "components"
import React, { useState } from "react"

import { FileDragAndDrop } from "components/inputs/FileDragAandDrop"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { toast } from "react-toastify"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { Form, InputNumber, Select } from "antd"

type props = {
  metric: string
  progress: string
  closeModal: Function
  handleSuccess: Function
  employee_objective_id: string
  totalTarget: string
  employeeName: string
}
export const UpdateMeasurement: React.FC<props> = ({
  metric,
  progress,
  closeModal,
  employee_objective_id,
  handleSuccess,
  totalTarget,
  employeeName,
}) => {
  const [form] = Form.useForm()

  const target = Form.useWatch("target", form)
  const [comment, setComment] = useState("")
  const [file, setFile] = useState("")

  const { isLoading, mutate: updateProgress } = useMutation({
    mutationFn: async (body: any) => {
      const headers = {
        "Content-Type": "multipart/form-data",
      }

      const res = await axiosInstance.post(
        `employee/performance/update_employeeobjective_progress`,
        body,
        { headers }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        closeModal()
        handleSuccess()
      } else {
        toast.error(data?.msg)
      }
    },
  })
  const allMetrics = [
    {
      label: "%",
      id: 2,
    },
    {
      label: "N",
      id: 3,
    },
    {
      label: "#",
      id: 4,
    },
  ]
  const updatedMetric = allMetrics.find((met) => met.id == Number(metric))

  const binaryOptions = [
    {
      value: "100",
      label: "Achieved",
    },
    {
      value: "0",
      label: "Not Achieved",
    },
  ]

  const OnFinish = (values: any) => {
    const body = {
      id: employee_objective_id,
      progress: values?.target,
      comment: values?.comment,
      file_upload: file,
    }
    updateProgress(body)
  }
  return (
    <CustomModal
      title={`Update Progress for ${employeeName}
`}
      open={true}
      onCancel={() => {
        closeModal()
      }}
      className=" !z-[100]"
    >
      <Form
        form={form}
        onFinish={OnFinish}
        layout="vertical"
        initialValues={{
          target:
            Number(metric) === 5
              ? String(progress) === "100"
                ? "100"
                : "0"
              : progress,
        }}
      >
        <div className="">
          {Number(metric) === 5 ? (
            <Form.Item
              name="target"
              label={
                <span className="font-circular text-padeBlack text-sm ">
                  Current Progress
                </span>
              }
              rules={[{ required: true, message: "Please enter this field!" }]}
              className="w-full"
            >
              <Select
                options={binaryOptions?.map((option, index) => ({
                  value: option?.value,
                  label: option?.label,
                }))}
                placeholder="Choose Progress"
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <span className="font-circular text-padeBlack text-sm ">
                  Current Progress
                </span>
              }
              extra={<span>Target :{totalTarget}</span>}
              required
              name="target"
              rules={[{ required: true, message: "Please enter this field!" }]}
            >
              <InputNumber<number>
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  value?.replace(/,/g, "") as unknown as number
                }
                className="w-full font-circular text-neutral400"
                addonBefore={updatedMetric?.label ?? ""}
                placeholder="e.g 200"
              />
            </Form.Item>
          )}
          <Form.Item
            label={
              <span className="font-circular text-padeBlack text-sm ">
                Comment
              </span>
            }
            required
            name="comment"
            rules={[{ required: true, message: "Please enter this field!" }]}
          >
            <TextareaInput placeholder="Enter comment" />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-circular text-padeBlack text-sm ">
                Proof(optional)
              </span>
            }
            name="proof"
          >
            <FileDragAndDrop setFile={setFile} />
          </Form.Item>
          <div className="w-full flex gap-4 justify-end mt-6   shadow-footer  bg-white ">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              submitting={isLoading}
            >
              Update{" "}
            </Button>
          </div>
        </div>
      </Form>
    </CustomModal>
  )
}
