import GBPFlag from "assets/svg/uk_flag.svg"

import Global from "assets/svg/countries/Global.svg"

export const availableFlags = [
  {
    code: "Global",
    flag: Global,
    symbol: "",
  },
  {
    code: "GBP",
    flag: "https://padefiles.blob.core.windows.net/images/0d45f6fa-31b7-473e-9f07-22a4719a1b8f.png",
    symbol: "£",
  },

  {
    code: "NGN",
    flag: "https://padefiles.blob.core.windows.net/images/c5426cfe-c3a9-44c3-bbff-d924ab022d14.png",
    symbol: "₦",
  },
  {
    code: "USD",
    flag: "https://padefiles.blob.core.windows.net/images/c95e0752-c489-4e80-87f4-ea45950f1ad3.png",
    symbol: "$",
  },
  {
    code: "KES",
    flag: "https://padefiles.blob.core.windows.net/images/55b53c00-ca07-4a35-9001-56e00339886d.png",
    symbol: "Sh",
  },
  {
    code: "CAD",
    flag: "https://padefiles.blob.core.windows.net/images/2d0f27f3-3ab4-4385-a270-417f6e1f0feb.png",
    symbol: "$",
  },

  {
    code: "GHS",
    flag: "https://padefiles.blob.core.windows.net/images/1bbc83d5-30db-4fe1-9430-7cd098a59c73.png",
    symbol: "₵",
  },
  {
    code: "ETB",
    flag: "https://padefiles.blob.core.windows.net/images/3ad1c073-9e34-46ea-8d1c-48c1387bcecf.png",
    symbol: "Br",
  },
  {
    code: "MWK",
    flag: "https://padefiles.blob.core.windows.net/images/c7862328-a19c-426d-b3d0-8e2e66454567.png",
    symbol: "MK",
  },
  {
    code: "RWF",
    flag: "https://padefiles.blob.core.windows.net/images/5657006e-a132-4559-bc6a-57febdc11ead.png",
    symbol: "Fr",
  },
  {
    code: "TZS",
    flag: "https://padefiles.blob.core.windows.net/images/f80f5c11-651c-4e90-94e0-2ac5aeae1d9c.png",
    symbol: "Sh",
  },
  {
    code: "SLL",
    flag: Global, //Still needs to be updated
    symbol: "Le",
  },
  {
    code: "EUR",
    flag: Global, //Still needs to be updated
    symbol: "€",
  },
  {
    code: "ZAR",
    flag: "https://padefiles.blob.core.windows.net/images/c900d71b-5203-48c7-8bb3-44322e39f5c6.png",
    symbol: "R",
  },

  {
    code: "UGX",
    flag: "https://padefiles.blob.core.windows.net/images/4bb40983-0899-40a6-ac4f-333772acac8d.png",
    symbol: "USh",
  },
]


export const getCurrencyDataBySymbol = (symbol: string) => {
  return availableFlags.find((i) => i.symbol === symbol)
}

export const getCurrencyDataByCode = (currCode: string) => {
  return availableFlags.find((i) => i.code === currCode)
}