import { splashcreenImg } from "index"

const Splashscreen = () => {
  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-white"
      style={{
        zIndex: 9999999999999,
      }}
    >
      <img
        src={splashcreenImg}
        alt=""
        className=" w-40"
      />
    </div>
  )
}

export default Splashscreen
