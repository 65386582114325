import axios from "axios"
import { useAppNotificationStore, useAuthStore } from "../stores"
import { baseUrl } from "./constants"
import { extendedAppUrl, walletBaseUrl, walletPublicKey } from "./newbaseurl"

type Request = {
  /**
   * the request endpoint
   *
   * OR
   *
   * the external resource url
   */
  url: string
  /**
   * the request body for POST, PUT, PATCH, and DELETE methods
   */
  body?: object | string | URLSearchParams | FormData | File | Blob
  params?: URLSearchParams
  headers?: Headers | {}
  external?: boolean
}

type RequestMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT" | "HEAD"

export const axiosInstance = axios.create({
  baseURL: baseUrl,
})

//const { user, authToken } = useAuthStore()

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("pade_auth_token")
    const refresh_token = localStorage.getItem("refresh_token")
    const userId = localStorage.getItem("skv")
    const organisationId = localStorage.getItem("xfr")
    const currentCompanyId = localStorage.getItem("cgm")
    const padeVersion = organisationId + "." + currentCompanyId + "." + userId

    if (padeVersion) config.headers["Api-Version"] = `${padeVersion}`
    if (token) {
      config.headers["access_token"] = `${token}`
      config.headers["refresh_token"] = `${refresh_token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.status === 406) {
      await useAuthStore.getState().signOut(true)
    }
    return response
  },
  async (error) => {
    console.log(error)
    if (error.response.status === 401 || error.response.status === 400) {
      const { checkUserSession } = useAuthStore.getState()
      await checkUserSession()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

const requestCore = async (method: RequestMethod, config: Request) => {
  try {
    // const { checkUserSession } = useAuthStore()
    let { url, body, params, headers, external } = config

    external = external ?? false
    body = method === "GET" || method === "HEAD" ? undefined : body
    headers =
      headers === undefined
        ? { "Content-Type": "application/json" }
        : { "Content-Type": "application/json", ...headers }
    // , "Sec-Fetch-Mode": "no-cors"

    const response = await axiosInstance({
      baseURL: external === true ? url : baseUrl,
      url: external === true ? "" : url,
      method: method,
      headers: headers,
      data: body,
      params: params,
      validateStatus: function (status) {
        return status >= 200 && status < 500
      },
    })

    if (response.status === 406) {
      //useAuthStore.getState().signOut(true)
      // console.log(response?.statusText)
      // const resp = await checkUserSession();
      return
    } else if (response.status === 403) {
      // useAppNotificationStore.getState().toast.error(response?.data?.message)
      // console.log(response?.statusText)
      return
    } else return response.data
  } catch (error: any) {
    if (error.code === "ERR_NETWORK") {
      useAppNotificationStore
        .getState()
        .toast.error("Please check your network connection!")
      return
    } else {
      console.log(error.response?.data.message ?? "")
      throw new Error(error?.response?.statusText + ". Please try again later.")
    }
  }
}

/**
 * Make request easily to the API or other external resource by
 * passing true to the `external` option.
 */
export const request = {
  /**
   * Make /GET request
   * @param config request configurations
   * @returns Promise
   */
  get: async (config: Request) => {
    //  return await useRequestCore(config)
    return await requestCore("GET", config)
  },
  /**
   * Make /POST request
   * @param config request configurations
   * @returns Promise
   */
  post: async (config: Request) => {
    return await requestCore("POST", config)
  },
  /**
   * Make /POST request
   * @param config request configurations
   * @returns Promise
   */
  patch: async (config: Request) => {
    return await requestCore("PATCH", config)
  },
  /**
   * Make /PATCH request
   * @param config request configurations
   * @returns Promise
   */
  put: async (config: Request) => {
    return await requestCore("PUT", config)
  },
  /**
   * Make /DELETE request
   * @param config request configurations
   * @returns Promise
   */
  delete: async (config: Request) => {
    return await requestCore("DELETE", config)
  },
}

/**
 * @description This is used to manage other calls from another api
 * @summary for app extensions
 */

export const extendedAxiosInstance = axios.create({
  baseURL: extendedAppUrl,
})

//axios instance (set up interceptors)
extendedAxiosInstance.interceptors.request.use(
  async (config) => {
    const { authToken } = useAuthStore.getState()
    if (authToken) {
      config.headers["access_token"] = `${authToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
extendedAxiosInstance.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      const { checkUserSession } = useAuthStore.getState()
      await checkUserSession()
      return error
      // return Promise.reject(error)
    }
    if (error.response.status === 406) {
      useAuthStore.getState().signOut(true)

      return error
      // return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)
export const publicExtendedAxiosInstance = axios.create({
  baseURL: extendedAppUrl,
})

//axios instance (set up interceptors)
publicExtendedAxiosInstance.interceptors.request.use(
  async (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
publicExtendedAxiosInstance.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * Wallet Service
 */
export const walletAxiosInstance = axios.create({
  baseURL: walletBaseUrl,
})

walletAxiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("pade_auth_token")
    if (accessToken) {
      config.headers["access_token"] = `${accessToken}`
      config.headers["api_key"] = `${walletPublicKey}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

walletAxiosInstance.interceptors.response.use(
  async (response) => {
    const { signOut } = useAuthStore.getState()
    if (response.status === 401) {
      signOut(true)
      return response
    }
    return response
  },
  (error) => {
    const { signOut } = useAuthStore.getState()
    if (error.response.status === 401) {
      signOut(true)
    }

    return Promise.reject(error)
  }
)
