import React from "react"
import { H4 } from "../typography/H4"
import { P1 } from "../typography/P1"
import { LinkButton } from "../buttons/LinkButton"
import { Alert } from "antd"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"
import RedAlert from "../../assets/svg/alert.svg"

const AccountSuspensionPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="sticky top-0 z-20 font-avenir  ">
        <Alert
          message="Your subscription invoice is overdue "
          type="error"
          showIcon
          icon={<ExclamationTriangleIcon className=" h-12 w-12" />}
          description={`Your subscription invoice is overdue. Please settle your payment to continue using this service .`}
          // description={`Your subscription invoice is 2 days overdue. To avoid service interruption, please settle your payment within the next 8 days.`}
          action={
            <div className="pr-5">
              <button
                onClick={() => {
                  navigate("/dashboard/settings/plan-and-billing/invoices")
                }}
                className="bg-[#EB3949] px-4 py-1 text-white rounded"
              >
                Pay now{" "}
              </button>
            </div>
          }
          className="bg-[#FEE7EC] invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center  "
        />
      </div>{" "}
      <div className="w-full h-[70vh]  flex justify-center items-center">
        <div className="w-[601px] h-full bg-white p-4 flex flex-col justify-center items-center ">
          <div className="text-center flex flex-col items-center ">
            <div className="h-[97px] w-[97px] flex justify-center items-center mb-2 ">
              <img
                src={RedAlert}
                alt="User Profile"
              />
            </div>
            <h5 className="font-bold text-[32px] text-black mb-2">
              <code>Temporary account suspension</code>
            </h5>
            <h6 className="text-center text-sm text-neutral600">
              We regret to inform you that your access to PaidHR has been
              temporarily suspended due to an overdue payment. To restore full
              service, please settle your outstanding invoice now.
            </h6>
          </div>

          <div className="w-full flex justify-center mt-6">
            <LinkButton
              title="Return to dashboard"
              to="/dashboard/settings/plan-and-billing/invoices"
              color="primary"
              className=""
            >
              Pay now
            </LinkButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountSuspensionPage
