import React from "react"
import { InboxOutlined } from "@ant-design/icons"
import { UploadProps } from "antd"
import { Upload } from "antd"
import { H6 } from "components/typography/H6"
import { P3 } from "components/typography/P3"
import { Button } from "components/buttons/Button"

const { Dragger } = Upload

const options = {
  onFileUploadFinished: (file: any) => {
    console.log(`this is the file:${file}`)
  },
}
// client.picker(options).open()

const props: UploadProps = {
  name: "file",
  multiple: true,
  accept: ".pdf,.doc,.docx,.xml,application/msword",
  //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  // beforeUpload:{()=>console.log(File)},
  beforeUpload(file, FileList) {},

  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files)
  },
}

export const FileDragAndDrop: React.FC<{
  setFile: Function
  listType?: "text" | "picture" | "picture-card" | "picture-circle"
}> = ({ setFile, listType = "picture" }) => {
  return (
    <Dragger
      // multiple
      listType={listType}
      accept=".pdf"
      // accept=".pdf,.doc,.docx,.xml,application/msword,.jpeg"
      name="file"
      className=" font-avenir"
      // onChange={uploadImage}

      onChange={(file) => {
        console.log(file)
        // alert("changed")
      }}
      beforeUpload={(file) => {
        setFile(file)
        return false
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <H6 className="text-dark10 font-bold  text-sm leading-[21px]">
        Drop your files here or
      </H6>

      <P3 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
        Only {""}
        {/* JPG, PNG or */}
        PDF with max size of 15MB
      </P3>
      <Button
        color="primary"
        title="Choose file"
        type="button"
      >
        Choose file
      </Button>
    </Dragger>
  )
}
