import React, { useEffect, useRef, useState } from "react"
import { Button } from "components"
import passwordHidden from "assets/illustrations/password_hidden.svg"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { newBaseUrl } from "utils/newbaseurl"
import { useAppNotificationStore } from "stores"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useNavigate } from "react-router-dom"

const SetEWAPin = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [transactionPin, setTransactionPin] = useState("")
  const [otp, setOtp] = useState<string[]>(["", "", "", ""])
  const [confirmOtp, setConfirmOtp] = useState<string[]>(["", "", "", ""])
  const [isPinComplete, setIsPinComplete] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isConfirmPinComplete, setIsConfirmPinComplete] = useState(false)
  const [pinError, setPinError] = useState("")

  const { isLoading: isUpdatingPinLoading, mutate: submitUpdate } = useMutation(
    {
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `${newBaseUrl}/v1/employees/update-pin`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.success) {
          navigate(-1)
          mixPanelEvent(`empl-ewa-pin-creation-success`)
          data.msg && toast.success(data.msg)
          navigate(-1)
        } else {
          data.msg && toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        data.msg && toast.error(data.msg)
      },
    }
  )

  const otpInputs = useRef<(HTMLInputElement | null)[]>([])
  const confirmOtpInputs = useRef<(HTMLInputElement | null)[]>([])

  useEffect(() => {
    setIsPinComplete(otp.every((digit) => digit !== ""))
    setIsConfirmPinComplete(confirmOtp.every((digit) => digit !== ""))
    if (isPinComplete && isConfirmPinComplete) {
      setPinError(
        otp.join("") !== confirmOtp.join("") ? "Pins do not match" : ""
      )
    }
  }, [otp, confirmOtp, isPinComplete, isConfirmPinComplete])

  const handleChange = (
    index: number,
    value: string,
    otpState: string[],
    setState: React.Dispatch<React.SetStateAction<string[]>>,
    ref: React.RefObject<(HTMLInputElement | null)[]>
  ) => {
    if (isNaN(Number(value))) return

    const newOtp = [...otpState]
    newOtp[index] = value
    setState(newOtp)
    setPinError("")
    if (
      value !== "" &&
      index < newOtp.length - 1 &&
      ref.current &&
      ref.current[index + 1]
    ) {
      ref.current[index + 1]?.focus()
    }
  }

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const pastedData = event.clipboardData.getData("text")
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault()
      return
    }

    const newOtp = pastedData.split("").slice(0, otp.length)
    setState(newOtp)
  }

  const handlePinUpdate = () => {
    if (otp.join("") !== confirmOtp.join("")) {
      setPinError("Pins do not match")
      return
    }
    mixPanelEvent(`empl-ewa-pin-creation-init`)
    submitUpdate({
      transactionPin: otp.join(""),
    })
  }

  return (
    <DashboardWrapper>
      <CustomModal
        title="Set Transaction Pin"
        open={true}
        onCancel={() => {
          navigate(-1)
        }}
        width={450}
        footer={
          <div className="flex items-center gap-4 w-full justify-end">
            <Button
              color="neutral"
              title="Cancel"
              onClick={() => {
                showConfirm ? setShowConfirm(false) : navigate(-1)
              }}
            >
              Cancel
            </Button>
            <Button
              title="Confirm"
              color="primary"
              submitting={isUpdatingPinLoading}
              onClick={() => {
                if (!showConfirm) {
                  isPinComplete
                    ? setShowConfirm(true)
                    : setPinError("Please complete your pin")
                } else {
                  isConfirmPinComplete
                    ? handlePinUpdate()
                    : setPinError("Please complete your pin")
                }
              }}
              disabled={pinError !== "" ? true : false}
            >
              {!showConfirm ? "Confirm" : "Save"}
            </Button>
          </div>
        }
      >
        <p className="w-full text-padeBlack text-sm mb-[37px]">
          To be able to successfully withdraw from your daily earnings, you have
          to set up your transaction pin
        </p>
        <div className="flex flex-col justify-between w-full items-center bg-white  px-4 max-w-[572px]">
          <img
            src={passwordHidden}
            alt="password"
            className="w-[59px] h-[59px]"
          />
          {/* PIN Input */}
          {!showConfirm && (
            <div className="mt-[37px] w-full">
              <p className="text-sm text-padeBlack mb-5 text-center">
                Please enter your 4-digit PIN
              </p>
              <div className="flex gap-[22px] justify-between">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength={1}
                    value={digit}
                    onChange={(e) =>
                      handleChange(
                        index,
                        e.target.value,
                        otp,
                        setOtp,
                        otpInputs
                      )
                    }
                    onPaste={(e) => handlePaste(e, setOtp)}
                    ref={(ref) => {
                      if (otpInputs.current) {
                        otpInputs.current[index] = ref
                      }
                    }}
                    className="border w-[50px] md:w-[60px] h-[60px] rounded-5px text-center text-lg font-bold active:border-primary500"
                    style={{
                      fontFamily: "PasswordFont",
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Confirm PIN Input */}
          {showConfirm && (
            <div className="mt-[37px] w-full">
              <p className="text-sm text-padeBlack mb-5  text-center">
                Confirm your 4-digit PIN
              </p>
              <div className="flex gap-[22px] justify-between">
                {confirmOtp.map((digit, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength={1}
                    value={digit}
                    onChange={(e) =>
                      handleChange(
                        index,
                        e.target.value,
                        confirmOtp,
                        setConfirmOtp,
                        confirmOtpInputs
                      )
                    }
                    onPaste={(e) => handlePaste(e, setConfirmOtp)}
                    ref={(ref) => {
                      if (confirmOtpInputs.current) {
                        confirmOtpInputs.current[index] = ref
                      }
                    }}
                    className="border w-[50px] md:w-[60px] h-[60px] rounded-5px text-center text-lg font-bold active:border-primary500"
                    style={{
                      fontFamily: "PasswordFont",
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {pinError && <p className="text-red-500 mt-2">{pinError}</p>}
        </div>
      </CustomModal>
    </DashboardWrapper>
  )
}

export default SetEWAPin
