import { Button } from "components"
import React, { useState } from "react"
import { Select, Form, Space, Radio, InputNumber, Tooltip, Input } from "antd"
import { Link, useNavigate } from "react-router-dom"
// import EnterPin from "../pin/EnterPin"
import { useAppNotificationStore, useAuthStore } from "stores"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { useGet, usePost } from "utils/useFetch"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"
import { availableFlags } from "utils/flags"
import Global from "assets/svg/countries/Global.svg"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  handleProceed: () => void
  found: any | undefined
  currencyCode: string
}

const SingleSchedule = ({
  setPreviewData,
  handleProceed,
  found,
  currencyCode,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const { toast } = useAppNotificationStore()
  const [useBeneficiary, setUseBeneficiary] = useState(false)

  const accountNumber = Form.useWatch("account_number", form)
  const amount = Form.useWatch("amount", form)
  const description = Form.useWatch("description", form)
  const includeExitedValue = Form.useWatch("includeExited", form)
  const [sendToEmployees, setSendToEmployees] = useState(true)
  const [nameError, setNameError] = useState("")
  const [updatedCurrCode, setUpdCurrCode] = useState(currencyCode)
  const [updatedSymbol, setUpdSymbol] = useState(found?.symbol || "")
  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    accountNumber: "",
    accountName: "",
    beneficiaryName: "",
    beneficiaryType: "",
    routingType: "",
    routingCode: "",
  })
  const { isLoading: isEmployeeLoading, data: employees } = useGet<any[]>({
    url: `/admin/people/getforselectionwithaccountdetails?active=${!includeExitedValue}&currency_code=${updatedCurrCode}`,
    // enabled: includeExitedValue !== undefined,
  })
  const { isLoading: isContractorsLoading, data: contractors } = useGet<any[]>({
    url: `/admin/contractors/getforselectionwithaccountdetails?currency_code=${updatedCurrCode}`,
  })
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies?forpayment=true",
    body: {
      q: "",
      page: "",
    },
  })

  const { isLoading: isCheckingScheduleName, mutate: checkScheduleName } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body.description}&payment_schedule_id=${body.id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "error" && data.msg) {
          setNameError(data.msg)

          toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        setNameError(data.msg)
        toast.error(data.msg)
      },
    })

  const onFinish = (values: any) => {
    const { contractor, employee } = values

    setPreviewData([
      {
        beneficiary: beneficiaryInfo?.beneficiaryName,
        account_name: beneficiaryInfo?.accountName,
        beneficiary_id: sendToEmployees ? employee : contractor,
        beneficiary_type: beneficiaryInfo?.beneficiaryType,
        account_number: beneficiaryInfo?.accountNumber,
        routing_type: beneficiaryInfo?.routingType,
        routing_code: beneficiaryInfo?.routingCode,
        amount: amount ?? "0",

        role: sendToEmployees ? "employee" : "contractor",
        description,
      },
    ])

    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px]">
            <div className="  mt-5 pb-8 flex flex-col  items-start w-full">
              <div className="w-full">
                <Form.Item
                  // className="!mb-0"
                  name="description"
                  label={
                    <span className="text-padeBlack text-sm font-circular flex items-center">
                      Payment description
                      <Tooltip
                        title="Mamixum of 50 characters"
                        trigger={["hover"]}
                      >
                        <span>
                          <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                        </span>
                      </Tooltip>
                    </span>
                  }
                  extra={
                    nameError && (
                      <span className="text-danger500">{nameError}</span>
                    )
                  }
                  rules={[
                    { required: true, message: "Please enter this field!" },

                    {
                      validator: async (_, value) => {
                        const wordCount = value
                          ? value.trim().split(/\s+/).length
                          : 0
                        if (wordCount > 50) {
                          throw new Error(
                            "Description must be 50 words or less"
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter"
                    maxLength={50}
                    onChange={(e) => setNameError("")}
                    onBlur={(e) => {
                      if (e.target.value) {
                        // Perform additional onBlur actions if needed
                        checkScheduleName({
                          description: e.target.value,
                          id: "-1",
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  label={
                    <span className="font-circular text-padeBlack text-sm">
                      Beneficiary currency type{" "}
                    </span>
                  }
                  name="currency_type"
                  rules={[
                    { required: true, message: "Please enter this field!" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    loading={isCurrencyLoading}
                    onChange={(e) => {
                      console.log(e)
                      const currency = currencies?.find(
                        (item) => item?.code == e
                      )
                      setUpdCurrCode(e)
                      setUpdSymbol(currency?.symbol)
                    }}
                    options={currencies?.map((curr) => ({
                      label: (
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <img
                              src={
                                availableFlags?.find(
                                  (item) => item.code === curr.code
                                )?.flag || Global
                              }
                              alt={curr?.code}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                                marginLeft: "8px",
                              }}
                            />
                            <span>{curr.code}</span>
                          </div>
                        </span>
                      ),
                      value: curr?.code,
                    }))}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  label={
                    <span className="text-padeBlack text-sm font-circular flex items-center">
                      Enter amount{" "}
                    </span>
                  }
                  name="amount"
                  required
                  rules={[
                    { required: true, message: "Please enter this field!" },
                  ]}
                >
                  <InputNumber<number>
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value?.replace(/,/g, "") as unknown as number
                    }
                    className="w-full font-circular text-neutral400"
                    addonBefore={updatedSymbol}
                    placeholder="e.g 200"
                  />
                </Form.Item>
              </div>
              <AntDesignRadio
                label="Who do you want to pay?"
                name="beneficiaryRole"
                value={sendToEmployees}
              >
                <Space direction="horizontal">
                  <Radio
                    value={true}
                    checked={sendToEmployees ? true : false}
                    onChange={() => {
                      setSendToEmployees(true)
                    }}
                  >
                    Employees{" "}
                  </Radio>
                  <Radio
                    value={false}
                    checked={sendToEmployees === false ? true : false}
                    onChange={() => {
                      setSendToEmployees(false)
                    }}
                  >
                    Contractors{" "}
                  </Radio>
                </Space>
              </AntDesignRadio>

              {sendToEmployees ? (
                <div className="w-full">
                  <Form.Item
                    label={
                      <span className="font-circular text-padeBlack text-sm">
                        Select employee
                      </span>
                    }
                    name="employee"
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      loading={isEmployeeLoading}
                      options={
                        isEmployeeLoading
                          ? []
                          : employees?.map((person) => ({
                              label: person.full_name,
                              value: person.person_id,
                            }))
                      }
                      onChange={(e) => {
                        const employee = employees?.find(
                          (cont) => cont?.person_id == e
                        )

                        const body = {
                          ...beneficiaryInfo,
                          accountNumber: employee?.account_number,
                          beneficiaryName: employee?.full_name,
                          accountName:
                            updatedCurrCode === "NGN"
                              ? employee?.full_name
                              : `${employee?.account_first_name} ${employee?.account_last_name}`,
                          beneficiaryType: employee?.beneficiary_type,
                          routingType: employee?.routing_type,
                          routingCode: employee?.routing_code,
                        }
                        setBeneficiaryInfo(body)
                      }}
                    />
                  </Form.Item>
                </div>
              ) : (
                <div className="w-full">
                  <Form.Item
                    label={
                      <span className="font-circular text-padeBlack text-sm">
                        Select contractor
                      </span>
                    }
                    name="contractor"
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      loading={isContractorsLoading}
                      options={
                        isContractorsLoading
                          ? []
                          : contractors?.map((cont) => ({
                              label: cont.name,
                              value: cont.contractor_id,
                            }))
                      }
                      onChange={(e) => {
                        const contractor = contractors?.find(
                          (cont) => cont?.contractor_id == e
                        )

                        const body = {
                          ...beneficiaryInfo,
                          accountNumber: contractor?.account_number,
                          beneficiaryName: contractor?.name,
                          accountName:
                            contractor?.account_company_name ??
                            `${contractor?.account_first_name} ${contractor?.account_last_name}`,
                          beneficiaryType: contractor?.beneficiary_type,
                          routingType: contractor?.routing_type,
                          routingCode: contractor?.routing_code,
                        }
                        setBeneficiaryInfo(body)
                      }}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default SingleSchedule
