import React, { useState } from "react"
import { MeasuredCard } from "./MeasuredCard"
import { useAppNotificationStore } from "stores"
import { Collapse } from "antd"
import DropdownCard, {
  Items,
} from "../../../../components/new-stuff/ui/DropdownCard"
import MeasuredBody from "./MeasuredBody"
const { Panel } = Collapse
type measurementprops = {
  completed: number
  total: number
  keyResultDetails: any[]
  refetchKeyData: Function
  totalTarget?: string
  showCompleted?: boolean
  showObj?: boolean
  isProgressUpdateAllowed: boolean
  permissionGranted: boolean
  isKRLocked: boolean
  myInitiativeSection?: boolean
  showUpdateProgressBtn?: boolean
}
const Measurements: React.FC<measurementprops> = ({
  completed,
  total,
  keyResultDetails,
  refetchKeyData,
  totalTarget,
  showCompleted = true,
  showObj = true,
  isProgressUpdateAllowed = true,
  permissionGranted = false,
  isKRLocked = false,
  myInitiativeSection = false,
  showUpdateProgressBtn = true,
}) => {
  const { toast } = useAppNotificationStore()
  const [activeEmpObjId, setActiveEmpObjId] = useState("")
  const Panels: Items[] = keyResultDetails.map((meas: any, index: any) => {
    return {
      key: meas?.employee_objective_id,
      header: (
        <MeasuredCard
          meas={meas}
          index={index}
          totalTarget={totalTarget}
          showObj={showObj}
          showUpdateProgressBtn={showUpdateProgressBtn}
          isKRLocked={
            myInitiativeSection
              ? meas.is_locked == "True"
                ? true
                : false
              : isKRLocked
          }
          isProgressUpdateAllowed={
            myInitiativeSection
              ? meas.is_locked == "True" &&
                meas.is_closed == "True" &&
                (meas.employee_objective_state_id == 2 ||
                  meas.employee_objective_state_id == 3)
                ? false
                : true
              : isProgressUpdateAllowed
          }
          permissionGranted={
            myInitiativeSection
              ? meas?.is_owner_current_user === "True"
                ? true
                : false
              : permissionGranted
          }
          refetchKeyData={refetchKeyData}
          handleSuccess={() => {
            toast.success("Progress has been successfully updated")
            refetchKeyData()
          }}
        />
      ),
      children: <MeasuredBody id={activeEmpObjId} />,
    }
  })

  return (
    <>
      <div className="font-avenir">
        {showCompleted && (
          <div className=" mb-4">
            <h6 className=" text-neutral700 text-sm font-extrabold">
              {completed}/{total} Complete
            </h6>
          </div>
        )}

        {keyResultDetails.length ? (
          <div>
            <DropdownCard
              rootClassName="p-0 "
              items={Panels}
              onChange={(key: any) => {
                // if (activeEmpObjId == key[0]) {
                //   setActiveEmpObjId("")
                // } else {
                setActiveEmpObjId(key[0])
                // }
              }}
            />
          </div>
        ) : (
          <div className=" h-20  flex justify-center items-center  text-neutral500 font-bold">
            No available measured initiative
          </div>
        )}
      </div>
    </>
  )
}

export default Measurements
