import { images, logos } from "assets"
import { Button, DashboardContainer } from "components"
import AntDCustomTable from "components/antd/AntDCustomTable"
import React, { useState, useEffect } from "react"
import { Contribution, IPayslipDetailed } from "../interface/Payslip"
import { useAuthStore } from "stores"
import { formatCurrencyNoSymbol } from "utils/currency"
import ImageComponent from "components/custom/image"
import { Table } from "antd"

const DownloadPayslipTemplate: React.FC<{
  data: IPayslipDetailed | undefined
}> = ({ data }) => {
  const { user } = useAuthStore()
  const [search, setSearch] = useState("")
  const [countSelected, setCountSelected] = useState(0)
  const [processSelected, setProcessSelected] = useState(false)

  const currentTableData =
    data?.earnings?.map((payment: Contribution) => ({
      // key: payment.person_id,
      description: payment?.description,
      current: `₦${formatCurrencyNoSymbol(Number(payment?.current))}`,
      yearToDate: {
        value: `₦${formatCurrencyNoSymbol(Number(payment?.ytd))}`,
        display_ytd: data?.display_ytd,
      },
    })) || []
  const TableData =
    [
      ...currentTableData,

      {
        description: "Total Earnings",
        current: `₦${formatCurrencyNoSymbol(Number(data?.total_earning))}`,
        yearToDate: "-",
      },
    ] || []

  const TableColumns =
    [
      {
        title: "Description",
        dataIndex: "description",
        width: "50%",
        render: (description: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {description}{" "}
          </span>
        ),
      },
      {
        title: "Current ",
        dataIndex: "current",
        width: "25%",
        render: (current: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {current}{" "}
          </span>
        ),
      },
      {
        title: `${data?.display_ytd == "Yes" ? "Year to date" : ""}`,
        dataIndex: "yearToDate",
        width: `${data?.display_ytd == "Yes" ? "25%" : ""}`,
        render: (yearToDate: { value: string; display_ytd: string }) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {yearToDate.display_ytd == "Yes" ? yearToDate.value : ""}{" "}
          </span>
        ),
      },
    ] || []
  const currentDeductionTableData =
    data?.deductions?.map((payment: Contribution) => ({
      // key: payment.person_id,
      description: payment?.description,
      current: `₦${formatCurrencyNoSymbol(Number(payment?.current))}`,
      yearToDate: {
        value: `₦${formatCurrencyNoSymbol(Number(payment?.ytd))}`,
        display_ytd: data?.display_ytd,
      },
    })) || []
  const DeductionTableData =
    [
      ...currentDeductionTableData,
      {
        description: "Total Deductions",
        current: `₦${formatCurrencyNoSymbol(Number(data?.total_deduction))}`,

        yearToDate: "-",
      },
    ] || []

  const DeductionTableColumns =
    [
      {
        title: "Description",
        dataIndex: "description",
        width: "50%",
        render: (description: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {description}{" "}
          </span>
        ),
      },
      {
        title: "Current ",
        dataIndex: "current",
        width: "25%",
        render: (current: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {current}{" "}
          </span>
        ),
      },
      {
        title: `${data?.display_ytd == "Yes" ? "Year to date" : ""}`,
        dataIndex: "yearToDate",
        width: `${data?.display_ytd == "Yes" ? "25%" : ""}`,
        render: (yearToDate: { value: string; display_ytd: string }) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {yearToDate.display_ytd == "Yes" ? yearToDate.value : ""}{" "}
          </span>
        ),
      },
    ] || []

  const currentContributionTableData =
    data?.contributions?.map((payment: Contribution) => ({
      // key: payment.person_id,
      description: payment?.description,
      current: `₦${formatCurrencyNoSymbol(Number(payment?.current))}`,
      yearToDate: {
        value: `₦${formatCurrencyNoSymbol(Number(payment?.ytd))}`,
        display_ytd: data?.display_ytd,
      },
    })) || []
  const ContributionTableData =
    [
      ...currentContributionTableData,
      {
        description: "Total Contributions",
        current: `₦${formatCurrencyNoSymbol(Number(data?.total_contribution))}`,
        yearToDate: "-",
      },
    ] || []

  const ContributionTableColumns =
    [
      {
        title: "Description",
        dataIndex: "description",
        width: "50%",
        render: (description: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {description}{" "}
          </span>
        ),
      },
      {
        title: "Current ",
        dataIndex: "current",
        width: "25%",
        render: (current: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {current}{" "}
          </span>
        ),
      },
      {
        title: `${data?.display_ytd == "Yes" ? "Year to date" : ""}`,
        dataIndex: "yearToDate",
        width: `${data?.display_ytd == "Yes" ? "25%" : ""}`,
        render: (yearToDate: { value: string; display_ytd: string }) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {yearToDate.display_ytd == "Yes" ? yearToDate.value : ""}{" "}
          </span>
        ),
      },
    ] || []

  const SummaryTableData =
    [
      {
        description: "Earnings",
        current: `₦${formatCurrencyNoSymbol(Number(data?.summary?.earnings))}`,
        yearToDate: {
          value: `₦${formatCurrencyNoSymbol(
            Number(data?.summary?.earnings_ytd)
          )}`,
          display_ytd: data?.display_ytd,
        },
      },
      {
        description: "Deductions",
        current: `₦${formatCurrencyNoSymbol(
          Number(data?.summary?.deductions)
        )}`,
        yearToDate: {
          value: `₦${formatCurrencyNoSymbol(
            Number(data?.summary?.deductions_ytd)
          )}`,
          display_ytd: data?.display_ytd,
        },
      },
      {
        description: "Take home",
        current: `₦${formatCurrencyNoSymbol(Number(data?.summary?.net_pay))}`,
        yearToDate: {
          value: `₦${formatCurrencyNoSymbol(
            Number(data?.summary?.net_pay_ytd)
          )}`,
          display_ytd: data?.display_ytd,
        },
      },
    ] || []

  const SummaryTableColumns =
    [
      {
        title: "Description",
        dataIndex: "description",
        width: "50%",
        render: (description: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {description}{" "}
          </span>
        ),
      },
      {
        title: "Current ",
        dataIndex: "current",
        width: "25%",
        render: (current: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {current}{" "}
          </span>
        ),
      },
      {
        title: `${data?.display_ytd == "Yes" ? "Year to date" : ""}`,
        dataIndex: "yearToDate",
        width: `${data?.display_ytd == "Yes" ? "25%" : ""}`,
        render: (yearToDate: { value: string; display_ytd: string }) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {yearToDate.display_ytd == "Yes" ? yearToDate.value : ""}{" "}
          </span>
        ),
      },
    ] || []

  const handSelectedRows = (selectedRowKeys: React.Key[]) => {
    // const documents = selectedRowKeys.map((key) => key.toString())
    // const newDocuments = [...documents]
    // documents.forEach((document) => {
    //   if (!newDocuments.includes(document)) {
    //     newDocuments.push(document)
    //   }
    // })
    setCountSelected(selectedRowKeys.length)
  }
  const paymentScheduleStatus = [{}]

  return (
    <div className="bg-white shadow-card  w-auto  ">
      <div className="flex flex-col sm:flex-row justify-between w-full items-center mb-6 gap-2 px-2 sm:px-[50px] sm:pt-6 ">
        <div>
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            {data?.payroll_month}
          </h4>
          <div className="flex items-center gap-4">
            <span className="text-black  text-opacity-50 text-sm">
              {" "}
              Pay Period
            </span>{" "}
            <span
              className="text-sm
               text-neutral700 font-bold"
            >
              {data?.pay_period}
            </span>
          </div>
        </div>
        <div className="w-[200px] h-[50px] max-h-[50px] max-w-[200px] object-contain ">
          <ImageComponent
            defaultImage={logos.PadeLogo}
            src={
              data?.employer_logo_url || user?.organisationAvatarFilename || ""
            }
            alt="org"
            className={` w-full h-full object-contain `}
          />
        </div>
      </div>
      <div className="h-auto sm:h-[117px] bg-primary50 px-2 sm:px-[50px] flex  flex-col sm:flex-row justify-between items-start gap-2   sm:items-center py-5 mb-9">
        <div className=" gap-4 flex items-center">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.employer_name || user?.userOrganisation}
            </h5>
            <p className="text-black  text-opacity-50 text-sm leading-snug">
              {data?.employer_address_line_1}
            </p>
            <p className="text-black  text-opacity-50 text-sm leading-snug">
              {data?.employer_address_line_2}
            </p>
          </div>
        </div>
        <div className="">
          <h5 className="text-lg text-neutral700 font-bold mb-2">
            {data?.full_name}
          </h5>
          <p className="text-black  text-opacity-50 text-sm leading-snug">
            {data?.employee_address_line_1}
          </p>
          <p className="text-black  text-opacity-50 text-sm leading-snug">
            {data?.employee_address_line_2}
          </p>
        </div>
      </div>
      <div className="mt-4 rounded-[10px] p-1 sm:p-[25px]">
        <div className="mb-[40px]">
          <h6 className="text-neutral700 font-bold text-base  mb-7">
            Employee Earnings
          </h6>
          <Table
            columns={TableColumns}
            dataSource={TableData}
            // onChange={handSelectedRows}
            className="text-[#42526D] border-none"
            pagination={false}
          />
        </div>
        <div className="mb-[40px]">
          <h6 className="text-neutral700 font-bold text-base  mb-7">
            Employee Deductions
          </h6>
          <Table
            columns={DeductionTableColumns}
            dataSource={DeductionTableData}
            pagination={false}
            className="text-[#42526D] border-none"
          />
        </div>
        <div className="mb-[40px]">
          <h6 className="text-neutral700 font-bold text-base  mb-7">
            Employer contributions{" "}
          </h6>
          <Table
            columns={ContributionTableColumns}
            dataSource={ContributionTableData}
            pagination={false}
            className="text-[#42526D] border-none"
          />
        </div>
        <div className="mb-[40px]">
          <h6 className="text-neutral700 font-bold text-lg  mb-7">Summary </h6>
          <Table
            columns={SummaryTableColumns}
            dataSource={SummaryTableData}
            className="text-[#42526D] border-none"
            pagination={false}
          />
        </div>
      </div>
    </div>
  )
}
export default DownloadPayslipTemplate
