import React, { useState } from "react"
import {
  ArrowRightOnRectangleIcon,
  ChevronUpIcon,
  LockClosedIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"
import { images, logos } from "assets"
import { useAuthStore } from "stores"

import { useGet } from "utils/useFetch"
import { Dropdown, Spin } from "antd"
import ImageComponent from "components/custom/image"
import { P2 } from "components/typography/P2"
import { createPortal } from "react-dom"
import { isPadeDomain } from "utils/utils"

const UserProfile: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate()
  const { signOut, user, authToken, loginSwitch } = useAuthStore()
  const [toSwitch, toggleToSwitch] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleSignout = () => {
    signOut()
    navigate("/auth/login")
  }

  const handleSwitch = async () => {
    toggleToSwitch(true)

    const userId = localStorage.getItem("bgr")

    const response = await loginSwitch(userId || "")
    if (response.status === "Success") {
      const authToken = response.access_token
      const encodedAuthToken = encodeURIComponent(authToken)
      const encodedUserId = encodeURIComponent(userId || "")

      const redirectUrl =
        "https://portal.paidhr.com/account/switch?token=" +
        encodedAuthToken +
        "&code=" +
        encodedUserId
      // window.open(redirectUrl, "_blank");
      window.location.href = redirectUrl
    } else {
      toggleToSwitch(false)
      navigate("/auth/login")
    }
  }

  const { data, isLoading } = useGet<any>({
    url: "/employee/notifications/select",
  })
  const handleCloseDropdown = () => {
    setDropdownOpen(false)
  }
  return (
    <div className="relative h-auto">
      {toSwitch ? (
        <>
          {createPortal(
            <div className=" flex  items-center justify-center py-10 bg-a11y/50 h-screen w-screen fixed inset-0 z-[9999999] ">
              <Spin size="large" />
            </div>,
            document.body
          )}
        </>
      ) : null}

      <div className="flex items-center gap-4 md:gap-6">
        {/* ===================== */}
        {/* ====Avatar & Name==== */}
        <Dropdown
          trigger={["click"]}
          open={dropdownOpen}
          onOpenChange={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          dropdownRender={(menu) => (
            <div>
              <div className="w-full  p-4 bg-white rounded-lg drop-shadow-2xl border">
                <div className="flex flex-col w-full gap-5">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col flex-wrap w-full">
                      <P2 className="font-semibold font-avenir text-neutral500 whitespace-nowrap">
                        {user?.userFullName}
                      </P2>
                      <p className="text-neutral300 font-avenir flex-wrap  text-xs w-11/12  ">
                        {user?.userName}
                      </p>
                    </div>
                  </div>
                </div>

                {user?.lastLogin ? (
                  <div className="flex w-full gap-3 px-1 py-2 item text-neutral500">
                    <LockClosedIcon className="w-4 stroke-2" />
                    <P2 className="text-sm  font-avenir">
                      Last Login: {user?.lastLogin}{" "}
                    </P2>
                  </div>
                ) : null}

                <button
                  onClick={handleSignout}
                  className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                >
                  <ArrowRightOnRectangleIcon className="w-4 stroke-2" />
                  <P2 className=" font-avenir">Log Out</P2>
                </button>
              </div>
            </div>
          )}
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="rounded-full border-[4px] border-primary50 w-9 h-9 md:w-[45px] md:h-[45px] flex justify-center items-center ">
              <ImageComponent
                className="object-cover rounded-full h-full w-full"
                src={user?.userAvatarFilename ?? images.DefaultProfilePicture}
                alt="User Profile"
              />
            </div>
            <P2 className="hidden md:block font-semibold font-avenir text-neutral500">
              {user?.userFullName}
            </P2>
            <ChevronUpIcon
              className={`hidden md:block ease-in-out rotate-180 transition-all w-4 stroke-[3px] text-neutral500`}
            />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export const TopNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className=" flex items-center justify-between w-full h-full px-4 md:px-6 lg:px-10 mx-auto ">
      {/* ========================= */}
      {/* ====Logo===== */}
      <div className="flex gap-4 md:hidden ">
        {isPadeDomain() ? (
          <img
            src={logos.PadeLogoNoName}
            alt="PaidHR Logo"
            className=""
            width={30}
            height={30}
          />
        ) : // <img
        //   src={customizationSettings?.brand?.menuLogoUrl}
        //   alt=""
        //   className="w-[100px]"
        // />
        null}

        {/* ========================= */}
        {/* ====Menu Drawer Btn===== */}
        <button
          className=" w-[27px] "
          type="button"
          onClick={() => {
            toggleSidebar(!isSidebarOpen)
            document.body.classList.add("overflow-hidden")
          }}
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>
      <div className="flex gap-4"></div>
      <UserProfile
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </div>
  )
}
