import { PaperClipIcon, PlusIcon } from "@heroicons/react/24/outline"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import React from "react"
import { useGet } from "utils/useFetch"
type measurementprops = {
  id?: string
}
const MeasuredBody: React.FC<measurementprops> = ({ id }) => {
  const groupPostBody = {
    search: "",
    length: "10",
    start: "1",
  }

  const {
    isLoading: progressLoading,
    data: progressData,
    refetch,
  } = useGet<any>({
    url: `/employee/performance/getemployeeobjectiveitems?employee_objective_id=${id}`,
    body: groupPostBody,
    enabled: !!id,
  })
  return (
    <div className=" bg-background p-4 sm:p-5 font-avenir rounded-5px">
      <h6 className="text-sm sm:text-base  text-neutral500  font-bold pb-2 border-b border-cardgrey">
        Progress Timeline
      </h6>
      <div className=" max-h-[220px] overflow-scroll scrollbar-hide">
        {progressLoading ? (
          <div className=" h-40 flex justify-center items-center">
            <LoadingIndicatorWhite />
          </div>
        ) : progressData?.length ? (
          progressData?.map((item: any, index: any) => (
            <div className="py-2 border-b border-cardgrey flex justify-between items-center">
              <div>
                <p className="text-[13px] sm:text-sm text-neutral500 font-semibold">
                  {item?.created_by_name} {item?.description}
                </p>
                <p className=" text-neutral300 font-light text-xs">
                  {item.created_date}
                </p>
              </div>
              {item?.document_url && (
                <a
                  href={item?.document_url}
                  target="_blank"
                >
                  <PaperClipIcon className="w-4 h-4" />
                </a>
              )}
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center my-10 text-neutral500 text-sm">
            No progress report
          </div>
        )}
      </div>
      {/* <div className="flex justify-end items-center mt-2">
        <button className="mt-2   text-sm text-primary500 flex items-center gap-2 mb-3">
          <PlusIcon className="w-4 stroke-2" />
          <p>Update progress</p>
        </button>
      </div> */}
    </div>
  )
}

export default MeasuredBody
