import { PencilIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"
import { icons } from "assets"
interface Props {
  title: string
  heading?: string
  address: string
  phone: string
  withEditIcon?: boolean
  className?: string
  id?: string
  addressKey?: string
}

const InfoCardDetails = ({
  address,
  phone,
  title,
  withEditIcon,
  className,
  id,
  heading,
  addressKey,
}: Props) => {
  return (
    <div className={`py-5 pb-10 px-6 ${className ? className : ""}`}>
      <div>
        <h2 className="text-[#42526D] font-circular  text-sm">{heading}</h2>

        <p className="text-[#5D6B82] font-avenir text-sm mb-1">{title}</p>

        <div className="flex justify-between">
          <p className="text-[#42526D] flex items-center gap-3 font-avenir font-semibold text-base">
            <icons.ManageWorkspaceIcon /> {address}
          </p>

          <div className="pr-2">
            {withEditIcon && (
              <Link
                to={`/dashboard/company-details/edit-workplace/${addressKey}`}
              >
                <PencilIcon className="text-[#5D6B82] w-6" />
              </Link>
            )}
          </div>
        </div>

        <p className="text-[#42526D] flex items-center gap-3 font-avenir text-sm">
          <icons.PhoneIcon /> {phone}
        </p>
      </div>
    </div>
  )
}

export default InfoCardDetails
