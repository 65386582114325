import {
  ChevronDownIcon,
  InformationCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline"
import StarFilled from "../../../../../../../assets/svg/star_filled.svg"

import StarUnFilled from "../../../../../../../assets/svg/star_unfilled.svg"

import React, { useState, useEffect } from "react"
import ImageComponent from "components/custom/image"
import { images } from "assets"
import {
  IReviewEmployeeData,
  IReviewEntityFeedbacks,
} from "../interface/listings"

import { useGet, usePost } from "utils/useFetch"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import SummaryTemplate from "./SummaryTemplate"
import StatCard from "components/new-stuff/ui/StatCard"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { Form, Input, InputNumber, Modal, Progress, Spin, Tooltip } from "antd"
import { Button, H5 } from "components"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { CustomInput } from "pages/dashboard/people/people/form"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { CustomInputNumber } from "."
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
export const Summary: React.FC<{
  ratings: any[]
  questions: any[]
  employeeReviewId: string | number
  invalidateQueries: Function
  feedback: IReviewEntityFeedbacks[] | any[]
  updatedEntityKey: any
  personId: any
  showEditSummary?: boolean
}> = ({
  questions,
  ratings,
  employeeReviewId,
  invalidateQueries,
  feedback,
  updatedEntityKey,
  personId,
  showEditSummary = true,
}) => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()

  const [activeQuestion, setActiveQuestion] = useState("")
  const [updatedQuestions, setUpdatedQuestions] = useState<any[]>([])
  const [editScores, setEditScores] = useState(false)
  const { user } = useAuthStore()

  const { isLoading: isRangeLoading, data: ratingsBands } = usePost<any[]>({
    url: "/admin/performance/getratingbands",
  })

  const {
    isLoading: isSummaryLoading,
    data: summaryData,
    refetch,
  } = useGet<IReviewEmployeeData>({
    url: `/performance/view/review?id=summary&x=${updatedEntityKey}`,
    enabled: !!updatedEntityKey,
  })
  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isGetRatings, data: ratingData } = usePost<any>({
    url: "/admin/performance/getratingconfiguration",
    body: reqBody,
  })
  const { isLoading: isFeedbackLoading, data: feedbackData } =
    useGet<IReviewEmployeeData>({
      url: `/performance/view/review?id=feedback&x=${updatedEntityKey}`,
      enabled: !!updatedEntityKey,
    })

  useEffect(() => {
    if (summaryData) {
      const questionsList =
        summaryData?.reviewGroup?.reviewEntity?.reviewQuestions
      const answerList = summaryData?.reviewGroup.reviewEntity?.reviewResponses
      const employeeReviews =
        summaryData?.reviewGroup?.reviewEntity?.employeeReviews

      if (questionsList?.length) {
        if (answerList?.length) {
          const feedbackReview = employeeReviews.find(
            (emplo) => emplo.employee_review_type === "Feedback"
          )

          let foundFeedback: any
          if (feedbackReview) {
            foundFeedback = answerList.filter(
              (ans) =>
                ans.employee_review_id === feedbackReview.employee_review_id
            )
          }
          const updatedList = questionsList.map((ques, index) => {
            let found = answerList.filter(
              (ans) => ans.review_question_id === ques.review_question_id
            )

            found = found.filter((ans) => {
              return !foundFeedback.some(
                (feedbackItem: any) =>
                  feedbackItem.employee_review_id === ans.employee_review_id
              )
            })

            if (found) {
              const returningArray =
                summaryData?.reviewGroup?.reviewEntity?.employeeReviews

              const updatedFound = found.map((item) => {
                const matchingEntry = returningArray.find(
                  (entry) =>
                    entry.employee_review_id === item.employee_review_id
                )

                if (matchingEntry) {
                  return {
                    ...item,
                    full_name: matchingEntry.full_name,
                    avatar_url: matchingEntry.avatar_url,
                  }
                }

                return item
              })

              return {
                response: updatedFound,
                ...ques,
              }
            } else {
              return {
                ...ques,
              }
            }
          })

          if (foundFeedback.length) {
            let updatedWithFeedback = updatedList.map((lis) => {
              let feedback = foundFeedback.find(
                (feedbackItem: any) =>
                  feedbackItem.review_question_id === lis.review_question_id
              )
              const allReviewees =
                summaryData?.reviewGroup?.reviewEntity?.employeeReviews

              //
              if (feedback) {
                const matchingEntry = allReviewees.find(
                  (entry) =>
                    entry?.employee_review_id === feedback?.employee_review_id
                )

                return {
                  ...lis,
                  feedback: {
                    ...feedback,
                    full_name: matchingEntry?.full_name,
                    avatar_url: matchingEntry?.avatar_url,
                  },
                }
                // return {
                //   ...lis,
                //   feedback: feedback,
                // }
              } else {
                return lis
              }
            })

            setUpdatedQuestions(updatedWithFeedback)
          } else {
            setUpdatedQuestions(updatedList)
          }
        } else {
          setUpdatedQuestions(questionsList)
        }
      }
    }
  }, [summaryData])

  const { isLoading: isEditingScores, mutate: updateScores } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/performance/edit_review_entity_summary?x=${updatedEntityKey}`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        setEditScores(false)
        {
          data.msg && toast.success(data.msg)
        }
        mixPanelEvent("pf-review-summary-scores-edit-success")
        refetch()
      }
    },
    onError: (data: any) => {
      setEditScores(false)

      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const [behaviouralScoreCompiled, setBehaviourScoreCompiled] = useState(0)
  const [finalScoreCompiled, setFinalScoreCompiled] = useState(
    summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore?.final_score || 0
  )

  const allScores = [
    {
      title: "Performance score",
      display_title: "Performance",
      value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.performance_score_actual || 0,
      other_value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.performance_score,
      rate: ratingData && ratingData[0]?.value,
    },
    {
      title: "Behavioural score",
      display_title: "Behavioural",
      value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.behavioural_score_actual || 0,
      other_value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.behavioural_score,
      rate: ratingData && ratingData[2]?.value + ratingData[1]?.value,
      hideScore: true,
    },
    {
      title: "Others score",
      display_title: "Others",

      value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.others_score_actual || 0,
      other_value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore?.others_score,
      rate: ratingData && ratingData[2]?.value,
    },
    {
      title: "Self score",
      display_title: "Self",

      value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.self_score_actual || 0,
      other_value:
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore?.self_score,
      rate: ratingData && ratingData[1]?.value,
    },
  ]

  const onFinish = (values: any) => {
    mixPanelEvent("pf-review-summary-scores-edit-init")
    updateScores({
      review_entity_score_id: String(
        summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
          ?.review_entity_score_id
      ),
      performance_score: String(values?.performance_score),
      self_score: String(values?.self_score),
      others_score: String(values?.others_score),
      behavioural_score: String(behaviouralScoreCompiled),
      final_score: String(finalScoreCompiled),
    })
  }

  const otherScoreValue = Form.useWatch("others_score", form)
  const selfScoreValue = Form.useWatch("self_score", form)
  const performanceScoreValue = Form.useWatch("performance_score", form)

  useEffect(() => {
    if (editScores && summaryData) {
      setBehaviourScoreCompiled(otherScoreValue + selfScoreValue)
      setFinalScoreCompiled(
        otherScoreValue + selfScoreValue + performanceScoreValue
      )
    }
  }, [
    editScores,
    summaryData,
    otherScoreValue,
    selfScoreValue,
    performanceScoreValue,
  ])

  const findPerformanceRating = (score: number): any | null => {
    const result = ratingsBands?.find(
      (band) => score >= band.lower && score <= band.upper
    )
    return result || null
  }
  const result = findPerformanceRating(
    Number(
      summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore?.final_score
    )
  )
  return (
    <div className="mt-8">
      {isSummaryLoading ? (
        <div className="h-[200px] flex justify-center items-center py-5">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className=" flex flex-col gap-4">
          {(summaryData?.reviewGroup?.review_id &&
            feedbackData?.reviewGroup?.review_id) ||
          Number(personId) ===
            Number(summaryData?.reviewGroup?.reviewEntity?.person_id) ? (
            <>
              {summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore && (
                <CustomCard>
                  {showEditSummary && (
                    <div
                      className="flex mb-4 justify-end items-center"
                      onClick={() => setEditScores(true)}
                    >
                      <PencilIcon className="w-5 text-neutral500" />
                    </div>
                  )}
                  <div className=" flex flex-col justify-center items-center mt-2 mb-4">
                    <h5 className="text-sm font-bold font-avenir mb-4">
                      FINAL SCORE
                    </h5>
                    <Progress
                      rootClassName="overflow-hidden font-avenir"
                      className=""
                      strokeWidth={8}
                      strokeColor={"#004AF5"}
                      size={210}
                      type="circle"
                      percent={
                        summaryData?.reviewGroup?.reviewEntity
                          ?.reviewEntityScore?.final_score as number
                      }
                      format={() => (
                        <div className="  text-[#15294B]  text-[40px] font-bold">
                          {
                            summaryData?.reviewGroup?.reviewEntity
                              ?.reviewEntityScore?.final_score
                          }
                          %
                        </div>
                      )}
                    />
                    {result && (
                      <h5 className=" mt-4 text-sm font-bold font-avenir  uppercase text-[#004AF5]">
                        {result?.description}{" "}
                      </h5>
                    )}
                  </div>

                  <div className=" grid sm:grid-cols-2 xl:grid-cols-4 mb-6 lg:mb-[30px] gap-4">
                    {allScores.map((scores, index) => (
                      <Tooltip
                        rootClassName="px-4 font-avenir w-[450px]"
                        title={
                          <div className="text-[13px]">
                            <h5>
                              {scores.display_title} set weight: {scores.rate}
                            </h5>
                            <h5>Actual score: {scores.value}</h5>
                            {!scores.hideScore && (
                              <h5>
                                {scores.display_title} score: ({scores.value}
                                /100) * {scores.rate}
                              </h5>
                            )}
                          </div>
                        }
                        // arrow={mergedArrow}
                      >
                        <div
                          key={index}
                          className=" bg-white flex items-center  p-4 border rounded-5px justify-between border-neutral50"
                        >
                          <div className=" flex flex-col gap-2   items-start h-full justify-center ">
                            <h5 className="text-neutral800 text-2xl  font-bold">
                              {scores.other_value}%
                            </h5>
                            <p className="text-neutral400  text-sm">
                              {scores.title}
                            </p>
                          </div>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </CustomCard>
              )}

              <div className="bg-white rounded-5px shadow-card">
                <div className="  ">
                  <h4 className="text-sm sm:text-base font-extrabold text-neutral700 my-4  px-4 sm:px-[22px]">
                    Review questions
                  </h4>
                  <div className="border border-neutral50 w-full mb-[12px]" />
                </div>
                {updatedQuestions?.length ? (
                  <div className="pb-[43px] ">
                    {updatedQuestions.map((ques, index) => (
                      <div
                        key={index}
                        className="px-4  sm:px-[22px]  pb-5 flex gap-4 flex-col "
                      >
                        <div className="border-2 border-cardgrey rounded-lg bg-white py-4 px-4  sm:px-[30px]">
                          <div
                            className="flex items-center justify-between w-full  cursor-pointer "
                            onClick={() => {
                              if (activeQuestion == ques.review_question_id) {
                                setActiveQuestion("")
                              } else {
                                setActiveQuestion(ques.review_question_id)
                              }
                            }}
                          >
                            {" "}
                            <div>
                              <h6 className=" text-sm sm:text-base text-neutral600  font-bold">
                                {index + 1}. {ques?.description}
                              </h6>
                              <p className="text-xs sm:text-sm text-bluesubtext  mt-1">
                                {ques?.alias}
                              </p>
                            </div>
                            <div className="">
                              <ChevronDownIcon className=" text-neutal500  w-5 h-5" />
                            </div>
                          </div>
                          {/* RESPONSE TYPES */}
                          {activeQuestion == ques.review_question_id && (
                            <>
                              {ques.review_question_id &&
                              ques?.feedback?.description ? (
                                <div
                                  key={index}
                                  className=" mt-2 border-2 rounded-5px py-2 px-4 gap-[10px] flex flex-col "
                                >
                                  <div className=" flex  items-center">
                                    <div className="flex items-center gap-1">
                                      <ImageComponent
                                        src={ques?.feedback?.avatar_url}
                                        alt="user"
                                        className="rounded-full w-[25px] h-[25px]"
                                      />
                                      <h6 className="text-[#15294B]  text-sm font-black">
                                        {ques?.feedback?.full_name}
                                      </h6>
                                    </div>
                                  </div>

                                  <p className="text-[#243757] text-sm">
                                    {ques?.feedback?.description}
                                  </p>
                                </div>
                              ) : (
                                <div className="mt-1 border-t text-neutral500 font-medium flex justify-center items-center w-full py-10 ">
                                  No available feedback from manager
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="h-40  flex justify-center items-center">
                    <h6 className="text-neutral-500">
                      No available review questions
                    </h6>
                  </div>
                )}
              </div>
              <div className="">
                <SummaryTemplate
                  title=" Professional development goals"
                  img=""
                  emptyText="No available professional development goals"
                  data={
                    summaryData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                      (feedback) => feedback.review_entity_feedback_type_id == 1
                    ) || []
                  }
                />
                <SummaryTemplate
                  title="Noteworthy accomplishments during this performance period"
                  img=""
                  emptyText="No available notable achievements"
                  data={
                    summaryData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                      (feedback) => feedback.review_entity_feedback_type_id == 2
                    ) || []
                  }
                />
                <SummaryTemplate
                  title=" Areas of improvement"
                  img=""
                  emptyText="No available areas of improvement"
                  data={
                    summaryData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                      (feedback) => feedback.review_entity_feedback_type_id == 3
                    ) || []
                  }
                />
              </div>
            </>
          ) : (
            <div className="bg-white rounded-5px shadow-card w-full">
              <div className="text-neutral500 font-medium flex justify-center items-center w-full py-10 ">
                You are not permitted to view summary for other employees{" "}
              </div>
            </div>
          )}
        </div>
      )}
      {editScores && (
        <Modal
          open={editScores}
          title={
            <div className="flex gap-2 items-center">
              <H5 className="font-medium text-neutral600">Edit Scores</H5>
            </div>
          }
          footer={false}
          onCancel={() => setEditScores(false)}
          // onOk={onUpdate}
          key={`modal-edit`}
        >
          <FormSectionWrapper
            subtitle={
              <p className="lg:ml-[-58px] lg:mr-[-60px]  ml-[-15px] mt-[-30px] text-sm text-neutral600">
                Manually update reviewee score
              </p>
            }
            isActive
            name="update-status"
            layout="vertical"
            initialValues={{
              performance_score:
                summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
                  ?.performance_score,
              // behavioural_score: behaviouralScoreCompiled,
              others_score:
                summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
                  ?.others_score,
              self_score:
                summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
                  ?.self_score,
              final_score:
                summaryData?.reviewGroup?.reviewEntity?.reviewEntityScore
                  ?.final_score,
            }}
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            footerBtns={
              <div className="w-[400px] justify-end flex gap-5 mt-4  mb-[-10px] ">
                {" "}
                <Button
                  color="neutral"
                  title="Confirm"
                  className="mt-3"
                  type="button"
                  onClick={() => setEditScores(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  title="Confirm"
                  className="mt-3"
                  type="submit"
                  // onClick={onUpdate}
                  key="update"
                  // submitting={isSubmitting}
                  // disabled={isDisabled}
                >
                  Save
                </Button>
              </div>
            }
          >
            {isEditingScores ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spin></Spin>
              </div>
            ) : (
              <div className="mb-8">
                <CustomInputNumber
                  required
                  name="performance_score"
                  // label="Performance score"
                  label={
                    <div className="flex items-center">
                      Performance score{""}
                      <Tooltip
                        title={`The set weight for performance score is ${
                          ratingData && ratingData[0]?.value
                        }`}
                      >
                        <InformationCircleIcon className=" ml-1 w-4 h-4" />
                      </Tooltip>
                    </div>
                  }
                  max={ratingData && ratingData[0]?.value}
                />
                <CustomInputNumber
                  required
                  name="self_score"
                  // label="Self score"
                  label={
                    <div className="flex items-center">
                      Self score{""}
                      <Tooltip
                        title={`The set weight for performance score is ${
                          ratingData && ratingData[1]?.value
                        }`}
                      >
                        <InformationCircleIcon className=" ml-1 w-4 h-4" />
                      </Tooltip>
                    </div>
                  }
                  max={ratingData && ratingData[1]?.value}
                />
                <CustomInputNumber
                  required
                  name="others_score"
                  // label="Others score"
                  label={
                    <div className="flex items-center">
                      Others score{""}
                      <Tooltip
                        title={`The set weight for performance score is ${
                          ratingData && ratingData[2]?.value
                        }`}
                      >
                        <InformationCircleIcon className=" ml-1 w-4 h-4" />
                      </Tooltip>
                    </div>
                  }
                  max={ratingData && ratingData[2]?.value}
                />

                <Form.Item
                  label={
                    <span className="font-circular text-neutral300 text-sm ">
                      Behavioural score
                    </span>
                  }
                >
                  <InputNumber
                    disabled
                    value={Number(behaviouralScoreCompiled)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-circular text-neutral300 text-sm ">
                      Final score{" "}
                    </span>
                  }
                >
                  <InputNumber
                    disabled
                    value={Number(finalScoreCompiled)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
            )}
          </FormSectionWrapper>
        </Modal>
      )}
    </div>
  )
}
export default Summary
