// tourUtils.ts
import TimeTracking from "pages/dashboard/documents/time-tracking/TimeTracking"
import { useCustomizationSettingsStore } from "stores/customization-settings"
export type TourStep = {
  title: string
  description: string
  placement?: string
  target: () => HTMLElement | null
}
export const getTourStepsForRoute = (route: string): TourStep[] => {
  const defaultStep: TourStep = {
    title: "We are currently updating our Knowledge-base ",
    description: "Please stay in touch!",
    target: () => null,
  }
  const routeToStepsMap: Record<string, TourStep[]> = {
    // DASHBOARD
    "/dashboard": [
      {
        title: "Dashboard",
        description:
          "Welcome to the dashboard! This is where you can view your stats.",
        placement: "right",
        target: () => document.getElementById("dashboard-nav"),
      },
      {
        title: "Total Employees Stats",
        description: "Shows total number of employees in your organization",
        target: () => document.getElementById("dashboard-total-employees"),
      },
      {
        title: "Total Salary Disbursed Stats",
        description: "Shows you total amount of salary disbursed for the month",
        target: () => document.getElementById("dashboard-total-salaries"),
      },

      {
        title: "Number of employees on leave",
        description: "Shows you the number of employees on leave",
        target: () => document.getElementById("dashboard-total-leaves"),
      },
      {
        title: "Payroll History chart",
        description:
          "Visualize your payroll trends, monitor salary growth, and gain valuable insights into your financial history at a glance.",
        target: () => document.getElementById("dashboard-payroll"),
      },
      {
        title: "Your profile",
        description: `Empower your presence on ${
          useCustomizationSettingsStore.getState().customizationSettings.brand
            .platformName
        }. Seamlessly manage and update your personal and company details in the Profile section.`,
        target: () => document.getElementById("dashboard-profile"),
      },
      {
        title: "Next payroll",
        description: "Get to see your next payroll date ",
        target: () => document.getElementById("dashboard-next-payroll"),
      },
      {
        title: "Tasks, Events, Needs attention",
        description:
          "This is your dashboard section, where you can your employee and payroll stats, company profile,events and tasks. ",
        target: () => document.getElementById("dashboard-actions"),
      },
      {
        title: "Employee attrition chart",
        description:
          "Visualize the Employee Attrition Chart to understand workforce dynamics. Track trends and foster a resilient team.",
        target: () => document.getElementById("dashboard-attrition"),
      },
      {
        title: "Head count",
        description:
          "This shows the total number of employees in the organization",
        target: () => document.getElementById("dashboard-headcount"),
      },
    ],
    // COMPANY DETAILS
    "/dashboard/company-details": [
      {
        title: "Company Details",
        description:
          "Access and manage essential details about your company. Update information such as company name, address, contact details, and other relevant information.",
        placement: "top",
        target: () => document.getElementById("cd-nav"),
      },
      {
        title: "Update Company Information",
        description:
          "Easily update  your company details including company image, primary contact, company name, email address, and other contact information.",
        target: () => document.getElementById("cd-update-info"),
      },
      {
        title: "Create New Linked Account",
        description:
          "This helps you create a new linked account by providing essential company details such as Company Name, Legal Name, Country, and specify whether other admins will have the ability to switch between companies.",
        target: () => document.getElementById("cd-create-linked-account"),
      },
      {
        title: "Subscription Date",
        description:
          "Here, you can view the date of your company's subscription to the service. To stay informed about the start date of your subscription plan.",
        target: () => document.getElementById("cd-subscription-date"),
        placement: "top",
      },
      {
        title: "Billing Frequency",
        description:
          "View your company's billing frequency, in order to stay informed about how often your company is billed for the services.",
        target: () => document.getElementById("cd-billing-frequency"),
        placement: "top",
      },
      {
        title: "Employee Count",
        description: "View the total number of employees in your company. ",
        target: () => document.getElementById("cd-employee-count"),
        placement: "top",
      },
      {
        title: "Payroll Count",
        description:
          "Shows the total number of payrolls processed for your company.",
        target: () => document.getElementById("cd-payroll-count"),
        placement: "top",
      },
      {
        title: "Subscription Details",
        description:
          "This shows essential information about your subscription plan. View details such as Subscription ID, Current Plan, Billing Mode, and Next Invoice.",
        target: () => document.getElementById("cd-subscription-details"),
        placement: "top",
      },
      {
        title: "Workplaces",
        description:
          "Access information about your company's workplaces, including filing and mailing addresses. Keep track of essential workplace details for efficient management.",
        target: () => document.getElementById("cd-workplaces-details"),
        placement: "top",
      },
      {
        title: "Manage Workplaces",
        description:
          "This allows you update filing and mailing addresses, add new workplaces, or modify existing ones with ease.",
        target: () => document.getElementById("cd-manage-workplaces"),
        placement: "top",
      },
      {
        title: "Pay Schedule",
        description:
          "View the pay frequency set for your company. Stay informed about how often employees are paid.",
        target: () => document.getElementById("cd-pay-schedule"),
        placement: "top",
      },
      {
        title: "Preview Upcoming Paydays",
        description:
          "This shows the upcoming paydays for your company. Stay ahead by knowing when employees will be paid.",
        target: () => document.getElementById("cd-upcoming-paydays"),
        placement: "top",
      },
      {
        title: "Tax Setup",
        description:
          "Access and manage tax-related information for your company.This includes essential details such as Tax Identification Number, Company Type, and Filing Schedule.",
        target: () => document.getElementById("cd-tax-setup"),
        placement: "top",
      },
      {
        title: "Edit Tax Setup",
        description:
          "Update tax-related information for your company. Modify details such as Tax Identification Number, Company Type, and Filing Schedule as needed.",
        target: () => document.getElementById("cd-edit-tax-setup"),
        placement: "top",
      },
      {
        title: "Pension Setup",
        description:
          "Access and manage pension-related information for your company. Set up details such as Reference Number to ensure compliance with pension regulations.",
        target: () => document.getElementById("cd-pension-setup"),
      },
      {
        title: "Edit Pension Setup",
        description:
          "Update pension-related information for your company. Update details such as Reference Number to ensure accurate pension management.",
        target: () => document.getElementById("cd-edit-pension-setup"),
      },
      {
        title: "NHF Setup",
        description:
          "Access and manage National Housing Fund (NHF) related information for your company. Set up details such as Reference Number to comply with NHF regulations.",
        target: () => document.getElementById("cd-nhf-setup"),
      },
      {
        title: "Edit NHF Setup",
        description:
          "Update National Housing Fund (NHF) related information for your company. Update details such as Reference Number to ensure compliance with NHF regulations.",
        target: () => document.getElementById("cd-edit-nhf-setup"),
      },
      {
        title: "Company Signatories",
        description:
          "Manage company signatories responsible for electronically signing all documents created on PaidHR. Signatories are notified to electronically sign new forms online.",
        target: () => document.getElementById("cd-company-signatories"),
      },
      {
        title: "Add New Signatory",
        description:
          "Add a new signatory responsible for electronically signing documents on behalf of the company.",
        target: () => document.getElementById("cd-new-signatory"),
      },
    ],
    // PAYROLL
    "/dashboard/payroll": [
      {
        title: "Payroll Overview",
        description: " Explore and manage payroll",
        placement: "top",
        target: () => document.getElementById("payroll-overview-nav"),
      },
      {
        title: "Payroll period",
        description:
          "Set and manage the overall performance period to effectively monitor and evaluate task performance.",
        placement: "top",
        target: () => document.getElementById("payroll-period"),
      },
      {
        title: "Total Payment",
        description:
          " This displays the total amount disbursed to employees on your last payroll cycle",
        target: () => document.getElementById("payroll-total-payment"),
      },
      {
        title: "Total Payroll",
        description:
          " This shows the total payroll amount, including company contributions such as Pension.",
        target: () => document.getElementById("payroll-total-payroll"),
      },
      {
        title: "Next Payroll",
        description:
          "This indicates the date of your next payroll run. To change your payroll run date, click here (insert link to payroll configuration page)",
        target: () => document.getElementById("payroll-next-payroll"),
      },
      {
        title: "Recent payroll history",
        description:
          "Visualize your payroll trends, monitor salary growth, and gain valuable insights into your payroll history at a glance.",
        target: () => document.getElementById("payroll-recent-payroll"),
        placement: "top",
      },
      {
        title: "Submit Payroll",
        description: "Payrolls waiting to be submitted will appear here.",
        target: () => document.getElementById("payroll-submit-payroll"),
        placement: "top",
      },
      {
        title: "Submit",
        description: " Click here to submit the selected payroll for approval.",
        target: () => document.getElementById("payroll-submit-button"),
      },
      {
        title: "Auto Payroll",
        description:
          "Toggle this button on if you want payrolls to be automatically created for you on your selected run date; otherwise, toggle it off.",
        target: () => document.getElementById("payroll-auto-payroll"),
        placement: "top",
      },
      {
        title: "Payroll Changes",
        description:
          "Manage any changes to employee payroll here, such as bonuses, payables, expense claims, and deductions.",
        target: () => document.getElementById("payroll-changes"),
        placement: "top",
      },
      {
        title: "Payroll Records",
        description:
          "Manage and approve submitted payrolls here. Historical payroll data are also stored in this module.",
        target: () => document.getElementById("payroll-records"),
        placement: "top",
      },
      {
        title: "Skip Employees",
        description:
          "This is where you exclude employees who will not be included in the month's payroll.",
        target: () => document.getElementById("payroll-skip-employees"),
        placement: "top",
      },
    ],
    "/dashboard/payroll/payrollchange": [
      {
        title: "Payroll changes",
        description: "Explore and manage payroll changes ",
        placement: "top",
        target: () => document.getElementById("payroll-changes-nav"),
      },
      {
        title: "Search Bar",
        description: "Search the payroll change history with employee names ",
        target: () => document.getElementById("payroll-change-search"),
      },
      {
        title: "Payroll Change Filter",
        description:
          " Use this dropdown to view pending payroll changes waiting to be approved,approved and declined payroll changes",
        target: () => document.getElementById("payroll-change-filter"),
      },

      {
        title: "Bulk Payroll Change",
        description:
          "Create multiple payroll changes at once using a spreadsheet upload.",
        target: () => document.getElementById("payroll-change-bulk"),
      },
      {
        title: "Create Payroll Change",
        description:
          " Record a payroll change for an employee. Specify the amount (percentage of gross earnings or fixed amount), type of change (deduction, bonuses, expense claims, payables), and frequency (one-off or recurring). If recurring, select effective date and recurring period in months. Specify if the change is taxable or not. If taxable, choose if it is a combined taxable change i.e. grouped together with other allowances for tax computation purposes.Recorded changes require approval before they can be processed in payroll.",
        target: () => document.getElementById("payroll-change-create"),
      },
      {
        title: "Payroll Change Batch Review",
        description: "Review multiple pending payroll changes at once.",
        target: () => document.getElementById("payroll-change-batch-review"),
      },
    ],
    "/dashboard/payroll/payrollrecord": [
      {
        title: "Payroll Records",
        description:
          " Access historical and present payroll data. Review, approve, cancel, or decline payroll entries. Approve payroll to establish a payschedule. ",
        placement: "top",
        target: () => document.getElementById("payroll-records-nav"),
      },
      {
        title: "Search Bar",
        description:
          "Search for specific payroll records by typing the name of the payroll. ",
        target: () => document.getElementById("payroll-record-search"),
      },
      {
        title: "Payroll Record Filter",
        description:
          "Use this dropdown to filter payroll records by various statuses: To Review, Not Submitted, Cancelled, Declined, Approved, and Processed",
        target: () => document.getElementById("payroll-record-filter"),
      },
    ],
    "/dashboard/payroll/payrollskip": [
      {
        title: "Skip Employees",
        description:
          "Manage employees to be skipped from payroll either on a short or long-term basis. ",
        placement: "top",
        target: () => document.getElementById("skip-employees-nav"),
      },
      {
        title: "Bulk Upload",
        description:
          "Skip multiple employees at a time by downloading a spreadsheet, updating it, and uploading the modified sheet.",
        target: () => document.getElementById("skip-employees-bulk"),
      },
      {
        title: "Select Employee",
        description: " Choose employee(s) that will be skipped.",
        target: () => document.getElementById("skip-employees-select"),
      },
      {
        title: " Exited Employees Check Box",
        description: `Check this box if you want to include exited employees in the selection in the "Select Employee" tab.`,
        target: () => document.getElementById("skip-employees-check"),
      },
    ],
    "/dashboard/payroll/configuration/pay-schedules": [
      {
        title: "Configuration",
        description:
          " Explore and manage all payroll settings, creating and managing paygrades, allowances, and pay schedules.",
        placement: "top",
        target: () => document.getElementById("payroll-config-nav"),
      },
      {
        title: "Payschedules - Regular",
        description:
          "Configure payroll schedules (weekly, biweekly, or monthly). View, edit, or disable regular payroll schedules. Click on the dotted lines next to each schedule for editing.",
        target: () =>
          document.getElementById("payroll-config-regular-schedule"),
      },
      {
        title: "Payschedules - Off-cycle",
        description:
          "View, create, edit, or disable pay schedules for off-cycle payroll",
        target: () =>
          document.getElementById("payroll-config-offcycle-schedule"),
      },
      {
        title: "Search Bar",
        description: "Search for specific  payschedules by typing the name.",
        target: () => document.getElementById("payroll-config-schedule-search"),
      },
      {
        title: "Create New Schedule",
        description:
          "Create a new payroll schedule for off-cycle payrolls. Add allowances specific to off-cycle payrolls, which can run independently or concurrently with your regular payroll.",
        target: () => document.getElementById("payroll-config-schedule-create"),
      },
    ],
    "/dashboard/payroll/configuration/pay-schedules/edit": [
      {
        title: "Cut-off Day",
        description:
          "Cut-off day is the last day of a payroll cycle i.e your pay period. For example, choose “Last Day” if your payroll cycle is from the first day to last day of the month.",
        target: () =>
          document.getElementById("payroll-config-schedule-edit-cut-off"),
      },
      {
        title: "Run Day",
        description:
          " Run day is used to determine the earliest date a payroll can be submitted. This is particularly important if Auto Payroll is enabled. Payroll will visible on your payroll dashboard from this day.",
        target: () =>
          document.getElementById("payroll-config-schedule-edit-run-day"),
      },
      {
        title: "Pay Day ",
        description: "Set the payday displayed on employee payslips.",
        target: () =>
          document.getElementById("payroll-config-schedule-edit-pay-day"),
      },
    ],
    "/dashboard/payroll/configuration/paygrades": [
      {
        title: "Configuration",
        description:
          " Explore and manage all payroll settings, creating and managing paygrades, allowances, and pay schedules.",
        placement: "top",
        target: () => document.getElementById("payroll-config-nav"),
      },
      {
        title: "Paygrades Settings",
        description:
          "Manage paygrade configurations: View, edit, and copy existing Paygrades. View a list of employees assigned to each paygrade. Disable or remove outdated configurations as needed.",
        target: () => document.getElementById("payroll-config-paygrade"),
      },
      {
        title: "Search Bar",
        description:
          "You can search for specific  paygrades by typing the name of the paygrade.",
        target: () => document.getElementById("payroll-config-paygrade-search"),
      },
      {
        title: " Create New Paygrade",
        description:
          "Build your paygrades from scratch by setting up the annual gross, defining allowances and statutory deductions for this paygrade, choosing whether the payroll should be taxable or non-taxable, and configuring tax settings if applicable.",
        target: () => document.getElementById("payroll-config-paygrade-create"),
      },
    ],
    "/dashboard/payroll/configuration/allowances": [
      {
        title: "Configuration",
        description:
          " Explore and manage all payroll settings, creating and managing paygrades, allowances, and pay schedules.",
        placement: "top",
        target: () => document.getElementById("payroll-config-nav"),
      },
      {
        title: "Allowances",
        description:
          "Manage custom allowances, which is tailored to meet specific employee needs. Each allowance can be taxable, special, or routine allowances based on unique circumstances. ",
        target: () => document.getElementById("payroll-config-allowances"),
      },
      {
        title: "Search Bar",
        description:
          "You can search for specific  allowances by typing the name of the allowance.",
        target: () =>
          document.getElementById("payroll-config-allowance-search"),
      },
      {
        title: " Create New Allowance",
        description:
          " Generate a new allowance, specifying its inclusion in regular payroll or off-cycle payroll and its taxability.",
        target: () =>
          document.getElementById("payroll-config-allowance-create"),
      },
    ],
    "/dashboard/payroll/configuration/statutory-benefits": [
      {
        title: "Configuration",
        description:
          " Explore and manage all payroll settings, creating and managing paygrades, allowances, and pay schedules.",
        placement: "top",
        target: () => document.getElementById("payroll-config-nav"),
      },
      {
        title: "Statutory Benefits",
        description:
          "Statutory benefits are government-mandated financial protections, like social security, minimum wage, and workers' compensation, ensuring basic rights and support for individuals in areas such as health, income, and employment.",
        target: () => document.getElementById("payroll-config-benefits"),
      },
      {
        title: "Search Bar",
        description:
          "You can search for specific  statutory benefit by typing its name.",
        target: () => document.getElementById("payroll-config-benefits-search"),
      },
      {
        title: " Create New Statutory Benefit",
        description:
          "Set up a new statutory benefit to provide government-mandated financial protections to employees. Ensure compliance with regulations by defining benefits such as social security, minimum wage, and workers' compensation.",
        target: () => document.getElementById("payroll-config-benefits-create"),
      },
    ],
    "/dashboard/payroll/configuration/tax-tables": [
      {
        title: "Configuration",
        description:
          " Explore and manage all payroll settings, creating and managing paygrades, allowances, and pay schedules.",
        placement: "top",
        target: () => document.getElementById("payroll-config-nav"),
      },
      {
        title: "Tax Table",
        description:
          "Explore the categories for tax deductions and their corresponding tax rates. Understand how each category affects Personal Income Tax (PIT) calculation based on various income levels.",
        target: () => document.getElementById("payroll-config-tax"),
      },
      {
        title: "Search Bar",
        description:
          "You can search for specific tax deduction by typing its name.",
        target: () => document.getElementById("payroll-config-tax-search"),
      },
    ],
    //REQUESTS
    "/dashboard/requests/list/loans": [
      {
        title: "Loans",
        description:
          "  Implement and oversee policies governing loan requests within your organization, including accessing approvals and maintaining records of loan requests.",
        placement: "top",
        target: () => document.getElementById("req-loan-nav"),
      },
    ],
    //WALLET
    "/dashboard/wallets/overview": [
      {
        title: "Wallet",
        description:
          "Explore your virtual wallet, where you can easily fund your account, withdraw funds, and manage transactions securely.",
        placement: "top",
        target: () => document.getElementById("wallet-nav"),
      },
      {
        title: "Wallet Balances",
        description:
          "View your current wallet balance. Stay updated on your available funds for seamless financial management.",
        target: () => document.getElementById("wallet-balance"),
      },
      {
        title: "View All Transactions",
        description:
          "Click here to access a comprehensive list of all your wallet transactions. Track your spending, deposits, and withdrawals with ease.",
        target: () => document.getElementById("wallet-transactions-btn"),
      },
      {
        title: "Withdraw Funds",
        description:
          "Easily withdraw funds from your wallet with just a few clicks. Transfer money to your linked bank account or other payment methods securely.",
        target: () => document.getElementById("wallet-withdrawal-btn"),
      },
      {
        title: "Fund Your Account",
        description:
          "Transfer funds to your virtual account to increase your wallet balance. Choose from the list of available virtual accounts and deposit the desired amount to fund your account.",
        target: () => document.getElementById("wallet-fund-account"),
      },
      {
        title: "Transaction Overview",
        description:
          "Track your transaction over time with a line chart. Choose from options like one week, one month, or select a custom date range to gain insights into your financial behavior",
        target: () => document.getElementById("wallet-transaction-chart"),
      },
    ],

    "/dashboard/wallets/transactions": [
      {
        title: "Transactions",
        description:
          "Easily navigate through your transactions to manage your financial activities effectively. This includes payouts, withdrawals, inflows, invoice payments, and statements to track and monitor your transactions.",
        placement: "top",
        target: () => document.getElementById("transaction-nav"),
      },
      {
        title: "Payouts",
        description:
          "View and manage payouts to recipients. Bank charges are displayed only for successful transfers, ensuring transparent transaction processing.",
        target: () => document.getElementById("transaction-payouts"),
      },
      {
        title: "Withdrawals",
        description:
          "Track withdrawals from your account. Bank charges are displayed only for successful withdrawals.",
        target: () => document.getElementById("transaction-withdrawals"),
      },
      {
        title: "Inflows",
        description:
          "Track incoming deposits to your account. Deposits will be reflected here at the end of the day, updating your balance instantly.",
        target: () => document.getElementById("transaction-inflows"),
      },
      {
        title: "Invoice Payments",
        description:
          "View and manage invoice payments. Invoices will be listed here at the end of the day for convenient tracking and processing.",
        target: () => document.getElementById("transaction-invoice"),
      },
      {
        title: "Statement",
        description:
          "Access your transaction history. Choose from options like today, yesterday, or select a custom date range. Download your statement for comprehensive record-keeping and analysis.",
        target: () => document.getElementById("transaction-statement"),
      },
    ],
    //EARNED WAGE ACCESS
    "/dashboard/ewa/analytics": [
      {
        title: "Analytics Overview",
        description:
          "Get insights into your Earned Wage Access activity. Track the amount withdrawn, total employees, registered employees, transaction volume, withdrawal frequency, and more for a comprehensive overview of EWA usage.",
        placement: "top",
        target: () => document.getElementById("ewa-analytics-nav"),
      },
      {
        title: "Total Employees",
        description:
          "View the total number of employees, including both registered and unregistered users, to understand the scope of your workforce.",
        target: () => document.getElementById("ewa-analytics-total-employees"),
      },
      {
        title: "Registered Employees",
        description:
          "Track the number of employees who have registered for Earned Wage Access, providing insight into user adoption and engagement.",
        target: () =>
          document.getElementById("ewa-analytics-registered-employees"),
      },
      {
        title: "Withdrawals This Month",
        description:
          "Monitor the total amount of withdrawals made by employees during the current month. Gain insights into usage patterns and frequency of Earned Wage Access withdrawals.",
        target: () => document.getElementById("ewa-analytics-withdrawals"),
      },
      {
        title: "Total Withdrawal Amount",
        description:
          "Track the total amount withdrawn by employees till date. Gain insights into the overall financial impact of EWA usage.",
        target: () =>
          document.getElementById("ewa-analytics-total-withdrawals"),
      },
      {
        title: "Withdrawals",
        description:
          "Visualize withdrawals over time with a line chart. Filter data by 7 days, 12 months, or 30 days to analyze withdrawal patterns more effectively.",
        target: () =>
          document.getElementById("ewa-analytics-withdrawals-chart"),
      },
      {
        title: "Recent Transactions",
        description:
          "View the latest five transactions, including the date, employee name, amount, and status. Stay updated on the most recent Earned Wage Access activities at a glance.",
        target: () =>
          document.getElementById("ewa-analytics-recent-transactions"),
      },
    ],
    "/dashboard/ewa/employees": [
      {
        title: "Employees",
        description:
          "View the total number of employees, including both registered and unregistered users, to understand the scope of your workforce.",
        target: () => document.getElementById("ewa-employees-nav"),
        placement: "top",
      },
      {
        title: "Registered Employees",
        description:
          "Track the number of employees who have registered for Earned Wage Access, providing insight into user adoption and engagement. You can filter registered employees by clicking on this card.",
        target: () => document.getElementById("ewa-employees-registered"),
      },
      {
        title: "Unregistered Employees",
        description:
          "Track the number of employees who are yet to be registered for Earned Wage Access. You can filter unregistered employees by clicking on this card.",
        target: () => document.getElementById("ewa-employees-unregistered"),
      },
      {
        title: "Employees",
        description:
          "View a comprehensive list of employees, including their name, email, account number, registration status, and current status. Easily track and manage employee information for Earned Wage Access.",
        target: () => document.getElementById("ewa-employees-list"),
      },
    ],
    "/dashboard/ewa/withdrawals": [
      {
        title: "Withdrawals ",
        description:
          "Monitor the  withdrawals made by employees. Gain insights into usage patterns and frequency of Earned Wage Access withdrawals.",
        target: () => document.getElementById("ewa-withdrawals-nav"),
        placement: "top",
      },
      {
        title: "Withdrawal History",
        description:
          "View employee withdrawals, including the date, employee name, amount, and status. Stay updated on the most recent Earned Wage Access activities at a glance.",
        target: () => document.getElementById("ewa-withdrawals-list"),
      },
    ],
    "/dashboard/ewa/invoice": [
      {
        title: "Payments",
        description:
          "Access and manage invoices related to Earned Wage Access transactions. Stay organized and keep track of financial documentation for EWA activities.",
        target: () => document.getElementById("ewa-invoice-nav"),
        placement: "top",
      },
      {
        title: "Invoice Listings",
        description:
          "View a list of invoices, including date, amount, and status. Keep track of all invoices related to Earned Wage Access transactions for easy reference and management.",
        target: () => document.getElementById("ewa-invoice-listing"),
      },
    ],
    // PERFORMANCE
    "/dashboard/performance/performance-period": [
      {
        title: "Performance Period",
        description:
          "Set and manage the overall performance period to effectively monitor and evaluate task performance.",
        placement: "top",
        target: () => document.getElementById("pf-period-nav"),
      },
      {
        title: "Total Periods",
        description:
          "Show you a list of all created performance periods and the company objectives associated with each period.",
        target: () => document.getElementById("pf-total-period"),
      },
      {
        title: "Company Objectives",
        description:
          "Shows the total count of company objectives for the current performance period.",
        target: () => document.getElementById("pf-company-objectives"),
      },

      {
        title: "Company’s Objectives On-track",
        description:
          "Show the total count of company-wide objectives on track for the current performance period.",
        target: () => document.getElementById("pf-company-objectives-ontrack"),
      },

      {
        title: "Performance Period By Category",
        description:
          "Shows the set performance weight across for the current performance period to gain insights into the areas contributing the most to overall performance.",
        target: () => document.getElementById("pf-period-by-category"),
      },

      {
        title: "Add New Performance Period",
        description:
          "This allows you add a new performance period. Enter the period name, select the time frame, and choose the performance methodology (Balanced Score Card or Objectives and Key Results). If Balanced Score Card is selected, set the performance weight for the period.",
        target: () => document.getElementById("pf-period-new"),
      },
      {
        title: "Performance Period",
        description:
          "Shows you a list of all created performance periods and the company objectives associated with each period.",
        target: () => document.getElementById("pf-period-listing"),
      },
    ],
    "/dashboard/performance/performance-objectives": [
      {
        title: "Performance Objectives",
        description:
          "Explore and manage various performance objectives created based on the company objectives",
        target: () => document.getElementById("pf-objectives-nav"),
      },
      {
        title: "Performance Period",
        description:
          "Use this dropdown to select and change the performance period. The objectives for the selected period will be automatically populated.",
        target: () => document.getElementById("pf-period-dropdown"),
      },
      {
        title: "Objective Class",
        description:
          "Use this dropdown to filter and view objectives based on different classes to streamline your focus .",
        target: () => document.getElementById("pf-objective-class"),
      },
      {
        title: "Create Objective",
        description:
          "Click this button to create a new objective. Input the objective description, select the company period, choose the company objective, select the class type, and assign the owner of the objective.",
        target: () => document.getElementById("pf-objective-new"),
      },
      {
        title: "Total Objectives",
        description:
          "Shows the total count of objectives for the selected performance period.",
        target: () => document.getElementById("pf-total-objectives"),
      },
      {
        title: "Key Results",
        description:
          "Shows the count of key results under the current active objective.",
        target: () => document.getElementById("pf-key-results"),
      },
      {
        title: "Key Results On-track",
        description:
          "Shows the count of key results on track under the current active objective.",
        target: () => document.getElementById("pf-key-results-ontrack"),
      },
      {
        title: "Objectives by Status",
        description:
          "This chart shows the count of objectives categorized by their status (Not Measured, Off Track, On Track, Achieved).",
        target: () => document.getElementById("pf-objectives-by-status"),
      },
      {
        title: "Objectives",
        description:
          "Shows you a list of all created performance objectives and the key results associated with each company objective.",
        target: () => document.getElementById("pf-objectives-listing"),
      },
      {
        title: "Others Initiatives",
        description: "Shows you a list of the Initiatives of a selected user.",
        target: () => document.getElementById("pf-others-initiatives-listing"),
      },
      {
        title: "Filter Objectives",
        description:
          "This allows you to filter the list of the created objectives by its status.",
        target: () => document.getElementById("pf-filter-obj"),
      },
      {
        title: "Key Results",
        description:
          "Shows you a list of the measurable key results that indicate progress towards achieving an objective.",
        target: () => document.getElementById("pf-key-result"),
      },
      {
        title: "Add Key result",
        description:
          "Click this button to create a new key result. Input the key result description, select the metric, and date, and assign the owner(s) of the Key Result.",
        target: () => document.getElementById("pf-key-result-new"),
      },
    ],
    "/dashboard/performance/groups": [
      {
        title: "Performance Groups",
        description:
          "Explore and manage various performance groups created based on the objectives",
        target: () => document.getElementById("pf-group-nav"),
      },
      {
        title: "Performance Groups",
        description:
          "Shows you a list of all created performance groups and the group members associated with each group.",
        target: () => document.getElementById("pf-group-listing"),
      },
      {
        title: "Create Group",
        description:
          "Click this button to create a new group. Input the group description, and assign the members of the group.",
        target: () => document.getElementById("pf-group-new"),
      },
    ],
    "/dashboard/performance/ratings": [
      {
        title: "Performance Ratings",
        description:
          "Explore and manage weightings to reflect the organization's priorities.",
        target: () => document.getElementById("pf-ratings-nav"),
      },
      {
        title: "Performance Ratings",
        description:
          "This allows you assign the weight for employees skills, productivity, and ability to achieve goals and targets.",

        target: () => document.getElementById("pf-rating-performance"),
      },
      {
        title: "Behavoral Ratings",
        description:
          "This allows you assign the weight to assess the employee's interpersonal skills, work ethics, collaboration, adaptability, and professionalism.",

        target: () => document.getElementById("pf-rating-behavoral"),
      },
    ],
    "/dashboard/performance/reviews": [
      {
        title: "Performance Reviews",
        description:
          "Explore performance reviews to assess employees progress, strengths,feedback and areas for improvement.",
        target: () => document.getElementById("pf-reviews-nav"),
      },
      {
        title: "Review Listing",
        description:
          "View a list of performance reviews conducted for employees. Access goals and feedback provided during each review.",
        target: () => document.getElementById("pf-reviews-listing"),
      },
      {
        title: "Core Values",
        description:
          "Explore the organization's core values that guide behavior and decision-making.",
        target: () => document.getElementById("pf-core-values"),
      },
      {
        title: "Review Templates",
        description:
          "Access predefined review templates to hasten the performance review process. Choose from a variety of templates tailored to different roles or performance evaluation criteria.",
        target: () => document.getElementById("pf-review-templates"),
      },
      {
        title: "Performance Range",
        description:
          "Define the performance range to establish clear criteria for evaluating employee performance.",
        target: () => document.getElementById("pf-range"),
      },
    ],
    "/dashboard/performance/reviews/round/view": [
      {
        title: "Launch Review",
        description:
          "Click this button to launch the performance review process to evaluate employee performance and provide feedback.",
        target: () => document.getElementById("pf-review-launch"),
      },
      {
        title: "Review Actions",
        description:
          "Shows various actions that can be carried out on this review including refreshing data, generating reports, generating a summary of scores, and updating information.",
        target: () => document.getElementById("pf-actions"),
      },
      {
        title: "Reviewees",
        description:
          "View a list of reviewees grouped by departments. Take actions such as removing, updating, and adding reviewees to effectively manage the review process.",
        target: () => document.getElementById("pf-reviewees-listing"),
      },
      {
        title: "Reviewers",
        description:
          "View a list of reviewers grouped by departments. Take actions such as removing, updating, and adding reviewees to effectively manage the review process.",
        target: () => document.getElementById("pf-reviewers-listing"),
      },
    ],
    "/dashboard/performance/reviews/round/view-employee": [
      {
        title: "Reviewees",
        description:
          "View a list of reviewees grouped by departments. Take actions such as removing, updating, and adding reviewees to effectively manage the review process.",
        target: () => document.getElementById("pf-review-reviewees"),
      },
      {
        title: "Add Reviewees",
        description:
          "Click this button to add new reviewees to the performance review process. Ensure all relevant employees are included for comprehensive evaluations.",
        target: () => document.getElementById("pf-add-reviewees"),
      },
      {
        title: "Employee Ratings",
        description:
          "This shows where you input ratings for each employee based on performance criteria.",
        target: () => document.getElementById("pf-review-empl-rating"),
      },
      {
        title: "Professional Development Goals",
        description:
          "This allows you set and track professional development goals for employees to encourage continuous growth and improvement.",
        target: () => document.getElementById("pf-review-development-goals"),
      },
      {
        title: "Notable Achievements",
        description:
          "Highlight and celebrate notable achievements of employees. Recognize outstanding performance and contributions to the organization.",
        target: () => document.getElementById("pf-review-achievements"),
      },
      {
        title: "Areas of Improvement",
        description:
          "Identify and address areas of improvement for employees. Offer support and resources to help individuals grow and overcome challenges.",
        target: () => document.getElementById("pf-review-improvement"),
      },
      {
        title: "Feedback",
        description:
          "Provide constructive feedback to employees to help them understand their strengths and areas for improvement. Offer specific, actionable suggestions for growth.",
        target: () => document.getElementById("pf-review-feedback"),
      },
      {
        title: "Summary",
        description:
          "Review a summary of the performance evaluation, including ratings, feedback, notable achievements, areas of improvement, and professional development goals.",
        target: () => document.getElementById("pf-review-summary"),
      },
    ],

    // PAYMENT SCHEDULES
    "/dashboard/payment-schedules/regular": [
      {
        title: "Regular Payment Schedule",
        description:
          "Explore your regular payment schedules established for consistent and timely payments.",
        target: () => document.getElementById("regular-payment-nav"),
      },
      {
        title: "Regular Payment Schedule",
        description:
          "View a list of available regular payment schedules based on the created date.",
        target: () => document.getElementById("regular-payment"),
      },
    ],
    "/dashboard/payment-schedules/custom": [
      {
        title: "Custom Payment Schedule",
        description:
          "Explore your custom payment schedules established to accommodate unique circumstances or agreements.",
        target: () => document.getElementById("custom-payment-nav"),
      },
      {
        title: "Custom Payment Schedule",
        description:
          "View a list of available custom payment schedules based on the created date and status.",
        target: () => document.getElementById("custom-payment"),
      },
      {
        title: "Create Schedule",
        description:
          "Click this button to create a custom payment schedule for one or multiple employees. Tailor payment intervals and amounts to suit employee(s).",
        target: () => document.getElementById("create-custom-payment"),
      },
      {
        title: "Custom Payment Bulk Upload ",
        description:
          "Simplify creating custom payment schedules by uploading a spreadsheet containing employee details and corresponding payment amounts.",
        target: () => document.getElementById("create-custom-payment-bulk"),
      },
    ],
    "/dashboard/payment-schedules/custom/new": [
      {
        title: "Select Transfer Type",
        description:
          "Select the type of transfer for the payment, such as single transfer or multiple transfer.",
        target: () => document.getElementById("custom-payment-transfer-type"),
      },
      {
        title: "Enter Payment Description",
        description:
          "Enter a description for the payment to provide additional context or details.",
        target: () => document.getElementById("custom-payment-desc"),
      },
      {
        title: "Select Employee and Amount",
        description:
          "Specify the payment amount for the custom schedule as well as the employee's name",
        target: () => document.getElementById("custom-payment-amount"),
      },
    ],
    "/dashboard/payment-schedules/view-regular-payment": [
      {
        title: "Payouts",
        description:
          "View detailed information about employees payouts. You can carry out actions such as process, reverse, reset, refresh, retry.",
        target: () => document.getElementById("regular-payment-payouts"),
      },
      {
        title: "Tax Summary",
        description:
          "Access a summary of tax deductions and contributions for each payment period. Here you can prepare a schedule for each payroll tax.",
        target: () => document.getElementById("regular-payment-tax"),
      },
      {
        title: "Pension",
        description:
          "Review employees payment to pension plans.Here you can prepare a schedule for each payroll Pension.",
        target: () => document.getElementById("regular-payment-pension"),
      },
      {
        title: "NHF (National Housing Fund)",
        description:
          "View NHF employees payments for housing fund deductions. Here you can prepare a schedule for each payroll Pension.",
        target: () => document.getElementById("regular-payment-nhf"),
      },
      {
        title: "Earned Wage Access",
        description:
          "Monitor earned wage access details, including advances, repayments, and remaining balances.",
        target: () => document.getElementById("regular-payment-ewa"),
      },
    ],
    "/dashboard/payment-schedules/view-payment": [
      {
        title: "Schedule Amount ",
        description:
          "View the total scheduled amount for the current payroll period.",
        target: () => document.getElementById("payment-schedule-amount"),
      },
      {
        title: "Amount Processed",
        description:
          "Review the total amount processed in the current payroll cycle.",
        target: () => document.getElementById("payment-amount-processed"),
      },
      {
        title: "Amount to Process",
        description:
          "View the remaining balance after processing the scheduled amount in the payroll.",
        target: () => document.getElementById("payment-amount-to-process"),
      },
      {
        title: "Bank Account",
        description:
          "View the amount processed to bank accounts for the current payroll.",
        target: () => document.getElementById("payment-bank-account"),
      },
      {
        title: "Custom Wallet",
        description:
          "View the amount processed to custom wallets for the current payroll.",
        target: () => document.getElementById("payment-custom-wallet"),
      },
      {
        title: "Process Payments",
        description:
          "Click this button to initiate the payment process. Choose between processing payments via cheque or direct deposit.",
        target: () => document.getElementById("payment-process"),
      },
    ],
    // DOCUMENT
    "/dashboard/documents": [
      {
        title: "Documents",
        description:
          "Access and manage your important employee and company documents.",
        target: () => document.getElementById("document-nav"),
        placement: "right",
      },

      {
        title: "Employee Documents",
        description:
          "Manage offer templates and employee documents. Send legally binding offer templates to new hires with terms of employment, compensation, and more. Provide a list of documents for employees to fill out and sign online.",
        target: () => document.getElementById("document-employee"),
      },
      {
        title: "Company Documents",
        description:
          "View all company templates and correspondences between your company and PaidHR, along with government documents filed on behalf of your company.",
        target: () => document.getElementById("document-company"),
      },
      {
        title: "Create Document",
        description:
          "Create a new document template for offers or employee documents. Customize templates for offer letters or add a document for new employees or existing employees.",
        target: () => document.getElementById("document-create"),
      },
    ],
    "/dashboard/documents/new": [
      {
        title: "Offer Template",
        description:
          "We'll guide you through the process of creating a reusable offer letter, carefully tailored to your company's needs.",
        target: () => document.getElementById("doc-offer-template"),
      },
      {
        title: "Employee Document",
        description:
          "Add a document for new employees during onboarding or for your existing employees.",
        target: () => document.getElementById("doc-employee-doc"),
      },
    ],
    "/dashboard/documents/offer-template": [
      {
        title: "Create Offer Template",
        description:
          "Create an offer template for individuals to join your company. Specify the template name and indicate whether you have your own pre-existing templates to integrate.",
        target: () => document.getElementById("doc-offer-template"),
      },
      {
        title: "Customize Offer Template",
        description:
          "Customize your offer template by including specific information relevant to employment terms, compensation, and other details.",
        target: () => document.getElementById("doc-offer-template-customize"),
      },
      {
        title: "Signature",
        description:
          "Ask the company signatory to sign this template. Typically, the signatory is the CEO, Owner, or HR Admin.",
        target: () => document.getElementById("doc-offer-template-signature"),
      },
    ],
    "/dashboard/documents/view-employee-document": [
      {
        title: "Download",
        description:
          "Download the employee document for offline access or printing purposes.",
        target: () => document.getElementById("empl-doc-download"),
      },
      {
        title: "Rename",
        description:
          "Rename the employee document to better reflect its content or purpose.",
        target: () => document.getElementById("empl-doc-rename"),
      },
      {
        title: "Assign",
        description:
          "Assign the employee document to specific employees for review or action.",
        target: () => document.getElementById("empl-doc-assign"),
      },
      {
        title: "Recall",
        description:
          "Recall the employee document from circulation for updates or corrections.",
        target: () => document.getElementById("empl-doc-recall"),
      },
      {
        title: "View Unacknowledged",
        description:
          "See a list of people who have not reviewed or acknowledged this document. Ensure all relevant individuals are informed and compliant.",
        target: () => document.getElementById("empl-doc-view-unacknowledged"),
      },
      {
        title: "View Acknowledged",
        description:
          "See a list of people who have reviewed and acknowledged this document. Keep track of compliance and ensure all necessary individuals are informed.",
        target: () => document.getElementById("empl-doc-view-acknowledged"),
      },
    ],
    // Benefits
    "/dashboard/benefits": [
      {
        title: "Benefits",
        description:
          "Access and manage employee benefits. Provide comprehensive benefits packages to attract and retain top talent.",
        target: () => document.getElementById("benefits-nav"),
        placement: "right",
      },
      {
        title: "Add Benefits",
        description:
          "Add new benefits to the payroll deductions. Provide employees with additional benefits managed by external providers.",
        target: () => document.getElementById("benefits-add-btn"),
      },
      {
        title: "Medical Subscription",
        description:
          "This allows you provide comprehensive medical coverage to support employee health and well-being, by allowing employees to choose insurance plans from private healthcare providers. ",
        target: () => document.getElementById("benefits-medical-sub"),
      },
      {
        title: "Pension Subscription",
        description:
          "Allow employees to choose pension plans from private providers. This helps employees to secure their financial future through retirement savings.",
        target: () => document.getElementById("benefits-pension-sub"),
      },
    ],
    "/dashboard/benefits/new": [
      {
        title: "Create New Benefit",
        description:
          "Create a new benefit by specifying the benefit name and enrolling employees. Provide employees with additional benefits to enhance their overall compensation package.",
        target: () => document.getElementById("benefit-create"),
      },
      {
        title: "Cumulative Deduction/Contribution",
        description:
          "If your employees have existing deductions and/or contributions that you want to track for this benefit, please enter the totals here. ",
        target: () =>
          document.getElementById("benefit-cumulative-deduction-contribution"),
      },
    ],
    "/dashboard/benefits/medical": [
      {
        title: "Search Medical Benefits",
        description:
          "Search for medical benefits by name. Quickly locate specific medical insurance plans to manage enrollment and administration.",
        target: () => document.getElementById("benefits-medical-search"),
      },
      {
        title: "Bulk Enroll Medical Benefits",
        description:
          "Bulk enroll employees for medical benefits. Streamline the enrollment process for medical insurance plans by enrolling multiple employees simultaneously.",
        target: () => document.getElementById("benefits-medical-bulk-enroll"),
      },
    ],
    "/dashboard/benefits/pensions": [
      {
        title: "Search Pension Benefits",
        description:
          "Search for pension benefits by name. Quickly locate specific pension plans to manage enrollment and administration.",
        target: () => document.getElementById("benefits-pension-search"),
      },
      {
        title: "Bulk Enroll Pension Benefits",
        description:
          "Bulk enroll employees for pension benefits. Streamline the enrollment process for pension plans by enrolling multiple employees simultaneously.",
        target: () => document.getElementById("benefits-bulk-enroll"),
      },
    ],
    // Tasks
    "/dashboard/tasks": [
      {
        title: "Tasks",
        description:
          "Manage your routine and onboarding tasks to ensure  smooth operations and employee onboarding.",
        target: () => document.getElementById("tasks-nav"),
        placement: "right",
      },
      {
        title: "Routine Tasks",
        description:
          "Manage routine tasks in order to keep track of recurring tasks and deadlines to ensure timely completion.",
        target: () => document.getElementById("tasks-routine"),
      },
      {
        title: "Onboarding Tasks",
        description:
          "Manage onboarding tasks for new employees. Ensure smooth onboarding processes by tracking and completing necessary tasks for new hires.",
        target: () => document.getElementById("tasks-onboarding"),
      },
      {
        title: "Create Task",
        description:
          "Create a new task which you can specify task details, deadlines, and assignees to facilitate effective task management.",
        target: () => document.getElementById("tasks-create"),
      },
    ],
    "/dashboard/tasks/onboarding": [
      {
        title: "Onboarding Tasks",
        description:
          "Manage onboarding tasks for new employees. Ensure smooth onboarding processes by tracking and completing necessary tasks for new hires.",
        target: () => document.getElementById("tasks-onboarding"),
      },
      {
        title: "Create Onboarding Task",
        description:
          "Create a new task which you can specify task details, to facilitate effective task management.",
        target: () => document.getElementById("tasks-create"),
      },
    ],
    //  Disciplinary
    "/dashboard/disciplinary/violations": [
      {
        title: "Violations",
        description:
          "Manage disciplinary violations. Track and address employee violations to ensure workplace compliance and maintain a positive work environment.",
        target: () => document.getElementById("violations-nav"),
        placement: "right",
      },
      {
        title: "Search Violations",
        description:
          "Search for disciplinary violations by employee name or violation type. Quickly locate specific violations to review or take action.",
        target: () => document.getElementById("violations-search"),
      },
      {
        title: "Filter Violations",
        description:
          "Filter disciplinary violations by status. Choose from options such as 'To Review,' 'Declined,' or 'Approved' to focus on specific types of violations.",
        target: () => document.getElementById("violations-filter"),
      },

      {
        title: "Record Violation",
        description:
          "Record a violation for an employee by providing details such as employee name, violation type, incident date, and details of the violation. Optionally, attach supporting documents if available.",
        target: () => document.getElementById("violations-record"),
      },
    ],
    "/dashboard/disciplinary/policies": [
      {
        title: "Policies",
        description:
          "Access and manage company policies related to disciplinary actions. Ensure consistent enforcement and adherence to company policies.",
        target: () => document.getElementById("policies-nav"),
      },
      {
        title: "Search Policies",
        description:
          "Search for disciplinary policies by name to review or reference during disciplinary proceedings.",
        target: () => document.getElementById("policies-search"),
      },
      {
        title: "Create Policy",
        description:
          "Create a new disciplinary policy by providing details such as policy name, violation type, description, and exemptions. Specify if there's a threshold before system actions are triggered.",
        target: () => document.getElementById("policies-create"),
      },
    ],
    "/dashboard/disciplinary/triggers": [
      {
        title: "Triggers",
        description:
          "Manage triggers for disciplinary actions. Set up automated triggers based on predefined criteria to initiate disciplinary proceedings when violations occur.",
        target: () => document.getElementById("triggers-nav"),
      },
      {
        title: "Search",
        description: "Search for triggers by name or action.",
        target: () => document.getElementById("search-triggers-section"),
      },
    ],
    "/dashboard/disciplinary/violation-types": [
      {
        title: "Violation Types",
        description:
          "Manage violation types for disciplinary actions. Define and categorize different types of violations to ensure consistency in disciplinary proceedings.",
        target: () => document.getElementById("violation-types-nav"),
      },
      {
        title: "Search Violation Types",
        description: "Search for violation types by name.",
        target: () => document.getElementById("violation-types-search"),
      },
      {
        title: "Create Violation Type",
        description:
          "Create a new violation type to define specific categories of misconduct or infractions. Use violation types to set up policies that trigger penalties for disciplinary actions.",
        target: () => document.getElementById("violation-type-create"),
      },
    ],
    // Reports
    "/dashboard/report": [
      {
        title: "Summary Reports",
        description:
          "These are some helpful reports that give you an overview of your business.",
        target: () => document.getElementById("reports-summary-nav"),
      },

      {
        title: "Payroll Journal",
        description:
          "View your employees’ past earnings, deductions, taxes, and payables. Access detailed records of payroll transactions to track financial data accurately.",
        target: () => document.getElementById("reports-payroll-journal"),
      },
      {
        title: "Payroll Summary",
        description:
          "Download and analyze your employees’ earnings, deductions, and taxes.",
        target: () => document.getElementById("reports-payroll-summary"),
      },
      {
        title: "Payroll Variance",
        description:
          "Compare your employees’ earnings, deductions, taxes, and payables with previous values.",
        target: () => document.getElementById("reports-payroll-variance"),
      },
      {
        title: "Year to Date",
        description:
          "View your employees’ year-to-date earnings, deductions, taxes, and payables.",
        target: () => document.getElementById("reports-year-to-date"),
      },
      {
        title: "Employee Report Builder",
        description:
          "Build custom reports using your employees' personal and compensation information.",
        target: () =>
          document.getElementById("reports-employee-report-builder"),
      },
      {
        title: "Statutory Benefits",
        description:
          "See company contributions and employee deductions for the statutory benefits you provide.",
        target: () => document.getElementById("reports-statutory-benefits"),
      },
      {
        title: "Custom Benefits",
        description:
          "See company contributions and employee deductions for the custom benefits you offer.",
        target: () => document.getElementById("reports-custom-benefits"),
        placement: "top",
      },
      {
        title: "Payroll Changes",
        description:
          "View active payroll changes scheduled for upcoming payrolls.",
        target: () => document.getElementById("reports-payroll-changes"),
        placement: "top",
      },
      {
        title: "Payroll Exemptions",
        description:
          "View employees that may be excluded from upcoming payrolls.",
        target: () => document.getElementById("reports-payroll-exemptions"),
        placement: "top",
      },
      {
        title: "Paygrades",
        description:
          "See a breakdown of paygrades with related allowances and statutory benefits.",
        target: () => document.getElementById("reports-paygrades"),
      },
      {
        title: "Employee Requests",
        description:
          "View your employees’ pending, approved, and declined requests.",
        target: () => document.getElementById("reports-employee-requests"),
      },
      {
        title: "Leave Balances",
        description:
          "View your employees’ leave accruals and balances for each policy.",
        target: () => document.getElementById("reports-leave-balances"),
      },
      {
        title: "Custom Fields",
        description:
          "See the custom information you have collected about your employees.",
        target: () => document.getElementById("reports-custom-fields"),
      },
      {
        title: "Employee Summary",
        description:
          "All the details about your employees’ compensation and taxes, in one simple report.",
        target: () => document.getElementById("reports-employee-summary"),
      },
      {
        title: "Statutory Payments",
        description:
          "See all tax and employee statutory payments that PaidHR has made on your behalf.",
        target: () => document.getElementById("reports-statutory-payments"),
      },
      {
        title: "Bank Transactions",
        description:
          "View every bank transaction initiated by PaidHR on behalf of your company.",
        target: () => document.getElementById("reports-bank-transactions"),
      },
    ],
    "/dashboard/report/custom-report": [
      {
        title: "Custom Reports",
        description:
          "Create personalized reports tailored to your specific business needs. Customize report parameters, metrics, and visualizations to gain deeper insights into your data.",
        target: () => document.getElementById("custom-reports-nav"),
        placement: "right",
      },
      {
        title: "Search Custom Reports",
        description:
          "Search for specific custom reports by report name to easily locate and access the reports you need.",
        target: () => document.getElementById("custom-reports-search"),
      },
      {
        title: "Create Custom Report",
        description:
          "Build a new custom report to analyze your business data. Define report parameters, select metrics, and configure visualizations to generate tailored insights.",
        target: () => document.getElementById("custom-report-create"),
      },
      {
        title: "Custom Reports Table",
        description:
          "View, edit, or delete custom reports from this table. Take actions to manage and organize your custom reports according to your needs.",
        target: () => document.getElementById("custom-reports-table"),
      },
    ],
    "/dashboard/reports-payroll-history": [
      {
        title: "Payroll History",
        description:
          "View a comprehensive record of past payroll ran. Review detailed information about each payroll cycle, including earnings, deductions, taxes, and payables.",
        target: () => document.getElementById("reports-payroll-history-nav"),
      },
      {
        title: "Search Payroll History",
        description:
          "search for  specific payroll records by period to easily locate and access the payroll history you need.",
        target: () => document.getElementById("reports-payroll-history-search"),
      },
      {
        title: "Payroll History Table",
        description:
          "View past payroll records in this table, which you can take actions such as viewing or emailing specific payslips to employees.",
        target: () => document.getElementById("reports-payroll-history"),
      },
    ],
    // Hiring
    // Contractors

    // TimeTracking

    // Assets
    // Audit
    // Announcement
    // Calendar
    // Request continuation
  }

  return routeToStepsMap[route] || [defaultStep]
}
