import { Form, FormItemProps, Input, Select, Radio, InputNumber } from "antd"

const { Option } = Select

export const CustomInput = ({
  label,
  name,
  required = false,
  maxLength = 50,
  onChange,
  disabled = false,

  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm ">{label}</span>
      }
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Input
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export const CustomSelect = ({
  label,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options,
  onChange,
  onSelectClick,
  isLoading = false,
  mode,
  value,
  disabled = false,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <span className="font-circular text-neutral300 text-sm ">
            {label}
          </span>
        ) : (
          ""
        )
      }
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Select
        disabled={disabled}
        loading={isLoading}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        value={value || undefined}
        onClick={onSelectClick}
        filterOption={(input, option) => {
          if (option) {
            const value = (option.value as string) || ""
            const children =
              typeof option.children === "string" ? option.children : ""
            return (
              value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
              children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            )
          } else {
            return false
          }
        }}
        mode={mode}
      >
        {options?.map((option, index) => (
          <Option
            key={index}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
        {["first", "second"].map((item) => (
          <Option
            key={"empty" + item}
            className="pointer-events-none"
          >
            {" "}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export const CustomTextarea = ({
  label,
  name,
  required = false,
  value,
  onChange,
  ...restProps
}: TextareaItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm">{label}</span>
      }
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Input.TextArea
        value={value}
        onChange={onChange}
        style={{ height: "89px", padding: "9px 11px" }}
      />
    </Form.Item>
  )
}

export const CustomOptions = ({
  label,
  name,
  required = false,
  onChange,
  initialValue,
  ...restProps
}: OptionsItemProps) => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <span className="font-circular text-neutral300 text-sm whitespace-normal text-left">
            {label}
          </span>
        ) : (
          ""
        )
      }
      initialValue={initialValue}
      rules={[{ required, message: "Please select an option!" }]}
      {...restProps}
    >
      <Radio.Group
        onChange={onChange}
        className="flex items-center gap-10"
      >
        <Radio value="yes">Yes</Radio>
        <Radio value="no">No</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

type OptionsItemProps = FormItemProps & {
  label: string
  required?: boolean
  onChange?: (e: any) => void
}
type TextareaItemProps = FormItemProps & {
  label: string
  required?: boolean
  value?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
}

type InputItemProps = FormItemProps & {
  maxLength?: number | undefined
  label: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  required?: boolean
  disabled?: boolean
  max?: number | undefined
}

type SelectItemProps = FormItemProps & {
  label: string | Element | React.ReactNode
  required?: boolean
  showSearch?: boolean
  onChange?: (e: any) => void
  onSelectClick?: VoidFunction
  placeholder: string
  options: { label: string; value: string }[] | undefined
  isLoading?: boolean
  mode?: "multiple" // New property for multiple selections
  value?: string[] | string
  disabled?: boolean
}
