import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useGet } from "utils/useFetch"
import { Select, Checkbox, Form, Tooltip, Input, InputNumber } from "antd"
import { IEmployee } from "stores/employee/types"
import { Link, useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import usePermissions from "hooks/usePermissions"

import SuccessModal from "pages/dashboard/wallets/crossBorderPayments/modals/SuccessModal"
import { availableFlags } from "utils/flags"

const SingleContractor = ({
  formBodySingle,
  setFormBodySingle,
}: {
  formBodySingle: any
  setFormBodySingle: any
}) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [nameError, setNameError] = useState("")
  const [canCreate] = usePermissions({
    allowedPermissions: ["can_create_pay_schedules"],
  })
  const [scheduleConfirmation, setScheduleConfirmation] = useState(false)

  const handleCreateSinglePayment = () => {
    const singleBody = [
      {
        description: formBodySingle.description,
        amount: formBodySingle.amount,
        au_comment: "",
        contractor_id: formBodySingle?.contractor_id,
        state: "",
      },
    ]

    submit(singleBody)
  }

  const { isLoading: isCheckingScheduleName, mutate: checkScheduleName } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body.description}&payment_schedule_id=${body.id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "error" && data.msg) {
          setNameError(data.msg)

          toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        setNameError(data.msg)
        toast.error(data.msg)
      },
    })

  const { isLoading: isContractorsLoading, data: contractors } = useGet<any[]>({
    url: `/admin/contractors/getforselectionwithaccountdetails?search=""`,
  })
  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        "/admin/payment_schedules/create_custom_payment_schedule",
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      {
        data.msg && toast.success(data.msg)
      }
      mixPanelEvent("pay-schedule-custom-add-success")
      navigate("/dashboard/payment-schedules/custom")
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  return (
    <>
      <div className=" mt-6 flex flex-col lg:grid grid-cols-2 gap-x-6 items-start">
        <div
          className="col-span-2 w-full mb-6"
          id="custom-payment-desc"
        >
          <Form.Item
            className="!mb-0"
            name="amountSingle"
            label={
              <span className="font-circular text-padeBlack text-sm  flex items-center ">
                Payment description
                <Tooltip
                  title="Mamixum of 50 characters"
                  trigger={["hover"]}
                >
                  <span>
                    <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                  </span>
                </Tooltip>
              </span>
            }
            extra={
              nameError && <span className="text-danger500">{nameError}</span>
            }
            rules={[
              {
                validator: async (_, value) => {
                  const wordCount = value ? value.trim().split(/\s+/).length : 0
                  if (wordCount > 50) {
                    throw new Error("Description must be 50 words or less")
                  }
                },
              },
            ]}
          >
            <Input
              value={formBodySingle.description}
              placeholder="Enter"
              maxLength={50}
              onChange={(e) => {
                setNameError("")
                setFormBodySingle({
                  ...formBodySingle,
                  description: e.target.value,
                })
              }}
              onBlur={(e) => {
                {
                  e.target.value &&
                    checkScheduleName({
                      description: e.target.value,
                      id: "-1",
                    })
                }
              }}
            />
          </Form.Item>
        </div>
        <div
          className="w-full"
          id="custom-payment-amount"
        >
          <div className="mt-1">
            <Form.Item
              label={
                <span className="font-circular text-padeBlack text-sm  ">
                  Select contractor
                </span>
              }
              name="contractors"
              rules={[{ required: true, message: "Please enter this field!" }]}
            >
              <Select
                showSearch
                onChange={(selected) => {
                  const person = contractors?.find(
                    (emp) => emp.name === selected
                  )
                  if (person) {
                    setFormBodySingle({
                      ...formBodySingle,
                      person_name: person?.name,
                      contractor_id: String(person?.contractor_id),
                      person_bank:
                        person.bank ??
                        `${person?.routing_code}(${person?.routing_type})`,
                      person_account_number: person.account_number,
                      currency_symbol: person?.currency_symbol,
                      currency_code: person?.currency_code,
                    })
                  }
                }}
                loading={isContractorsLoading}
                options={contractors?.map((person: any) => ({
                  label: (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{person.name}</span>
                      <div>
                        <img
                          src={
                            availableFlags?.find(
                              (item) => item.code === person.currency_code
                            )?.flag || ""
                          }
                          alt={`${person?.currency_code} flag`}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        />
                        <span>{person.currency_code}</span>
                      </div>
                    </span>
                  ),
                  value: person?.name,
                }))}
                placeholder="Select contractor"
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-full">
          <div className="mt-1">
            <Form.Item
              label={
                <span className="font-circular text-padeBlack text-sm  ">
                  Enter amount{" "}
                </span>
              }
              name="amount"
              required
              rules={[{ required: true, message: "Please enter this field!" }]}
            >
              <InputNumber<number>
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  value?.replace(/,/g, "") as unknown as number
                }
                className="w-full font-circular text-neutral400"
                addonBefore={formBodySingle?.currency_symbol}
                defaultValue={formBodySingle.amount}
                placeholder=""
                onChange={(e) => {
                  setFormBodySingle({
                    ...formBodySingle,
                    amount: String(e),
                  })
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-3 mt-3 ">
        <Checkbox
          className="text-sm text-[#5D6B82]"
          defaultChecked={false}
          onChange={() => setScheduleConfirmation(!scheduleConfirmation)}
        >
          I confirm the payment details above
        </Checkbox>
      </div>
      <div className="flex justify-end w-full gap-6  py-4 bg-white border-t mt-14 shadow-footer  items-center">
        <Link to="/dashboard/payment-schedules/custom">
          <Button
            title="Previous"
            color="secondary"
          >
            Cancel
          </Button>
        </Link>

        <Button
          title="Next"
          color="primary"
          submitting={isSubmitting}
          onClick={() => {
            mixPanelEvent("pay-schedule-custom-add-init")
            if (canCreate) {
              handleCreateSinglePayment()
            } else {
              toast.unauthorized()
            }
          }}
          disabled={
            formBodySingle.description == "" ||
            formBodySingle.contractor_id == "" ||
            formBodySingle.amount == "" ||
            scheduleConfirmation == false ||
            nameError !== ""
              ? true
              : false
          }
        >
          Proceed{" "}
        </Button>
      </div>
    </>
  )
}

export default SingleContractor
