import { logos } from "assets"
import { Button, DashboardContainer } from "components"
import { useLocation, useNavigate } from "react-router-dom"
import { useGet } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { DownOutlined } from "@ant-design/icons"

import React, { useRef } from "react"
import ReactToPrint from "react-to-print"
import ReviewReportToPrint from "./ReviewReportToPrint"
import getIdFromKey from "utils/getIdFromKey"
import { CSVLink } from "react-csv"

import { Dropdown, Form, MenuProps, Space, Table } from "antd"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
const ReviewReport = () => {
  const myParam = useLocation().search
  const id = new URLSearchParams(myParam).get("id")
  const navigate = useNavigate()
  const componentRef: any = useRef()
  const { isLoading: isLoadingGenerateReport, data } = useGet<any>({
    url: `/admin/performance/selectreviewroundscores?review_group_id=${getIdFromKey(
      id || ""
    )}`,
  })
  const headers = [
    { label: "Employee name", key: "full_name" },
    { label: "Department", key: "department" },
    { label: "Role", key: "employee_title" },
    { label: "Email", key: "email" },
    { label: "Performance score", key: "performance_score" },
    { label: "Behavioural score", key: "behavioural_score" },
    { label: "Others score", key: "others_score" },
    { label: "Self score", key: "self_score" },
    { label: "Final score", key: "final_score" },
  ]

  const csvProps = {
    filename: data?.data.length
      ? `${data?.data[0].review_group}.csv`
      : "file.csv",
    headers: headers,
    data: data?.data,
    onafterprint: () => mixPanelEvent("pf-review-generate-report-success"),
  }

  return (
    <DashboardContainer>
      <>
        {isLoadingGenerateReport ? (
          <div className="h-[200px] items-center flex justify-center">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center w-full mb-2">
              <div className="flex items-center gap-3">
                <ArrowLeftIcon
                  className="  w-5 h-5"
                  onClick={() => navigate(-1)}
                />
                <h6 className=" text-neutral700  font-extrabold text-base capitalize">
                  {data?.data.length
                    ? `${data?.data[0].review_group} Report`
                    : "Review Report"}{" "}
                </h6>
              </div>

              <div className="flex justify-end items-center  gap-2">
                {" "}
                {componentRef && (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="primary"
                        title="Download"
                        onClick={() => {
                          mixPanelEvent("pf-review-generate-report-init")
                        }}
                      >
                        Download PDF
                      </Button>
                    )}
                    onAfterPrint={() => {
                      mixPanelEvent("pf-review-generate-report-success")
                    }}
                    content={() => componentRef.current}
                    documentTitle={
                      data?.data.length
                        ? `${data?.data[0].review_group}.pdf`
                        : "file.pdf"
                    }
                  />
                )}
                <CSVLink {...csvProps}>
                  <Button
                    color="primary"
                    title="Download"
                    onClick={() => {
                      mixPanelEvent("pf-review-generate-report-init")
                    }}
                  >
                    Download CSV
                  </Button>
                </CSVLink>
              </div>
            </div>
            <ReviewReportToPrint
              ref={componentRef}
              data={data}
            />
          </div>
        )}
      </>
    </DashboardContainer>
  )
}
export default ReviewReport
