import { Drawer, Spin } from "antd"
import { IPaymentDetail } from "pages/dashboard/wallets/interface"
import React, { Dispatch, SetStateAction, useState } from "react"
import { useLocation } from "react-router-dom"
import { useGet } from "utils/useFetch"
import cardImg from "assets/svg/wallet-card.svg"
import cardLeather from "assets/svg/Wallet-leather.svg"
import ChequeCard from "assets/svg/Cheque.svg"
import { ICurrencyInfo } from "../interface/payouts"
import { logos } from "assets"

import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { formatCurrencyNoSymbol } from "utils/currency"
import { ItransferDet } from "../../Component/PaymentScheduleTemplate"
import getIdFromKey from "utils/getIdFromKey"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import usePermissions from "hooks/usePermissions"
import { Button } from "components"
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons"
import { availableFlags } from "utils/flags"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { antIcon } from "pages/dashboard/dashboard/Dashboard"
import { LoaderWhite } from "components/custom/LoaderWhite"
import { DivideByRate, UnformattedDivideByRate } from "utils/rate"
type Props = {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  activeCurrency: ICurrencyInfo | null
  invalidateQueries: () => void
}
const ProcessPayment = ({
  open,
  onClose,
  activeCurrency,
  invalidateQueries,
}: Props) => {
  const myParam = useLocation().search
  const { toast } = useAppNotificationStore()
  const x = new URLSearchParams(myParam).get("x")
  const { user } = useAuthStore()
  const { customizationSettings } = useCustomizationSettingsStore()
  const [canProcess, canDelete] = usePermissions({
    allowedPermissions: [
      "can_process_pay_schedules",
      "can_delete_pay_schedules",
    ],
  })

  const walletId = user?.organisationWalletId
  const [paymentMethod, setPaymentMethod] = useState("Direct Deposit")
  const [InsufBalance, setInsufBalance] = useState(false)
  const [rateFailed, setRateFailed] = useState(false)
  const [total, setTotal] = useState(0)
  const [fee, setFee] = useState(0)
  const [subtotal, setsubTotal] = useState(0)
  const [rateValue, setRateValue] = useState<number>(0)
  const currencyElement = availableFlags?.find(
    (item) => item?.code === activeCurrency?.currency_code
  )
  const currencyFlag = currencyElement?.flag
  const currencySymbol = activeCurrency?.currency_symbol
  const USDElement = availableFlags?.find((item) => item?.code === "USD")
  const USDFlag = USDElement?.flag
  const USDSymbol = USDElement?.symbol

  const { isLoading: isPaymentLoading, data } = useGet<IPaymentDetail>({
    url: `admin/payment_schedules/view/index?id=detail&x=${x}`,
  })

  const {
    isLoading: isLoadingRate,
    data: rateData,
    refetch,
  } = useQuery(
    [`conversion-rate`],
    async () => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/conversion-rate`,
        {
          sourceCurrency: "USD",
          destinationCurrency: activeCurrency?.currency_code,
        },
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    {
      enabled:
        activeCurrency?.currency_code === "NGN" ||
        activeCurrency?.currency_code === "GBP" ||
        activeCurrency?.currency_code === "USD"
          ? false
          : true,
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {
        setRateValue(data?.data?.rate)
      },
      onError: (data: any) => {
        setRateFailed(true)
      },
    }
  )

  const { isLoading: isLoadingWallet, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const currentBalance =
    activeCurrency?.currency_code !== "GBP" &&
    activeCurrency?.currency_code !== "NGN"
      ? newWalletData?.data?.balances?.find(
          (balance) => balance.currency === "USD"
        )
      : newWalletData?.data?.balances?.find(
          (balance) => balance.currency === activeCurrency?.currency_code
        )

  const { isFetching: isLoadingTransferDetails, data: transferDetails } =
    useGet<ItransferDet>({
      url: `/admin/payment_schedules/gettransferdetails?id=${getIdFromKey(
        x || ""
      )}&x=${activeCurrency?.currency_code}`,
      enabled: (() => {
        if (activeCurrency?.currency_code) {
          if (["GBP", "USD", "NGN"].includes(activeCurrency.currency_code)) {
            return true
          } else {
            return rateValue !== 0 ? true : false
          }
        } else {
          return false
        }
      })(),
      onSuccess: (data: any) => {
        if (data?.status === "success") {
          const feeForm = Number(data?.record)
          const subtotalForm = rateValue
            ? Number(UnformattedDivideByRate(data?.data, rateValue))
            : Number(data?.data)
          const formattedTotal = feeForm + subtotalForm

          setTotal(formattedTotal)
          setFee(feeForm)
          setsubTotal(subtotalForm)

          currentBalance &&
          Number(formattedTotal) > Number(currentBalance?.balance)
            ? setInsufBalance(true)
            : setInsufBalance(false)
        }
      },
    })

  const { isLoading: isProcessingPayment, mutate: process } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/process_payment_schedule_new`,
        {
          id: data?.payment_schedule_id,
          payment_option: paymentMethod,
          currency_code: activeCurrency?.currency_code,
          // x: activeCurrency?.currency_code,
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose(false)
        // setAllSelectedRows([])
        // setProcessSelected(false)
        {
          data.msg && toast.success(data.msg)
        }

        invalidateQueries()
        mixPanelEvent("pay-schedule-payout-process-success")
      } else {
        {
          data.msg && toast.error(data.msg)
        }
        invalidateQueries()
        // setAllSelectedRows([])
        // setProcessSelected(false)
      }
    },
    onError: (data: any) => {
      // setProcessSelected(false)

      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  return (
    <Drawer
      width={455}
      title={
        <span className=" text-padeBlack font-circular text-xl">
          Process Payment
        </span>
      }
      open={open}
      // onClose={() => }
      closeIcon={false}
      className="bg-white relative"
      extra={
        <button
          onClick={() => {
            onClose(false)
            setRateValue(0)
            setTotal(0)
            setFee(0)
            setsubTotal(0)
          }}
        >
          <CloseOutlined className="h-6 w-6" />
        </button>
      }
    >
      <div className="overflow-y-scroll  pb-16 font-circular text-padeBlack w-full ">
        <div className="w-full">
          <p className=" text-sm font-circular text-padeBlack  font-medium ">
            Choose Payment Option
          </p>
          <div className="mt-3 h-[52px] w-full bg-[#F3F5FB] rounded-5px p-[6px] flex items-center mb-4">
            {["Direct Deposit", "Cheque"].map((item, index) => (
              <button
                key={index}
                className={`h-full  w-full text-sm font-circular ${
                  paymentMethod === item &&
                  "bg-white rounded shadow-sm text-padeBlack"
                }  `}
                onClick={() => {
                  setPaymentMethod(item)
                }}
              >
                {item}
              </button>
            ))}
          </div>
          {paymentMethod === "Cheque" ? (
            <div className="">
              <p className=" text-padeBlack font-circular text-sm mb-5">
                When you process by cheque please go ahead and write cheques for
                the selected beneficiaries.
              </p>
              {/* Cheque Card */}
              <div className="relative z-10">
                <img
                  src={ChequeCard}
                  alt="card"
                  className="w-full"
                />
                <div className="absolute top-0 bottom-0 right-0 left-0 px-6 py-8 z-50">
                  <div className="w-full flex items-center justify-between">
                    <div className="px-[5px] py-1 rounded-full flex items-center gap-1 bg-transparent border border-black w-fit">
                      <img
                        src={currencyFlag || ""}
                        alt=""
                        className="h-[18px] w-[18px]"
                      />
                      <p className="text-padeBlack text-[10px] font-circular">
                        {activeCurrency?.currency_code}
                      </p>
                    </div>
                    <p className="">Cheque</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <p className=" text-padeBlack font-circular text-sm mb-5">
                If you choose Direct Deposit,
                {""} {customizationSettings?.brand.platformName} will debit your
                wallet after you process this schedule. Note: Each deposit may
                attract a processing fee depending on the transfer amount and
                currency. Learn more about transfer charges in our Help Center.
              </p>
              <div className="relative mt-[95px]">
                {/* Wallet Card */}

                <div className="absolute card-cover-animation">
                  <img
                    src={cardImg}
                    alt="card"
                    className="w-full"
                  />

                  <div className="absolute top-0 bottom-0 right-0 left-0 px-6 py-8 z-50">
                    <div className="w-full flex items-center justify-between">
                      <div className="py-[5px] px-[10px] rounded-full flex items-center gap-1 bg-white w-fit">
                        <img
                          src={
                            activeCurrency?.currency_code !== "GBP" &&
                            activeCurrency?.currency_code !== "NGN"
                              ? USDFlag
                              : currencyFlag
                          }
                          alt=""
                          className="h-[18px] w-[18px]"
                        />
                        <p className="text-padeBlack text-[10px] font-circular">
                          {activeCurrency?.currency_code !== "GBP" &&
                          activeCurrency?.currency_code !== "NGN"
                            ? "USD"
                            : activeCurrency?.currency_code}
                        </p>
                      </div>
                      <img
                        src={logos?.PadeLogoWhiteNoName}
                        alt=""
                        className="mr-2"
                      />
                    </div>
                    <div className="mt-9">
                      {isLoadingWallet ? (
                        <Spin
                          spinning={true}
                          indicator={
                            <LoadingOutlined
                              style={{ color: "white", fontSize: 24 }}
                              spin
                            />
                          }
                        />
                      ) : (
                        <h5 className="font-circular  text-white text-[28px]">
                          {activeCurrency?.currency_code !== "GBP" &&
                          activeCurrency?.currency_code !== "NGN"
                            ? availableFlags?.find(
                                (item) => item?.code === "USD"
                              )?.symbol
                            : activeCurrency?.currency_symbol}
                          {formatCurrencyNoSymbol(
                            Number(currentBalance?.balance) || 0
                          )}
                        </h5>
                      )}
                      <p className="mt-[10px] text-padeLightBlue font-circular text-sm">
                        Wallet Balance
                      </p>
                    </div>
                  </div>
                </div>

                {/* Card Cover */}
                <div className="relative z-10">
                  <img
                    src={cardLeather}
                    alt="cover"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Spin spinning={isLoadingTransferDetails}>
          {paymentMethod !== "Cheque" && InsufBalance && (
            <p className=" font-avenir text-sm text-[#EB3949] font-bold my-4 ">
              Insufficient Funds
            </p>
          )}
          {rateFailed && (
            <p className=" font-avenir text-sm text-[#EB3949] font-bold my-4 ">
              Rate conversion failed
            </p>
          )}
          {activeCurrency?.currency_code !== "GBP" &&
            activeCurrency?.currency_code !== "NGN" &&
            activeCurrency?.currency_code !== "USD" &&
            rateData &&
            paymentMethod !== "Cheque" && (
              <div className="h-[34px] bg-[#ECF2FE] mt-[28px] px-4 flex items-center rounded-sm">
                <span className="mr-[9px] font-circular"> Rate</span>
                <div className="flex items-center justify-between flex-grow">
                  <div className="flex items-center  h-7">
                    {[USDFlag, currencyFlag]?.map((item, index) => (
                      <img
                        key={index}
                        className={`h-6 w-6 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold border-2 border-white ${
                          index !== 0 ? "-ml-3" : ""
                        }`}
                        src={item}
                        alt=""
                      />
                    ))}
                  </div>
                  <h6 className="text-padeBlack text-sm font-circular">
                    {USDSymbol}1 = {currencySymbol}
                    {formatCurrencyNoSymbol(Number(rateValue || 0), {
                      minimumFractionDigits: 7,
                      maximumFractionDigits: 7,
                    })}
                  </h6>
                </div>
              </div>
            )}
          <div className="mt-4">
            <span className=" text-sm font-circular text-padeBlack  font-medium">
              Payment Information
            </span>
            <div className="mt-[9px] rounded border p-4 flex flex-col">
              <div className=" grid grid-cols-2 my-3">
                <p className="text-[#0C1B3D]  font-circular text-sm">
                  Payout Amount
                </p>
                <p className=" text-padeBlack text-sm  text-right">
                  {activeCurrency?.currency_symbol}
                  {data &&
                    formatCurrencyNoSymbol(Number(transferDetails?.data) || 0)}
                </p>
              </div>
              {activeCurrency?.currency_code !== "GBP" &&
                activeCurrency?.currency_code !== "NGN" &&
                activeCurrency?.currency_code !== "USD" &&
                rateData && (
                  <div className=" grid grid-cols-2 my-3">
                    <p className="text-[#0C1B3D]  font-circular text-sm">
                      Debit Amount
                    </p>
                    <p className=" text-padeBlack text-sm  text-right">
                      {USDSymbol}

                      {rateData
                        ? formatCurrencyNoSymbol(Number(subtotal) || 0)
                        : 0}
                    </p>
                  </div>
                )}
              {paymentMethod !== "Cheque" && (
                <div className=" grid grid-cols-2 my-3 ">
                  <p className="text-[#0C1B3D]  font-circular text-sm">
                    Charge
                  </p>
                  <p className=" text-padeBlack text-sm  text-right">
                    {activeCurrency?.currency_code !== "GBP" &&
                    activeCurrency?.currency_code !== "NGN" &&
                    activeCurrency?.currency_code !== "USD"
                      ? USDSymbol
                      : activeCurrency?.currency_symbol}
                    {data && formatCurrencyNoSymbol(Number(fee) || 0)}
                  </p>
                </div>
              )}

              {paymentMethod !== "Cheque" && (
                <div className=" grid grid-cols-2 my-3">
                  <p className="text-[#0C1B3D]  font-circular text-sm">Total</p>
                  <p className=" text-padeBlack text-base  text-right font-bold">
                    {activeCurrency?.currency_code !== "GBP" &&
                    activeCurrency?.currency_code !== "NGN" &&
                    activeCurrency?.currency_code !== "USD"
                      ? USDSymbol
                      : activeCurrency?.currency_symbol}

                    {activeCurrency?.currency_code !== "GBP" &&
                    activeCurrency?.currency_code !== "NGN" &&
                    activeCurrency?.currency_code !== "USD"
                      ? formatCurrencyNoSymbol(Number(total) || 0)
                      : formatCurrencyNoSymbol(
                          Number(transferDetails?.pagi) || 0
                        )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Spin>
        <div className="mt-5  bg-[#FEECBD] px-[25px] py-[17px]">
          <p className=" text-padeBlack text-sm font-medium">
            We'll update the status of this payroll to processed after
            processing the selected payment(s). You can still cancel the{" "}
            {data?.description} after processing.
          </p>
        </div>
      </div>
      <div className="absolute bottom-0 flex justify-end left-0 right-0 h-[64px]  py-3 px-4 lg:px-6 bg-white ">
        <Button
          color={
            paymentMethod !== "Cheque" && InsufBalance ? "secondary" : "primary"
          }
          className="w-full"
          submitting={isProcessingPayment}
          disabled={(paymentMethod !== "Cheque" && InsufBalance) || total === 0}
          onClick={() => {
            if (canProcess) {
              process()
              mixPanelEvent("pay-schedule-payout-process-init")
            } else {
              toast.unauthorized()
            }
          }}
        >
          {paymentMethod !== "Cheque" && InsufBalance
            ? "Insufficient funds to process"
            : "Process"}
        </Button>
      </div>
    </Drawer>
  )
}

export default ProcessPayment
