import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { IWithdrawalData } from "../interface/wallet"
import TransactionsTemplate from "../components/TransactionsTemplate"

const Transactions = () => {
  const [search, setSearch] = useState("")

  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<IWithdrawalData>({
      url: `${newBaseUrl}/v1/employee/withdrawals`,
      external: true,
      onSuccess: (data: any) => {
        mixPanelEvent("empl-wallet-transaction-listing")
      },
    })

  return (
    <DashboardWrapper>
      <div className="">
        <h6 className="mb-3 text-neutral500 text-lg">Withdrawal History </h6>

        <div className="bg-white  p-4 rounded-5px">
          <div className="flex justify-between items-center mb-4">
            <div className="border-2  h-8  rounded-5px w-[268px] flex  items-center px-2 bg-white ">
              <MagnifyingGlassIcon className=" text-neutral80 w-4" />
              <input
                placeholder="Search"
                className="bg-none w-full px-2 outline-none  text-sm h-7"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </div>
          <TransactionsTemplate
            data={withdrawalsData?.data?.docs || []}
            isLoading={isWithdrawalsLoading}
          />
        </div>
      </div>
    </DashboardWrapper>
  )
}
export default Transactions
