import React from "react"
import { Route } from "react-router-dom"
import NewResponsiveApprovalsLayout from "components/new-stuff/layout/approvals/ApprovalsLayout"
import PayRollReportSummary from "pages/dashboard/payroll/records/PayRollReportSummary"
import PayRollReportVariance from "pages/dashboard/payroll/records/PayRollReportVariance"
import PayRollReportDetails from "pages/dashboard/payroll/records/PayRollReportDetails"

const ViolationListings = React.lazy(
  () => import("pages/approval/violations/ViolationListings")
)
const CustomList = React.lazy(
  () => import("pages/approval/payment-schedules/Custom")
)

const PayrollRecords = React.lazy(
  () => import("pages/approval/payroll/PayrollRecords")
)

const PayrollChanges = React.lazy(
  () => import("pages/approval/payroll/PayrollChanges")
)

const PayrollNew = React.lazy(() => import("pages/approval/payroll/PayrollNew"))

const DocumentRequests = React.lazy(
  () => import("pages/approval/requests/DocumentRequests")
)

const ExitRequests = React.lazy(
  () => import("pages/approval/requests/ExitRequests")
)

const LeaveRequests = React.lazy(
  () => import("pages/approval/requests/LeaveRequests")
)

const LoanRequests = React.lazy(
  () => import("pages/approval/requests/LoanRequests")
)

const Redeployments = React.lazy(
  () => import("pages/approval/requests/RedployementRequests")
)

const SalaryAdvances = React.lazy(
  () => import("pages/approval/requests/SalaryAdvanceRequests")
)
const ApproveAbsence = React.lazy(
  () => import("pages/approval/absence/ApproveAbsence")
)

const ApprovalRoutes = () => {
  return (
    <Route
      path=""
      element={<NewResponsiveApprovalsLayout />}
    >
      <Route path="/approval/">
        <Route
          path="absence"
          element={<ApproveAbsence />}
        />
        <Route path="requests">
          <Route
            path="leaves"
            element={<LeaveRequests />}
          />

          <Route
            path="salary-advances"
            element={<SalaryAdvances />}
          />
          <Route
            path="loans"
            element={<LoanRequests />}
          />
          <Route
            path="documents"
            element={<DocumentRequests />}
          />
          <Route
            path="exits"
            element={<ExitRequests />}
          />
          <Route
            path="redeployments"
            element={<Redeployments />}
          />
        </Route>
        <Route path="payrolls">
          <Route
            path="records"
            element={<PayrollRecords />}
          />
          <Route
            path="changes"
            element={<PayrollChanges />}
          />
          <Route
            path="new"
            element={<PayrollNew />}
          />
          <Route
            path="report-summary"
            element={<PayRollReportSummary />}
          />
          <Route
            path="report-variance"
            element={<PayRollReportVariance />}
          />
          <Route
            path=":payrollId/report-details"
            element={<PayRollReportDetails />}
          />
        </Route>
      </Route>
      <Route path="violations">
        <Route
          path=""
          element={<ViolationListings />}
        />
      </Route>
      <Route path="payment-schedules">
        <Route
          path="custom"
          element={<CustomList />}
        />
      </Route>
    </Route>
  )
}

export default ApprovalRoutes
