import { useState } from "react"
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline"
import EWAAccountImg from "assets/illustrations/ewa-account.svg"
import NoWallet from "assets/illustrations/no-wallet.svg"
import Salary from "assets/illustrations/Salary.svg"
import { useNavigate } from "react-router-dom"
import TransactionsTemplate from "../components/TransactionsTemplate"
import { IEarningsWalletData, IWithdrawalData } from "../interface/wallet"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { formatCurrencyNoSymbol } from "utils/currency"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useAppNotificationStore } from "stores"
import { Button } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
export const divideEarningsByPercent = (number: number, percent: number) => {
  const decimalPercent = percent / 100
  const calculatedResult = number * decimalPercent
  return calculatedResult
}
const EarnedWageAccess = () => {
  const [_, copy] = useCopyToClipboard()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()

  const [showPinModal, setShowPinModal] = useState(false)

  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
    retry: 0,
    onSuccess: (data: any) => {
      mixPanelEvent("empl-ewa-page-load")
    },
  })
  const invalidateQueries = () => {
    refetch()
  }
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<IWithdrawalData>({
      url: `${newBaseUrl}/v1/employee/withdrawals`,
      external: true,
      retry: 0,
      onSuccess: (data: any) => {
        console.log(data)
        mixPanelEvent("empl-ewa-transaction-listing")
      },
    })
  const previewData = [
    {
      title: "Bank Name",
      value: earningWalletData?.data?.employee?.bankName || "-",
    },
    {
      title: "Account Name",
      value: earningWalletData?.data?.employee?.bankAccountName || "-",
    },
    {
      title: "Account Number",
      value: earningWalletData?.data?.employee?.bankAccountNumber || "-",
      subValue: true,
    },
  ]

  const today = new Date()

  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  return (
    <DashboardWrapper>
      {isEarningWalletLoading ? (
        <div className=" z-50 h-screen flex justify-center items-center right-0 top-0 left-0 bottom-0 py-10">
          <LoadingIndicatorWhite />
        </div>
      ) : earningWalletData?.data ? (
        <div className="">
          <div className="lg:h-[284px] flex flex-col lg:grid grid-cols-5 gap-4 mb-10">
            <div className="col-span-3 h-full  flex flex-col">
              <div className="h-[112px] bg-primary500 py-[27px] px-4 lg:px-[30px] relative">
                <h6 className=" text-padeLightBlue font-circular text-sm mb-3">
                  Accrued amount (50% 0f your daily earnings){" "}
                </h6>
                <h4 className="text-white  font-bold font-circular text-xl">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(
                      divideEarningsByPercent(
                        earningWalletData?.data?.totalEarnings || 0,
                        50
                      )
                    )
                  )}
                </h4>
                <div className="absolute right-0 bottom-0">
                  <img
                    src={Salary}
                    alt=""
                    className="w-[60px] h-[60px] lg:w-[98px] lg:h-[98px]"
                  />
                </div>
              </div>
              <div className="flex-grow flex-col   bg-white py-[23px] px-4 lg:px-[62px]">
                <div className=" flex justify-between w-full mb-[25px]">
                  <div>
                    <h6 className="font-circular  font-bold text-lg lg:text-[25px] text-padeBlack mb-1">
                      ₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.availableBalance ?? 0)
                      )}
                    </h6>
                    <p className="text-neutral300 font-circular text-xs">
                      Balance
                    </p>
                  </div>
                  <div className="  h-[59px] border border-[#E2E4E9]" />

                  <div>
                    <h6 className="font-circular  font-bold text-lg lg:text-[25px] text-padeBlack mb-1">
                      ₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.totalWithdrawn ?? 0)
                      )}
                    </h6>
                    <p className="text-neutral300 font-circular text-xs">
                      Amount Taken
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <Button
                    color="primary"
                    className="w-full"
                    onClick={() => {
                      if (earningWalletData?.data?.employee?.enrollmentStatus) {
                        navigate(
                          "/employee/dashboard/wallet/earned-wage-access/withdrawal"
                        )
                        mixPanelEvent("empl-ewa-withdrawal-process-started")
                      } else {
                        navigate(
                          "/employee/dashboard/wallet/earned-wage-access/pin"
                        )
                      }
                    }}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-2  h-fit lg:h-full  bg-white rounded-5px py-5 lg:pt-[41px] px-4 lg:px-5">
              <div className="flex justify-between items-center">
                <div>
                  <h5 className=" text-sm lg:text-base font-circular text-padeBlack font-bold mb-0.5">
                    EWA Receiving Account
                  </h5>
                  <p className="text-[rgba(108,122,147,0.6)]  font-avenir ">
                    This is your salary account
                  </p>
                </div>
                <img
                  src={EWAAccountImg}
                  alt=""
                  className="h-[45px] w-[45px] lg:h-[70px] lg:w-[70px]"
                />
              </div>
              <div className=" bg-[#EEF4FE] rounded px-4 lg:px-6 py-4 mt-10 flex-grow gap-3  flex flex-col">
                {previewData?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col lg:flex-row justify-between items-center mb"
                  >
                    <p className="text-[#6C7A93] font-circular text-xs lg:text-sm">
                      {item?.title}
                    </p>
                    <p className="text-padeBlack  font-avenir font-bold text-xs lg:text-[13px] capitalize items-center flex ">
                      {item?.value}
                      {item?.subValue && (
                        <button
                          onClick={() => {
                            copy(String(item?.value))
                            toast.success("Copied")
                          }}
                          className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                        >
                          <ClipboardDocumentIcon className="w-4 h-4" />
                        </button>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <CustomCard
            title="Recent Transactions"
            ctaBtn={
              <div className="flex justify-end items-center py-4 px-4">
                <button
                  className="text-primary400 font-extrabold text-sm"
                  onClick={() =>
                    navigate(
                      "/employee/dashboard/wallet/earned-wage-access/transactions"
                    )
                  }
                >
                  See all
                </button>
              </div>
            }
          >
            <TransactionsTemplate
              data={withdrawalsData?.data?.docs.slice(0, 5) || []}
              isLoading={isWithdrawalsLoading}
            />
          </CustomCard>
        </div>
      ) : (
        <div className="w-full  h-screen justify-center items-center flex">
          <div className=" flex flex-col justify-center items-center w-full sm:w-[585px]  px-0 sm:px-8 font-avenir">
            <img
              src={NoWallet}
              alt="NoWallet"
              className="w-[338px] h-[253px]"
            />
            <h5 className="  mt-9 text-xl sm:text-2xl font-bold text-[#15294B] text-center font-circular">
              You're in, but your HR hasn't given you the green light for Earned
              Wage Access.!
            </h5>
            <p className="text-sm  text-bluesubtext mt-1 text-center mb-10 font-avenir">
              Don't miss out on instant access to your earnings.. Let's nudge
              your HR to get this amazing benefit rolling!
            </p>
          </div>
        </div>
      )}
    </DashboardWrapper>
  )
}
export default EarnedWageAccess
