import React, { useRef, useState } from "react"
import { get } from "lodash"
import { LoadingIndicator } from "./Loader"
import Pagination, { IPagination } from "./Pagination"
import { H3 } from "components/typography/H3"
import { illustrations } from "assets"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
// import { NewLoadingIndicator } from "./Loading"

export interface IColumnType<T> {
  key: string
  title: string
  width?: string
  render?: (column: IColumnType<T>, item: T) => React.ReactNode
  renderHeader?: (column: IColumnType<T>) => React.ReactNode
  onRowClick?: (item: T) => void
}

interface Props<T> extends Partial<IPagination> {
  data: T[]
  header: IColumnType<T>[]
  headerComponent?: React.ReactNode
  isExpandable?: boolean
  renderExpandable?: (item: T) => React.ReactNode
  isLoading?: boolean
  useHeaderColor?: boolean
  headerProps?: string
  withPagination?: boolean
  attachTopEl?: boolean
  onRowClick?: (item: T) => void
}

interface PropsTableRow<T> {
  data: T[]
  columns: IColumnType<T>[]
  isExpandable?: boolean
  renderExpandable?: (item: T) => React.ReactNode
  onRowClick?: (item: T) => void
}

interface PropsTableHeader<T> {
  columns: IColumnType<T>[]
  useHeaderColor?: boolean
  headerProps?: string
}

interface PropsTableCell<T> {
  item: T
  column: IColumnType<T>
}

export function Table<T>({
  data,
  header,
  headerComponent,
  isExpandable,
  renderExpandable,
  onRowClick,
  isLoading = false,
  withPagination = false,
  attachTopEl = false,
  useHeaderColor = true,
  headerProps,
  ...paginationProps
}: Props<T>): JSX.Element {
  const headerComponentRef = useRef<HTMLDivElement | null>(null)
  const topSpace = headerComponentRef?.current?.clientHeight || 0

  return (
    <div className="flex flex-col justify-between  h-full  relative">
      <div
        className={`${
          attachTopEl ? "rounded-b-md" : "rounded-md"
        } rounded-md shadow-md border border-[#EBEDF0]  `}
      >
        <div
          ref={headerComponentRef}
          className="px-5"
        >
          {headerComponent}
        </div>
        {isLoading && data?.length === 0 && (
          <div className="z-[2] flex  items-center justify-center py-10 bg-white bg-opacity-70  absolute left-0 top-0 right-0 bottom-0 ">
            <div>
              <LoadingIndicator />


            </div>
          </div>
        )}
        <table className="border-none h-auto w-full table table-auto">
          <thead>
            <TableHeader
              columns={header}
              useHeaderColor={useHeaderColor}
              headerProps={headerProps}
            />
          </thead>
          <tbody className="relative">
            <TableRow
              data={data}
              columns={header}
              onRowClick={onRowClick}
              isExpandable={isExpandable}
              renderExpandable={renderExpandable}
            />
          </tbody>
        </table>
        {isLoading && data?.length !== 0 && (
          <div className="z-[2] flex h-96 items-center justify-center py-10 bg-white bg-opacity-75  bottom-0   absolute left-0 top-0 right-0  ">
            <div>
              <LoadingIndicator />


            </div>
          </div>
        )}

        {!isLoading && data?.length === 0 && (
          <div className="h-96 flex items-center justify-center py-5 bg-white">
            <div className="text-center">
              <div className="w-[200px] h-full flex flex-col gap-8 justify-center items-center">
                <img
                  src={illustrations.EmptyOffers}
                  alt="Empty Offers"
                />
              </div>
              <h6 className="mt-2   text-sm tracking-wider text-[#243757] font-bold">
                NO DATA AVAILABLE
              </h6>
            </div>
          </div>
        )}
      </div>

      {withPagination && (
        <Pagination
          total={paginationProps.total || 10}
          currentPage={paginationProps.currentPage || 1}
          perPage={paginationProps.perPage || 10}
          onPageChange={paginationProps.onPageChange || (() => {})}
          updatePerPage={paginationProps.updatePerPage || (() => {})}
        />
      )}
    </div>
  )
}

export function TableHeader<T>({
  columns,
  useHeaderColor,
  headerProps,
}: PropsTableHeader<T>): JSX.Element {
  return (
    <tr>
      {columns.map((column, columnIndex) => (
        <th
          key={`table-header-${columnIndex}`}
          className={`py-7 text-left tracking-wider  text-[#111827] font-extrabold px-3   ${
            useHeaderColor ? "bg-[#fff]" : headerProps
          } `}
          style={{ width: `${column.width}` }}
        >
          <div className="flex items-center justify-start text-sm cursor-pointer">
            <span>
              {column.renderHeader ? column.renderHeader(column) : column.title}
            </span>
          </div>
        </th>
      ))}
    </tr>
  )
}

export function TableRow<T>({
  data,
  columns,
  isExpandable = false,
  renderExpandable,
  onRowClick,
}: PropsTableRow<T>): JSX.Element {
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  return (
    <>
      {data?.map((item, itemIndex) => (
        <>
          <tr
            className="border-t border-[#DFE2E6] hover:bg-primary50 cursor-pointer font-extrabold  "
            key={`table-body-${itemIndex}`}
            onClick={() => onRowClick!(item)}
          >
            {columns.map((column, columnIndex) => (
              <TableRoWCell
                key={`table-body-${columnIndex}`}
                item={item}
                column={column}
              />
            ))}
            {isExpandable && (
              <td
                className={`py-3 text-[#42526D] bg-white px-3 text-sm   font-medium`}
                style={{ width: "30px" }}
                onClick={(e) => {
                  e.stopPropagation()
                  setExpandedRow(expandedRow === itemIndex ? null : itemIndex)
                }}
                key={`table-body-expandable-${itemIndex}`}
              >
                <ChevronUpIcon
                  className={`w-5 h-5 text-[#42526D] duration-150 ${
                    expandedRow === itemIndex ? "transform rotate-180" : ""
                  }`}
                />
              </td>
            )}
          </tr>
          {isExpandable && expandedRow === itemIndex && (
            <tr>
              <td
                colSpan={columns.length}
                className="p-0"
              >
                {renderExpandable!(item)}
              </td>
            </tr>
          )}
        </>
      ))}
    </>
  )
}

function TableRoWCell<T>({ item, column }: PropsTableCell<T>): JSX.Element {
  const value = get(item, column.key)
  return (
    <td
      className={`py-3 text-[#111827]   bg-white px-3 text-sm   font-medium`}
      style={{ width: `${column.width}` }}
    >
      {column.render ? (
        column.render(column, item)
      ) : (
        <span className="truncate text-sm ">{value}</span>
      )}
    </td>
  )
}
