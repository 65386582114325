import React, { forwardRef } from "react"
import { logos } from "assets"
import { formatCurrencyNoSymbol } from "utils/currency"
import ImageComponent from "components/custom/image"
import { useAuthStore } from "stores"
import NSITFRow from "./NSITFRow"

export interface INSITFSchedule {
  full_name: string
  identification_number: string
  amount: number
  currency_symbol: string
}

const NSITFReportToPrint = forwardRef(({ data }: any, ref: any) => {
  const { user } = useAuthStore()

  const groupedNHFSchedules: INSITFSchedule[] = data?.nsitfSchedules || []

  return (
    <div
      ref={ref}
      className="bg-white shadow-card   py-[25px]  px-4 lg:px-[35px]"
    >
      <div className=" flex justify-between w-full items-center mb-6">
        <div>
          {" "}
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            {data?.scheduleDate}
          </h4>
          <p className=" text-neutral500 text-sm leading-snug">
            Report created on: {data?.report_date}
          </p>
        </div>
        <div className=" mt-1">
          <ImageComponent
            src={user?.organisationAvatarFilename ?? logos.PadeLogo}
            alt="org"
            className={` w-[200px] h-[100px] max-h-[200px] `}
          />
        </div>
      </div>
      <div className="mb-9 h-[117px] border-b bg-primary50 px-4 lg:px-[50px] flex justify-between items-center py-5">
        <div className=" gap-4 flex items-center">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {/* {user?.userOrganisation} */}
            </h5>
            <p className=" text-neutral500 font-semibold text-lg leading-snug">
              NSITF Schedule Report
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              Employer code: {data?.nsitfReferenceNumber}
            </p>
          </div>
        </div>
        <div className="">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.full_name}
            </h5>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_line_1}
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_city},{" "}
              {data?.officeAddress?.address_state}
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-2">
        <div className="  grid-cols-3 grid gap-[6px] w-full border-b border-black py-2 ">
          <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
            Employee Name
          </span>
          <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
            Employee ID
          </span>
          <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
            Amount
          </span>
        </div>
        <div className="">
          {groupedNHFSchedules.map((item) => (
            <NSITFRow item={item} />
          ))}
        </div>
        <OverallTotal items={groupedNHFSchedules} />
      </div>
    </div>
  )
})

const OverallTotal: React.FC<{
  items: INSITFSchedule[]
}> = ({ items }) => {
  const overallTotal: any = items.reduce(
    (total: any, schedule: any) => total + Number(schedule.amount),
    0
  )

  return (
    <div
      className={`border-t border-black  mt-4 py-3 flex-grow   grid-cols-3 grid gap-[6px] w-full `}
    >
      <div className="">
        <span className="flex-wrap  font-avenir   text-sm text-[#42526D]"></span>
      </div>
      <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
        Overall total
      </span>
      <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
        ₦{formatCurrencyNoSymbol(overallTotal)}
      </span>
    </div>
  )
}

export default NSITFReportToPrint
