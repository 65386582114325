import { useState } from "react"
import { Formik, FormikHelpers } from "formik"
import * as Yup from "yup"

import LoginImg1 from "assets/images/LoginImg1.png"
import LoginImg2 from "assets/images/new-login-img.png"
import LineDivider from "assets/images/linedivider.svg"
import LogoWhite from "assets/logos/paidhr-logo-white.svg"
import { Button, Input, TextLink, PasswordInput } from "components"
import { Link, useLocation } from "react-router-dom"
import { Carousel, Space } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { parseQuery } from "utils/parseQuery"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { GoogleLogin } from "./GoogleLogin"
import { MicrosoftLogin } from "./MicrosoftLogin"
import { VerifyOtpPrompt } from "pages/2fa/modals/VerifyOtpPrompt"
import { ILoginResponse } from "stores/auth"
import { useHandleLogin } from "./useHandleLogin"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"

//const { toast } = useAppNotificationStore()
const validateSchema = Yup.object().shape({
  email: Yup.string().required("Please enter your email").email(),
  password: Yup.string().required("Please enter your password"),
})

interface Values {
  password: string
  email: string
}

const Login = () => {
  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading="You’re in good company"
          subHeading="From startups to established enterprises, companies of all sizes use PaidHR"
          showGetHelp
          showCompanies
        >
          <div className="">
            <h2 className="font-circular font-bold text-4xl mb-2">
              Welcome back!
            </h2>
            <p className="font-circular font-semibold text-base text-neutral500 mb-8">
              Don't have an account?{" "}
              <Link
                className="text-primary500 no-underline "
                to={"/get-started"}
                onClick={() => {
                  mixPanelEvent("auth-sign-up", "")
                }}
              >
                Sign up
              </Link>
            </p>

            <LoginForm />
          </div>
        </NewAuthWrapper>
      ) : (
        <div>
          <div className="flex flex-col lg:flex-row items-stretch">
            <div
              className={`hidden lg:block w-full md:max-w-[600px] 2xl:max-w-[650px] h-screen h-[100dvh] pt-[40.49px] pb-[60px] bg-primary500`}
            >
              <div className="ml-[38px]">
                <img
                  className=""
                  src={
                    isPadeDomain() === true
                      ? LogoWhite
                      : customizationSettings.brand.logoUrl
                  }
                  alt="logo"
                  width={120}
                />
              </div>
              <LHS />
            </div>

            <div className="grow w-full md:max-w-[700px] flex items-center xl:pt-20 md:pl-[40px] xl:pl-[100px]">
              <div className="grow px-4 mt-6 lg:mt-0">
                <h2 className="font-circular font-bold text-neutral700 text-[35px] leading-[45.5px] mb-[19px]">
                  Login
                </h2>
                <p className="font-circular font-medium text-[16px] leading-[20.8px] text-neutral300 mb-[43px]">
                  New to {customizationSettings.brand.platformName}?{" "}
                  <TextLink
                    className="text-primary500 no-underline font-extrabold text-[16px] leading-[21.86px]"
                    title="Create account"
                    to={"/onboarding/business-registration"}
                    onClick={() => {
                      mixPanelEvent("auth-sign-up", "")
                    }}
                  >
                    Create account
                  </TextLink>
                </p>

                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  )
}

export default Login

export const LoginForm = () => {
  const [showVerifyOtpPrompt, setShowVerifyOtpPrompt] = useState(false)
  //@: Access token used to verify the code a user has entered in the Two Factor Authentication prompt.
  const [twoFALoginResponse, setTwoFALoginResponse] = useState<ILoginResponse>()

  const urlQuery = parseQuery(useLocation().search)

  const { handleLoginProcess, loading } = useHandleLogin(
    setShowVerifyOtpPrompt,
    setTwoFALoginResponse
  )

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validateSchema}
        onSubmit={async (values, { setSubmitting }: FormikHelpers<Values>) => {
          try {
            await handleLoginProcess(
              values.email,
              values.password,
              urlQuery.token
            )
          } catch (error) {
            console.log(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(formik) => {
          return (
            <div>
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-1">
                  <Input
                    name="email"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />

                  {formik.errors.email && formik.touched.email ? (
                    <div className="text-danger500 text-sm font-avenir">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-col gap-y-1">
                  <PasswordInput
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="w-full error:border-danger500 error:text-danger500 error:placeholder-danger500"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />

                  {formik.errors.password && formik.touched.password ? (
                    <div className="text-danger500 text-sm font-avenir">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between mt-[13.45px] mb-[25px]">
                <span className="avenirstdmedium font-medium text-[16px] leading-[21.86px] text-neutral700">
                  {/* Remember me */}
                </span>
                <TextLink
                  className="text-primary500 no-underline font-extrabold text-[16px] leading-[21.86px]"
                  title="Forgot password"
                  to={"/auth/forgot-password"}
                >
                  forgot password?
                </TextLink>
              </div>
              <div className="h-[56px]">
                <Button
                  type="submit"
                  title="SUBMIT"
                  onClick={() => formik.handleSubmit()}
                  color="primary"
                  className="w-full font-medium rounded-lg"
                  disabled={formik.isSubmitting || loading}
                >
                  {formik.isSubmitting ? (
                    <span>
                      Loading{" "}
                      <Space>
                        <LoadingOutlined />
                      </Space>
                    </span>
                  ) : (
                    "Log in"
                  )}
                </Button>
              </div>
              {isPadeDomain() === true ? (
                <>
                  <div className="md:max-w-[389px] mx-auto">
                    <img
                      src={LineDivider}
                      alt="line"
                      className="w-full max-w-md mx-auto"
                    />
                  </div>
                  <div className="flex justify-center gap-[40px] mt-8 ">
                    <GoogleLogin
                      loading={loading}
                      handleLoginProcess={handleLoginProcess}
                    />
                    <MicrosoftLogin
                      loading={loading}
                      handleLoginProcess={handleLoginProcess}
                    />
                  </div>
                </>
              ) : null}
            </div>
          )
        }}
      </Formik>

      {showVerifyOtpPrompt && twoFALoginResponse && (
        <VerifyOtpPrompt
          onSuccess={() => {}}
          loginResponse={twoFALoginResponse}
        />
      )}
    </div>
  )
}

const LHS = () => {
  const { customizationSettings } = useCustomizationSettingsStore()
  return (
    <Carousel
      autoplay
      className="h-full"
      dots={{
        className:
          "!bottom-[-30px] [&_button]:!rounded-full [&_button]:!w-[12px] [&_button]:!h-[12px] [&_button]:!bg-a11y",
      }}
    >
      {(isPadeDomain() === true
        ? loop_data
        : customizationSettings?.pages?.login
      )?.map((item, idx) => (
        <div
          className="flex flex-col justify-center items-center px-6 2xl:space-y-24 h-full pt-10"
          key={idx}
        >
          <div className="md:min-h-[300px] flex justify-center">
            <img
              className="md:h-[320px]"
              src={item.imageUrl || LoginImg1}
              alt="logo-white"
              width={426}
              height={288}
            />
          </div>

          <div className="space-y-6 min-h-[140px] grow flex flex-col justify-end ">
            <div className="flex flex-col justify-center items-center gap-4 mx-auto">
              <h4 className=" font-circular font-bold text-[20px] leading-[25px] text-a11y text-center">
                {item.title}
              </h4>

              <p className="max-w-[500px] mx-auto font-avenir font-light text-[15px] leading-[20px] text-a11y/90 text-center">
                {item.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

const loop_data = [
  {
    imageUrl: LoginImg1,
    title: "HR at your fingertips",
    description:
      "PaidHR gives you the power to manage your workforce from anywhere, at any time. Keep track of employee information, track performance, and stay compliant with just a few clicks.",
  },
  {
    imageUrl: LoginImg2,
    title: "Empower your employees, Elevate your business.",
    description:
      "PaidHR gives you the tools to manage your workforce effectively and efficiently, allowing you to focus on growing your business",
  },
]
