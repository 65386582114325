import { LinkButton, TopActionBar } from "components"
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Select, Table } from "antd"
// import {
//   IEmployeePayslip,
//   IEmployeePayslipList,
//   IPayslip,
// } from "./interface/Payslip"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useGet } from "utils/useFetch"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

const EmployeePayslips: React.FC<{}> = () => {
  const currentYear = new Date().getFullYear()
  const myParam = useLocation().search
  const id = new URLSearchParams(myParam).get("id")
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const reqBody = {
    q: "",
    page: "",
  }
  // const { isLoading: isPayslipLoading, data } = useGet<any>({
  //   url: `/admin/employees/payslips?id=${id}`,
  // })

  const { isLoading: isPayslipLoading, data } = useGet<any>({
    url: `/admin/employees/view/payslips?id=${id}&x=${selectedYear}`,
    enabled: !!selectedYear,
  })
  interface IPayslipYear {
    id: number
    text: string
  }
  const { isLoading: isPayslipYearLoading, data: payslipYears } = useGet<
    IPayslipYear[]
  >({
    url: "employee/payslips/getpayrollyears",
  })

  const TableData =
    data?.payslips?.map((payslip: any) => ({
      key: payslip.payroll_key,
      netpay: `${payslip?.currency_symbol} ${formatCurrencyNoSymbol(
        Number(payslip.net_pay)
      )}`,

      date: {
        paydate: payslip.pay_date,
      },

      action: {
        payslip_id: payslip.payroll_key,
      },
    })) || []

  const TableColumns =
    [
      {
        title: "Pay Date",
        dataIndex: "date",
        render: (date: { paydate: string }) => (
          <span className="whitespace-nowrap text-[#42526D] font-avenir text-sm">
            Paid on {date.paydate}
          </span>
        ),
      },
      {
        title: "Net pay",
        dataIndex: "netpay",
        render: (netpay: string) => (
          <span className="whitespace-nowrap text-[#42526D] font-avenir text-sm">
            {netpay}{" "}
          </span>
        ),
      },

      {
        title: "Action",
        dataIndex: "action",
        render: (action: { payslip_id: string }) => (
          <Link
            to={`/dashboard/employee/payslip/view?id=${action.payslip_id}&x=${data?.person_key}`}
          >
            <span
              className={`text-sm font-medium py-1 block text-primary500 text-center w-[80px] rounded-[4px]

            `}
            >
              View
            </span>
          </Link>
        ),
      },
    ] || []

  const handleChangePeriod = (value: any) => {
    let owner = value.split("-")
    let name = owner[0]
    owner = owner[1]

    setSelectedYear(owner)
  }
  return (
    <>
      <TopActionBar>
        <div className="flex justify-between w-full">
          <div
            className={`flex  items-center cursor-pointer  rounded-5px  w-40 border border-cardgrey`}
          >
            <Select
              showSearch
              optionFilterProp="children"
              defaultValue={selectedYear && selectedYear}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%", height: "100%" }}
              placeholder="Select Year"
              onChange={handleChangePeriod}
              bordered={false}
              optionLabelProp="label"
              options={payslipYears?.map((prd, index) => ({
                value: `${prd.text}-${prd.id}`,
                label: `${prd.text}`,
              }))}
            />
          </div>
          <LinkButton
            to={`/dashboard/employee/payslips/all?id=${selectedYear}&x=${id}`}
            color="primary"
          >
            <span className="">View All </span>
          </LinkButton>
        </div>
      </TopActionBar>
      <DashboardWrapper>
        <div>
          <h6 className="text-bluesubtext font-extrabold text-base mb-4">
            Your {selectedYear} payslips
          </h6>
          <div>
            <Table
              columns={TableColumns}
              dataSource={TableData}
              scroll={{ x: true }}
              loading={isPayslipLoading}
              className="text-[#42526D]"
              // pagination={{
              //   current,
              //   onChange: handlePaginationChange,
              //   // total: data?.total,
              //   defaultPageSize: 20,
              // }}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  )
}

export default EmployeePayslips
