import { IconButton } from "../buttons/IconButton"
import { XMarkIcon } from "@heroicons/react/24/outline"

type props = {
  width?: string
  onClose: Function
  children: JSX.Element | JSX.Element[]
}

export const FlyoutWrapper: React.FC<props> = ({ width, onClose, children }) => {
  return (
    <div className="absolute z-40 h-screen w-screen flex justify-end items-start top-0 bottom-0 left-0 right-0 bg-[#1E1E1E]/60">
      <div className={`relative w-full h-full overflow-y-auto bg-white rounded-none ${width}`}>
        <div className="absolute top-0 right-0 m-2">
          <IconButton
            title="Close"
            onClick={() => onClose()}
          >
            <XMarkIcon className="w-4 h-4" />
          </IconButton>
        </div>

        {children}
      </div>
    </div>
  )
}
