import { Collapse, Select, Spin } from "antd"
import DropdownCard, { Items } from "components/new-stuff/ui/DropdownCard"
import React, { useState } from "react"
// import Measurements from "../../../employee-dashboard/performance/initiatives/Measurements"

import { usePost, useGet } from "utils/useFetch"
import { Label } from "components"
import Measurements from "pages/employee-dashboard/performance/initiatives/Measurements"
const { Panel } = Collapse

type measurementprops = {
  personForKR?: string
  periodID?: string
}
const ReviewViewKeyResults: React.FC<measurementprops> = ({
  personForKR,
  periodID,
}) => {
  const {
    isLoading: initiativeLoading,
    data: initiativesData,
    refetch,
  } = useGet<any>({
    url: `admin/performance/selectanotheremployeeobjectives?person_id=${personForKR}&period_id=${periodID}&for_employee=${true}`,
    // enabled: !!selectedPersonId,
  })

  const totalCompletedMeasurement = initiativesData?.data.filter(
    (item: any) =>
      item.employee_objective_status_id == "4" ||
      item.employee_objective_status_id == "5"
  )

  // const Panels: Items[] = [
  //   {
  //     key: "1",
  //     header: (
  //       <div className=" font-avenir">
  //         <h6 className=" text-neutral700 text-base font-extrabold">
  //           Measured Initiatives
  //         </h6>
  //         <p className=" text-neutral400 text-sm font-light">
  //           {totalCompletedMeasurement?.length || 0}/
  //           {initiativesData?.data.length} Completed
  //         </p>
  //       </div>
  //     ),
  //     children: (

  //     ),
  //   },
  // ]

  return (
    <div className=" font-avenir flex flex-col gap-4">
      {initiativeLoading ? (
        <Spin />
      ) : initiativesData ? (
        // <DropdownCard
        //   rootClassName="perfCollapse h-auto  bg-white"
        //   items={Panels}
        // />
        <Measurements
          completed={totalCompletedMeasurement?.length}
          total={initiativesData?.data.length}
          keyResultDetails={initiativesData?.data || []}
          refetchKeyData={() => refetch()}
          showCompleted={false}
          isProgressUpdateAllowed={false}
          isKRLocked={false}
          permissionGranted={true}
          myInitiativeSection={true}
          showUpdateProgressBtn={false}
        />
      ) : (
        <div>No available Initiative</div>
      )}
    </div>
  )
}

export default ReviewViewKeyResults
