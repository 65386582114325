import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import React, { useState, useEffect } from "react"
import { Alert, Form, Input, Radio, Space, Spin, Tag } from "antd"
import { AntDesignInput } from "components/antd/AntDesignInput"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { IBillingInvoice } from "../interface/invoice"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { useGet } from "utils/useFetch"
import { formatCurrencyNoSymbol } from "utils/currency"
import PrevNextButton from "pages/dashboard/documents/custom-form/PrevNextButton"
import { PaystackButton } from "react-paystack"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Button } from "components"
import { IWalletOverViewData } from "pages/dashboard/wallets/interface"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import bg_invoice from "../../../../../../assets/illustrations/ewa_bg.png"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
const PayInvoice = () => {
  const { user } = useAuthStore()
  const walletId = user?.organisationWalletId
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()
  const myParam = useLocation().search
  const invoiceId = new URLSearchParams(myParam).get("x")
  const [form] = Form.useForm()
  const [selectedMethod, setSelectedMethod] = useState("Card")
  const [amount, setAmount] = useState("0")

  const { isLoading: isInvoiceLoading, data: invoiceData } =
    useGet<IBillingInvoice>({
      url: `/admin/company/view/invoice?id=details&x=${invoiceId}`,
    })
  useEffect(() => {
    if (invoiceData) {
      setAmount(String(invoiceData?.amount_due))
    }
  }, [invoiceData])

  const {
    isFetching: isLoadingWalletData,
    data: walletData,
    isError: IsLoadingOverviewError,
    refetch,
  } = useGet<IWalletOverViewData>({
    url: "/admin/wallet/view/index",
  })

  const { isLoading: isPayingFomWallet, mutate: paywithWallet } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/logpayment_new`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        mixPanelEvent("settings-plans-billings-invoice-payment-success")

        toast.success(
          `Payment of ₦${formatCurrencyNoSymbol(
            Number(amount) || 0
          )} for invoice ${
            invoiceData?.invoice_number
          } was successful. It might take some minutes for the status of the invoice to be updated.`
        )
        navigate("/dashboard/settings/plan-and-billing/invoices")
      } else {
        data?.msg && toast.error(data?.msg)
      }
    },
    onError: (data: any) => {
      toast.error(data?.msg)
    },
  })
  const componentProps = {
    email: user?.userName || "",
    amount: Number(amount) * 100,
    // metadata: {
    //   invoice: String(invoiceData?.invoice_number),
    // },
    publicKey: String(invoiceData?.ppk) || "",
    text: "Pay Now",
    onSuccess: () => {
      // alert("Thanks for doing business with us! Come back soon!!")
      toast.success(
        `Payment of ₦${formatCurrencyNoSymbol(
          Number(amount) || 0
        )} for invoice ${
          invoiceData?.invoice_number
        } was successful. It might take some minutes for the status of the invoice to be updated.`
      )
      mixPanelEvent("settings-plans-billings-invoice-payment-success")
      navigate("/dashboard/settings/plan-and-billing/invoices")
    },
    // onClose: () => alert("You have closed"),
  }
  const { isLoading: isLoadingOverview, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })

  const invoiceInfo = [
    {
      title: "Invoice Number",
      value: invoiceData?.invoice_number || 0,
    },

    {
      title: "Invoice Date",
      value: invoiceData?.end_date,
    },
    {
      title: "Amount Due",
      value: `₦${formatCurrencyNoSymbol(Number(invoiceData?.amount_due || 0))}`,
    },
  ]
  const bankInfo = [
    { title: "Bank Name", value: invoiceData?.padeBank },
    { title: "Account Number", value: invoiceData?.padeAccountNumber },
    { title: "Account Name", value: invoiceData?.padeName },
    {
      title: "Total Amount",
      value: `₦${formatCurrencyNoSymbol(Number(invoiceData?.total_amount))}`,
    },
    {
      title: "Invoice Status",
      status: (
        <div>
          <Tag
            color={
              invoiceData?.invoice_status_id === 1
                ? "success"
                : invoiceData?.invoice_status_id === 2
                ? "error"
                : "processing"
            }
          >
            {invoiceData?.invoice_status}
          </Tag>
        </div>
      ),
    },
  ]
  const ngnBalance = newWalletData?.data?.balances?.find(
    (balance) => balance.currency === "NGN"
  )
  return (
    <DashboardWrapper>
      <FormSectionWrapper
        name="Pay Invoice"
        title="Pay Invoice"
        subtitle="We accept part-payment, this can be useful if you intend to split the payment between multiple cards. The status of your invoice will be updated to paid after you’ve settled the amount due.​"
        isActive={true}
        layout="vertical"
        form={form}
        style={{ maxWidth: 1024 }}
        autoComplete="off"
        footerBtns={
          <div className="flex justify-end items-center gap-2">
            <Button
              color="secondary"
              title="Cancel"
              onClick={() => navigate(-1)}
            >
              {" "}
              Cancel
            </Button>
            {selectedMethod == "Card" && (
              <Button
                title="payment"
                color="primary"
                disabled={amount == "" ? true : false}
                onClick={() =>
                  mixPanelEvent("settings-plans-billings-invoice-payment-init")
                }
              >
                <PaystackButton {...componentProps} />
              </Button>
            )}
            {selectedMethod == "Wallet" && (
              <Button
                title="payment"
                color="primary"
                disabled={amount == "" ? true : false}
                submitting={isPayingFomWallet}
                onClick={() => {
                  mixPanelEvent("settings-plans-billings-invoice-payment-init")
                  paywithWallet({
                    amount,
                    payment_option: "Wallet",
                    invoice_number: invoiceData?.invoice_number,
                    full_name: user?.userOrganisation,
                    email: user?.userName,
                    phone: user?.userPhone,
                    currency_code: user?.currencyCode,
                    country_code: user?.countryCode,
                  })
                }}
              >
                Pay now
              </Button>
            )}{" "}
          </div>
        }
      >
        {isInvoiceLoading ? (
          <div className="flex  justify-center items-center py-4">
            <Spin />
          </div>
        ) : (
          <div className=" flex flex-wrap  items-center justify-between px-4 md:px-6 xl:px-[60px]">
            <div className="flex flex-col   items-start font-avenir">
              {invoiceInfo?.map((info, index) => (
                <div
                  key={index}
                  className="flex flex-col  items-start gap-1 mb-4"
                >
                  <h4 className=" text-[#5D6B82] ">{info.title}</h4>

                  <h2 className="text-[#15294B] font-medium">{info.value}</h2>
                </div>
              ))}

              <div>
                <Form.Item
                  label={
                    <span className="font-circular text-neutral300 text-sm  ">
                      Specify the amount you want to pay
                    </span>
                  }
                  required={true}
                  name="amount"
                >
                  <Input
                    name="amount"
                    required={true}
                    defaultValue={String(invoiceData?.amount_due)}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Item>
              </div>
              <Form layout="vertical">
                <AntDesignRadio
                  label="Choose payment option"
                  name="access"
                  value={selectedMethod}
                >
                  <Space direction="horizontal">
                    <Radio
                      value="Card"
                      checked={selectedMethod === "Card" ? true : false}
                      onChange={() => {
                        setSelectedMethod("Card")
                      }}
                    >
                      Card
                    </Radio>
                    <Radio
                      value="Wallet"
                      checked={selectedMethod === "Wallet" ? true : false}
                      onChange={() => {
                        setSelectedMethod("Wallet")
                      }}
                      disabled={
                        walletData && walletData?.direct_Deposit_Status_Id !== 1
                      }
                    >
                      Wallet
                    </Radio>
                    <Radio
                      value="Other"
                      checked={selectedMethod === "Other" ? true : false}
                      onChange={() => {
                        setSelectedMethod("Other")
                      }}
                    >
                      Other
                    </Radio>
                  </Space>
                </AntDesignRadio>
              </Form>
            </div>
            {selectedMethod == "Wallet" && amount !== "" && (
              <div
                className="w-[350px] min-h-[200px] font-avenir  bg-primary500  rounded-5px p-4  flex flex-col mb-7  "
                style={{
                  // backgroundImage: `url(${bg_invoice})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "105%",
                  backgroundPosition: "center",
                }}
              >
                <h3 className="text-white font-extrabold text-xl mb-1 border-b-2  border-white w-full">
                  Payment Information
                </h3>

                {isLoadingWalletData ? (
                  <div className="w-full flex justify-center items-center py-3 mt-4 flex-grow">
                    <LoadingIndicatorWhite />
                  </div>
                ) : (
                  <>
                    <div className="flex items-center justify-between my-4 pr-5 ">
                      <p className="text-white text-sm">Wallet Balance</p>

                      <p className="text-white text-sm font-bold text-right">
                        ₦
                        {ngnBalance?.balance &&
                          formatCurrencyNoSymbol(
                            Number(ngnBalance?.balance) || 0
                          )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between mb-4 pr-5">
                      <p className="text-white text-sm">Transaction Amount</p>
                      <p className="text-white text-base font-bold text-right">
                        ₦{formatCurrencyNoSymbol(Number(amount) || 0)}
                      </p>
                    </div>

                    {Number(amount) > Number(ngnBalance?.balance) && (
                      <div className="mt-2">
                        <Alert
                          message="Insufficient amount in your wallet. Kindly choose another payment option or top up your wallet."
                          type="error"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {selectedMethod == "Other" && (
              <div className=" font-avenir bg-white  rounded-5px p-3  justify-between flex flex-col mb-7 shadow-card ">
                {isInvoiceLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <h6 className="text-[#15294B] text-sm  font-normal mr-4">
                      Please make cheques or bank deposits payable to
                    </h6>
                    <div className="my-3 flex flex-col flex-wrap gap-2  py-2 px-3 bg-[#ecf2fe80]">
                      {bankInfo?.map((info, index) => (
                        <div
                          key={index}
                          className="flex flex-col gap-1"
                        >
                          <h4 className="text-[#6C7A93F2]  font-light">
                            {info.title}
                          </h4>
                          {info?.status ? (
                            info?.status
                          ) : (
                            <h2 className="text-[#15294B] font-bold">
                              {info.value}
                            </h2>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-4  px-3 ">
                      <p className="text-[#15294B]  text-sm leading-snug">
                        For any enquiries, please contact
                      </p>
                      <div className="flex flex-col gap-2 font-bold  ">
                        <p className="text-[#42526D]  text-sm flex items-center">
                          <UserIcon className="w-5 h-5 mr-1" />
                          {invoiceData?.padeAdminContactName}
                        </p>
                        <p className="text-[#42526D]  text-sm flex items-center ">
                          <EnvelopeIcon className="w-5 h-5 mr-1" />
                          {invoiceData?.padeAdminContactEmail}
                        </p>

                        <p className="text-[#42526D]  text-sm flex items-center">
                          <PhoneIcon className="w-5 h-5 mr-1" />
                          {invoiceData?.padeAdminContactPhone}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </FormSectionWrapper>
    </DashboardWrapper>
  )
}

export default PayInvoice
