import { create } from "zustand"
import { persist } from "zustand/middleware"
import { isPadeDomain } from "utils/utils"
import LoginImg1 from "assets/images/LoginImg1.png"
import LoginImg2 from "assets/images/new-login-img.png"
import LogoWhite from "assets/logos/paidhr-logo-white.svg"
import LandingImage from "assets/images/landingPage.png"
import { appColor } from "utils/constants"

export interface CustomizationSettings {
  brand: {
    colorScheme: string
    logoUrl: string
    faviconUrl: string
    documentTitle: string
    documentDescription: string
    platformName: string
    companyName: string
    menuLogoUrl: string
  }
  pages: {
    getStarted: {
      imageUrl: string
      title: string
      description: string
    }
    login: {
      imageUrl: string
      title: string
      description: string
    }[]
    forgotPassword: {
      imageUrl: string
      description: string
    }
    businessRegistration: {
      title: string
      description: string
    }
    employeeRegistration: {
      title: string
      description: string
    }
  }
}

const defaultSettings: CustomizationSettings = {
  brand: {
    colorScheme: appColor || "#004AF5",
    logoUrl: LogoWhite,
    faviconUrl: "",
    documentTitle: "PaidHR - The People Platform",
    documentDescription:
      "PaidHR - We are Africa's favourite People Platform. From hire to exit, simplify your entire HR process from one central dashboard.",
    platformName: "PaidHR",
    menuLogoUrl: LogoWhite,
    companyName: "Pade HCM Inc.",
  },
  pages: {
    getStarted: {
      imageUrl: LandingImage,
      title: "Simplify your HR with PaidHR",
      description:
        "Our HR software helps you save time and money by automating your HR processes.",
    },
    forgotPassword: {
      imageUrl:
        "https://app.paidhr.com/static/media/Group%2024.dcd033a28878ea379e50.png",
      description:
        "Our user-friendly platform allows you to streamline your HR processes and make informed decisions to drive your business forward.",
    },
    login: [
      {
        imageUrl: LoginImg1,
        title: "HR at your fingertips",
        description:
          "PaidHR gives you the power to manage your workforce from anywhere, at any time. Keep track of employee information, track performance, and stay compliant with just a few clicks.",
      },
      {
        imageUrl: LoginImg2,
        title: "Empower your employees, Elevate your business.",
        description:
          "PaidHR gives you the tools to manage your workforce effectively and efficiently, allowing you to focus on growing your business",
      },
    ],
    businessRegistration: {
      title: "A few clicks from creating your account",
      description:
        "We've made managing your workforce really simple - helping you spend less time on desk work and managing routine tasks.",
    },
    employeeRegistration: {
      title: "Unleash Your Team's Potential with Our Innovative PaidHR",
      description:
        "Refer your HR manager today and give them the tools they need to drive success in your organisation. Unlock a world of possibilities with our cutting-edge HR solutions.",
    },
  },
}

interface State {
  customizationSettings: CustomizationSettings
}

interface Actions {
  setCustomizationSettings: (
    customizationSettings: CustomizationSettings
  ) => void
}

const initialState =
  isPadeDomain() === true
    ? defaultSettings
    : localStorage.getItem("app-settings")
    ? JSON.parse(localStorage.getItem("app-settings") as string)
    : defaultSettings

export const useCustomizationSettingsStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      customizationSettings: initialState,
      setCustomizationSettings: (
        customizationSettings: CustomizationSettings
      ) => {
        set({ customizationSettings })
      },
    }),
    {
      name: "app-settings",
    }
  )
)
