import React from "react"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { axiosInstance } from "utils/request"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { queryClient } from "index"

const DeleteBeneficiary: React.FC<{
  deleteModal: boolean
  setDeleteModal: Function
  beneficiaryId: string
}> = ({ deleteModal, setDeleteModal, beneficiaryId }) => {
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async () => {
      const res: any = await axiosInstance.delete(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries/${beneficiaryId}`,

        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res?.data
    },
    onSuccess: (data: any) => {
      console.log(data)
      setDeleteModal(false)
      queryClient.invalidateQueries({
        queryKey: [
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
        ],
      })
    },
    onError: (data: any) => {
      console.log(data)

      {
        data.message && toast.error(data.message)
      }
    },
  })
  const handleSummit = () => {
    submit()
  }
  return (
    <CustomModal
      title="Delete Beneficiary?"
      open={deleteModal}
      onCancel={() => {
        setDeleteModal(false)
      }}
      footer={
        <div className=" gap-4   flex justify-end">
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            type="submit"
            disabled={isLoading}
            submitting={isLoading}
            onClick={(e) => {
              handleSummit()
            }}
          >
            Delete
          </Button>
        </div>
      }
    >
      <div className="pt-1 ">
        <p className="text-[#7A8699] text-sm mb-4">
          Are you sure you want to delete this beneficiary? Click delete to
          continue.
        </p>
      </div>
    </CustomModal>
  )
}

export default DeleteBeneficiary
