import ImageComponent from "components/custom/image"
import Measurement from "../../../../assets/images/measure_key.svg"
import NotStarted from "../../../../assets/images/not_started.svg"
import TotalKeyResult from "../../../../assets/images/total_obj.svg"
import OnTrack from "../../../../assets/images/on_track.svg"
import df from "../../../../assets/images/on_track.svg"
import Achieved from "../../../../assets/images/achieved.svg"
import React, { useState, useEffect } from "react"
import { images } from "assets"
import { UpdateMeasurement } from "../UpdateMeasurement"
import { useObjectivesStore } from "stores/performance/Objectives"
import { useObjectivesEmployeeStore } from "stores/performance-employee/Objectives"
import { Space, Tag, Tooltip } from "antd"
import {
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"
import { Button, ModalWrapper } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { axiosInstance } from "utils/request"
import { useMutation } from "@tanstack/react-query"
import { useAppNotificationStore } from "stores"
import { formatCurrencyNoSymbol } from "utils/currency"

type measurementprops = {
  meas: any
  index: number
  totalTarget?: string
  handleSuccess: Function
  showObj?: boolean
  isProgressUpdateAllowed: boolean
  permissionGranted: boolean
  isKRLocked: boolean
  refetchKeyData: any
  showUpdateProgressBtn?: boolean
}
export const MeasuredCard: React.FC<measurementprops> = ({
  meas,
  index,
  totalTarget,
  handleSuccess,
  showObj = false,
  isProgressUpdateAllowed = true,
  permissionGranted = false,
  refetchKeyData,
  isKRLocked = false,
  showUpdateProgressBtn = true,
}) => {
  console.log(meas)

  const is_user_locked: any = meas?.is_locked == "True" ? true : false
  const { toast } = useAppNotificationStore()
  const [openMeasurementDropdown, setOpenMeasurementDropdown] = useState(false)
  const [lockUserModal, setLockUserModal] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState({ name: "", id: " " })

  const status = [
    {
      title: "Not started",
      icon: Measurement,
      id: 1,
    },
    {
      title: "On track",
      icon: OnTrack,
      id: 2,
    },
    {
      title: "Off track",
      icon: Measurement,
      id: 3,
    },
    {
      title: "Achieved",
      icon: Achieved,
      id: 4,
    },
    {
      title: "Exceeded",
      icon: Achieved,
      id: 5,
    },
    // {
    //   title: "Not Measured",
    //   icon: Measurement,
    //   id: 6,
    // },
  ]

  const statusIcon = status.find(
    (stat) => stat.id == Number(meas.employee_objective_status_id)
  )
  const symbol =
    meas?.measurement_unit_id == 2
      ? "%"
      : meas?.measurement_unit_id == 4
      ? "#"
      : ""
  const { isLoading: isLockingKR, mutate: submitLockKR } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/employee/performance/lock_employeeobjective?id=${meas.employee_objective_id}`
      )
      return res.data
    },
    onSuccess: (data) => {
      setLockUserModal(false)
      refetchKeyData()
      {
        data.msg && toast.success(data.msg)
      }
    },
  })
  const { isLoading: isUnlockingKR, mutate: submitUnlockKR } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/employee/performance/unlock_employeeobjective?id=${meas.employee_objective_id}`
      )
      return res.data
    },
    onSuccess: (data) => {
      setLockUserModal(false)
      refetchKeyData()
      {
        data.msg && toast.success(data.msg)
      }
    },
  })
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/employee/performance/remove_employeeobjective?id=${activeDeleteId.id}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        setOpenDeleteConfirmation(false)
        setActiveDeleteId({
          name: "",
          id: "",
        })
        refetchKeyData()

        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenDeleteConfirmation(false)
        setActiveDeleteId({
          name: "",
          id: "",
        })

        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)
      setActiveDeleteId({
        name: "",
        id: "",
      })
      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  return (
    <div className=" font-avenir">
      <div
        key={index}
        className="flex flex-col lg:flex-row justify-between  items-start lg:items-center gap-1 lg:gap-0"
      >
        <div className=" flex  items-center">
          <Tooltip
            title={
              isKRLocked
                ? "Key result has been locked"
                : permissionGranted && isProgressUpdateAllowed
                ? ""
                : !permissionGranted &&
                  isProgressUpdateAllowed &&
                  !is_user_locked
                ? ""
                : "Progress cannot be updated, contact admin"
            }
          ></Tooltip>
          <div className=" ">
            {Number(meas?.measurement_unit_id) === 5 ? (
              <p className=" text-neutral500 font-light text-sm">
                Target: Achieved
              </p>
            ) : totalTarget ? (
              <p className=" text-neutral500 font-light text-sm">
                Target: {meas.currency_symbol}
                {meas.target}
                {symbol} of {meas.currency_symbol}
                {formatCurrencyNoSymbol(Number(totalTarget))}
                {symbol}
              </p>
            ) : (
              <p className=" text-neutral500 font-light text-sm">
                Target: {meas.currency_symbol}
                {formatCurrencyNoSymbol(Number(meas.target))}
                {symbol}
              </p>
            )}

            {Number(meas?.measurement_unit_id) === 5 ? (
              <p className=" text-sm text-neutral500 font-semibold">
                Progress:{" "}
                {Number(meas?.progress) === 100 ? "Achieved" : "Not Achieved"}
              </p>
            ) : (
              <p className=" text-sm text-neutral500 font-semibold">
                {" "}
                Progress: {meas.currency_symbol}
                {formatCurrencyNoSymbol(Number(meas.progress))}
                {symbol} of {meas.currency_symbol}
                {formatCurrencyNoSymbol(Number(meas.target))}
                {symbol}
              </p>
            )}
            {showUpdateProgressBtn && (
              <div className="flex items-center gap-2 mt-1">
                <button
                  className={`border py-1 px-2 text-xs  rounded-md text-white bg-primary500 ${
                    !isProgressUpdateAllowed && " opacity-[60%]"
                  }`}
                  onClick={() => {
                    if (permissionGranted && isProgressUpdateAllowed) {
                      setOpenMeasurementDropdown(true)
                    } else if (
                      !permissionGranted &&
                      isProgressUpdateAllowed &&
                      !is_user_locked
                    ) {
                      setOpenMeasurementDropdown(true)
                    } else {
                    }
                  }}
                >
                  Update
                </button>
                {!isKRLocked && permissionGranted && (
                  <button
                    className={`border py-1 px-2 text-xs  rounded-md text-white bg-danger500`}
                    onClick={() => {
                      setOpenDeleteConfirmation(true)
                      setActiveDeleteId({
                        name: meas?.full_name,
                        id: meas?.employee_objective_id,
                      })
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={`flex w-full mt-1 lg:mt-0 lg:w-auto   lg:justify-normal   lg:gap-4 ${
            !showObj ? "justify-end" : "justify-between"
          }`}
        >
          {showObj && (
            <div className="flex flex-col items-start gap-1 lg:gap-0 lg:items-end">
              <div className="bg-background p-1 rounded-xl flex gap-2 items-start lg:items-center ">
                <img
                  src={TotalKeyResult}
                  alt=""
                  className="h-5 w-5 "
                />
                <p className="text-neutral500 text-xs capitalize">
                  {meas?.key_result}
                </p>
              </div>
              <p className=" text-xs text-neutral500 flex items-center">
                Created by:{""}
                <span className="font-medium">{meas.owner_full_name}</span>
              </p>
              <p className=" text-xs text-neutral500 flex items-center">
                Assigned to:{" "}
                <span className="font-medium">{meas.full_name}</span>
                <div className="ml-1">
                  <ImageComponent
                    className="w-4 h-4 rounded-full"
                    src={meas.avatar_url || images.DefaultProfilePicture}
                    alt=""
                  />
                </div>
              </p>
            </div>
          )}
          {/* {showObj && (
            <Tooltip title={meas.full_name}>
              <div>
                <ImageComponent
                  className="w-6 h-6 rounded-full"
                  src={meas.avatar_url || images.DefaultProfilePicture}
                  alt=""
                />
              </div>
            </Tooltip>
          )} */}
          {!showObj && (
            <div className="flex  items-center gap-1  w-full sm:w-auto">
              <ImageComponent
                className="w-5 h-5 rounded-full"
                src={meas.avatar_url || images.DefaultProfilePicture}
                alt=""
              />

              <p className=" text-neutral500 font-medium text-xs">
                {meas.full_name}
              </p>
            </div>
          )}
          {showUpdateProgressBtn && (
            <Tooltip
              placement="leftTop"
              title={
                isKRLocked
                  ? "Key result has been locked"
                  : permissionGranted
                  ? `${is_user_locked ? "Unlock" : "Lock"} ${meas.full_name}`
                  : is_user_locked
                  ? "You have been locked, contact admin to update progress"
                  : ""
              }
            >
              <button
                onClick={() =>
                  !isKRLocked && permissionGranted ? setLockUserModal(true) : {}
                }
              >
                {is_user_locked ? (
                  <LockClosedIcon className="w-4 h-4" />
                ) : (
                  <LockOpenIcon className="w-4 h-4" />
                )}
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      {openMeasurementDropdown && (
        <UpdateMeasurement
          metric={meas.measurement_unit_id}
          closeModal={() => setOpenMeasurementDropdown(false)}
          employee_objective_id={meas.employee_objective_id}
          handleSuccess={handleSuccess}
          progress={meas.progress}
          totalTarget={meas.target || ""}
          employeeName={meas?.full_name}
        />
      )}
      {lockUserModal && (
        <ModalWrapper
          onClose={() => setLockUserModal(false)}
          title={`${is_user_locked ? "Unlock" : "Lock"} ${meas.full_name}`}
        >
          <div className="px-5 py-3 flex flex-col items-center">
            <h6 className="text-[#15294B]  font-bold text-2xl my-3"></h6>
            <p className="text-[#15294B] text-sm mb-6 ">
              This user will be {is_user_locked ? "unlocked" : "locked"} and
              progress cannot be updated. However it can be reverted.
            </p>
          </div>
          <div className="border py-5 px-6 flex gap-4  justify-end ">
            <Button
              title="cancel"
              color="secondary"
              onClick={() => {
                setLockUserModal(false)
              }}
            >
              Cancel
            </Button>

            <Button
              title="update"
              color="primary"
              submitting={isLockingKR || isUnlockingKR}
              onClick={() =>
                is_user_locked ? submitUnlockKR() : submitLockKR()
              }
            >
              Confirm
            </Button>
          </div>
        </ModalWrapper>
      )}
      {openDeleteConfirmation && (
        <ModalWrapper
          title={`Delete ${activeDeleteId.name}'s initiative`}
          onClose={() => {
            setOpenDeleteConfirmation(false)

            setActiveDeleteId({
              name: "",
              id: "",
            })
          }}
        >
          <div className="px-5 py-3 flex flex-col items-center">
            <p className="text-[#15294B] text-sm mb-6 ">
              If you delete this initiative,it would be removed from your list.
              Are you sure you want to continue?
            </p>
          </div>
          <div className="border py-5 px-6 flex gap-4 justify-end items-center">
            <Button
              title="cancel"
              color="secondary"
              onClick={() => {
                setOpenDeleteConfirmation(false)
                setActiveDeleteId({
                  name: "",
                  id: "",
                })
              }}
            >
              Cancel
            </Button>

            <Button
              title="remove"
              color="primary"
              submitting={isSubmittingDelete}
              onClick={() => {
                submitDelete()
              }}
            >
              Confirm
            </Button>
          </div>
        </ModalWrapper>
      )}
    </div>
  )
}
