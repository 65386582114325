import RequireRoutePermit from "RequireRoutePermit"
import Statements from "pages/dashboard/wallets/Statements"
import Overview from "pages/dashboard/wallets/crossBorderPayments/Overview"
import ViewCurrency from "pages/dashboard/wallets/crossBorderPayments/viewcurrency/ViewCurrency"

import CrossBorderTransactions from "pages/dashboard/wallets/crossBorderPayments/transactions/Transactions"
import CompanyVerification from "pages/dashboard/wallets/integration/CompanyVerification"
import Integration from "pages/dashboard/wallets/integration/Integration"
import React from "react"
import { Route } from "react-router-dom"
import CreateCurrencySchedule from "pages/dashboard/wallets/crossBorderPayments/viewcurrency/CreateCurrencySchedule"
import ViewCurrencyTransaction from "pages/dashboard/wallets/crossBorderPayments/viewcurrency/ViewTransaction"
import CurrencyBeneficiaries from "pages/dashboard/wallets/crossBorderPayments/viewcurrency/beneficiaries/Beneficiaries"
import MoveFunds from "pages/dashboard/wallets/crossBorderPayments/movefunds/MoveFunds"
import UploadFlag from "pages/dashboard/wallets/crossBorderPayments/uploadFlag/UploadFlag"
import SwapMoney from "pages/employee-dashboard/wallet/wallet/crossBorder/SwapMoney"

const Wallet = React.lazy(() => import("pages/dashboard/wallets/Wallets"))
const WalletTransfers = React.lazy(
  () => import("pages/dashboard/wallets/Transfers")
)
const DirectDebit = React.lazy(
  () => import("../pages/dashboard/wallets/DirectDebit")
)

const TransactionHistory = React.lazy(
  () => import("../pages/dashboard/wallets/ViewTransactionHistory")
)

const WalletRoutes = () => {
  return (
    <>
      <Route
        path="overview"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_wallet_overview"]}>
            <Wallet />
          </RequireRoutePermit>
        }
      />

      <Route
        path="direct-debit"
        element={
          // <RequireRoutePermit allowedPermissions={["can_view_wallet_overview"]}>
          <DirectDebit />
          // </RequireRoutePermit>
        }
      />

      <Route
        path="transfers"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <WalletTransfers />
          </RequireRoutePermit>
        }
      />

      <Route
        path="statements"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Statements />
          </RequireRoutePermit>
        }
      />
      <Route
        path="integration"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Integration />
          </RequireRoutePermit>
        }
      />

      <Route
        path="integration/company-verification"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <CompanyVerification />
          </RequireRoutePermit>
        }
      />
      <Route
        path="transaction-history"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <TransactionHistory />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/overview"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Overview />
          </RequireRoutePermit>
        }
      />
      {/* <Route
        path="cross-border/transactions"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <CrossBorderTransactions />
          </RequireRoutePermit>
        }
      /> */}
      <Route
        path="cross-border/view"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <ViewCurrency />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/view/transactions"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <ViewCurrencyTransaction />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/view/beneficiaries"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <CurrencyBeneficiaries />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/swap-money"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <SwapMoney type="admin" />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/send-money"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <CreateCurrencySchedule />
          </RequireRoutePermit>
        }
      />
      <Route
        path="cross-border/move-funds"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <MoveFunds />
          </RequireRoutePermit>
        }
      />
      {/* THIS IS TEMPORARY IN ORDER FOR US TO UPLOAD ALL FLAGS TO THE MEDIA SERVICE */}
      <Route
        path="cross-border/upload-flags"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <UploadFlag />
          </RequireRoutePermit>
        }
      />
    </>
  )
}

export default WalletRoutes
