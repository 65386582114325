import { useGet, usePost } from "utils/useFetch"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { Form, Input, Select, Tooltip } from "antd"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { Button } from "components"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useState } from "react"
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline"
import { useAppNotificationStore } from "stores"

const GiveFeedback = () => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()
  const type = Form.useWatch("type", form)

  const [externalRecipients, setExternalRecipient] = useState<any[]>([])

  const { isLoading: isLoadingTypes, data: types } = usePost<any[]>({
    url: `/commonutils/getemployeescopes`,
    body: {
      q: "",
      page: "",
    },
    onSuccess: (data: any) => {
      console.log(data)
    },
  })
  const { isLoading: isLoadingEmployees, data: employees } = useGet<any[]>({
    url: `/admin/employees/get`,

    onSuccess: (data: any) => {
      console.log(data)
    },
  })
  const { isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/performance/requestfeedback`, body)
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        toast.success(data?.msg)
        form.resetFields()
        navigate(-1)
      }
    },
  })
  const onFinish = (values: any) => {
    const body = {
      employee_scope: String(type),
      feedback_request: values?.description,
      person_list: values?.specific.map(String),
      // recipient_list: ["<string>", "<string>"],
    }
    submit(body)
    // mixPanelEvent(`payroll-config-allowances-update-init`)
  }
  return (
    <DashboardWrapper>
      <div className=" flex flex-col items-start justify-start   gap-6 max-w-[900px] ">
        <FormSectionWrapper
          title="Send feedback to an employee"
          subtitle="When you send feedback to an employee, we’ll email the employee to let them know you’ve sent them a feedback and post it on their performance dashboard along with your name."
          isActive={true}
          footerBtns={
            <div className="flex justify-end w-full gap-3">
              <Button
                color="neutral"
                onClick={(e) => {
                  navigate(-1)
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={isLoading}
                type="submit"
                submitting={isLoading}
              >
                Submit
              </Button>
            </div>
          }
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="px-4 md:px-6 xl:px-[60px] pb-[66px]">
            <Form.Item
              name="type"
              label={
                <span className="font-circular text-padeBlack text-sm ">
                  Who do you want to get feedback from?
                </span>
              }
              rules={[{ required: true, message: "Please enter this field!" }]}
              className="w-full"
            >
              <Select
                options={types?.map((option, index) => ({
                  value: option?.id,
                  label: option?.text,
                }))}
                loading={isLoadingTypes}
                placeholder="Please select"

                // onChange={(value) => {
                //   const selected = allMetrics.find(
                //     (metr) => metr?.measurementUnitId === value
                //   )
                //   setMetric({
                //     label: selected?.symbol || "",
                //     id: selected?.measurementUnitId,
                //   })
                // }}
              />
            </Form.Item>
            {type == 2 && (
              <Form.Item
                name="specific"
                label={
                  <span className="font-circular text-padeBlack text-sm ">
                    Select Employees
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter this field!" },
                ]}
                className="w-full"
              >
                <Select
                  mode="multiple"
                  options={employees?.map((option, index) => ({
                    value: option?.id,
                    label: option?.text,
                  }))}
                  loading={isLoadingEmployees}
                  placeholder="Please select"
                  filterOption={(input, option) => {
                    if (option) {
                      const value = (option?.label as string) || ""
                      return (
                        value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      )
                    } else {
                      return false
                    }
                  }}
                  // onChange={(value) => {
                  //   const selected = allMetrics.find(
                  //     (metr) => metr?.measurementUnitId === value
                  //   )
                  //   setMetric({
                  //     label: selected?.symbol || "",
                  //     id: selected?.measurementUnitId,
                  //   })
                  // }}
                />
              </Form.Item>
            )}
            <button
              type="button"
              className="text-primary500 flex items-center mb-6"
              onClick={() => {
                setExternalRecipient([
                  ...externalRecipients,
                  {
                    name: "",
                    email: "",
                    id: externalRecipients.length + 1,
                  },
                ])
              }}
            >
              <PlusIcon className="h-4 w-4" />
              {externalRecipients.length > 0
                ? "Add another recipient"
                : "Add external recipients"}
            </button>
            {externalRecipients.map((rec, index) => (
              <div className="flex items-center gap-2">
                <div className="grid grid-cols-2 gap-4 flex-grow">
                  {" "}
                  <Form.Item
                    label={
                      <span className="font-circular text-padeBlack text-sm ">
                        Full Name
                      </span>
                    }
                    name={`name-${rec?.id}`}
                    hasFeedback
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                    required
                  >
                    <Input
                      placeholder="Enter Email"
                      id="email"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="font-circular text-padeBlack text-sm ">
                        Email Address
                      </span>
                    }
                    name={`email-${rec?.id}`}
                    hasFeedback
                    rules={[
                      { required: true, message: "Please enter this field!" },
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                    required
                  >
                    <Input
                      type="email"
                      placeholder="Enter Email"
                      id="email"
                    />
                  </Form.Item>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const notActive = externalRecipients.filter(
                      (item) => item.id !== rec?.id
                    )
                    setExternalRecipient(notActive)
                  }}
                >
                  <TrashIcon className="h-6 w-6 text-danger500" />
                </button>
              </div>
            ))}
            <Form.Item
              required
              rules={[{ required: true, message: "Please enter this field" }]}
              name="description"
              label={
                <span className="text-padeBlack text-sm font-circular">
                  Your Request
                </span>
              }
            >
              <Input.TextArea
                className="min-h-[92px]"
                id="mobile-number"
                required={false}
                placeholder="Feedback should be brief and to the point"
                // value={reason}
                // onChange={(e) => {
                //   setReason(e.target.value)
                // }}
              />
            </Form.Item>
          </div>
        </FormSectionWrapper>
      </div>
    </DashboardWrapper>
  )
}

export default GiveFeedback
