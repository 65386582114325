import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { icons } from "assets"
import { useState } from "react"
import { Label } from "./Label"
import { SelectOption } from "./SelectOption"

/**
 * An optimized input.
 *
 * @param props
 * @returns input
 */
export const SelectInput: React.FC<
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    error?: boolean
    isFilter?: boolean
    filterIcon?: boolean
    noBorder?: boolean
    title?: String
    isError?: boolean
    errorText?: string
    placeholder?: string
  }
> = ({
  filterIcon,
  isFilter,
  noBorder,
  title,
  isError,
  errorText,
  ...props
}) => {
  const [selectedPlaceholder, toggleSelectPlaceholder] = useState(
    typeof props.placeholder !== "undefined"
  )
  return (
    <>
      {title && <Label htmlFor="title">{title}</Label>}
      <div
        className={`relative rounded-[4px] focus:ring-2  my-auto ${
          noBorder
            ? ""
            : "focus:ring-blue-500 focus:border-blue-500  border  border-neutral50   h-10 } w-full"
        } flex items-center py-0 gap-[12px]`}
      >
        {filterIcon ? (
          <div className=" pl-[12px] z-0">
            <icons.FilterIcon />{" "}
          </div>
        ) : (
          <span />
        )}
        <select
          {...props}
          onChange={(e) => {
            // TODO: fix selection bug
            toggleSelectPlaceholder(e.target.value === "__placeholder__")
            if (e.target.value !== "__placeholder__")
              props.onChange && props.onChange!(e)
          }}
          className={`appearance-none w-full cursor-pointer z-10 leading-[21px] text-[14px] py-0 focus: outline-none  ${
            isFilter ? "font-extrabold" : ""
          } ${
            selectedPlaceholder === true ? "text-neutral80" : "text-neutral600"
          } focus:outline-0 ${props.className} `}
        >
          {filterIcon ? (
            <div className=" pl-[12px] z-0">
              <icons.FilterIcon />{" "}
            </div>
          ) : (
            <span />
          )}
          {props.children}
        </select>
        <div className="absolute right-[12px] z-0">
          <ChevronDownIcon className="w-4" />
        </div>
      </div>
      {isError && (
        <span>
          <div className="text-red-500 text-sm">{errorText}</div>
        </span>
      )}
    </>
  )
}
